import React, { Component, Fragment } from 'react';
import { strings } from './strings';
import ContentEditable from 'react-contenteditable';
import Swal from "sweetalert2";

class AccessoriesRoom extends Component {

    constructor(props) {

        super(props);

        this.state = {
            roomsList: [{id:99, name: 'Accessories'}],
            productsArray: [],
        }

        this.copyProduct = this.copyProduct.bind(this);

    }

    copyProduct(product) {
        this.props.copyProduct(product);
    }



    componentDidMount() {

        if (this.props.products != null) {

            const productsArray = this.props.products; //JSON.parse(localStorage.getItem("jobProduct"));

            //console.log('array', productsArray);

            let rooms = this.state.roomsList;

            productsArray.map(job => {

                const RoomId =   job.jobProductData.RoomId;
                const roomName = job.jobProductData.roomData;

                const found = this.state.roomsList.filter(room => { return room.id === RoomId });

                if (found.length === 0 && roomName === 'Accessories') {
                    rooms.push( {id: RoomId, name: roomName} )
                }

                return job;

            });

            this.setState({
                productsArray: productsArray,
                roomsList: rooms
            })

        }


    }

    getroomProduct(product, key) {

        var roomStyles = document.querySelectorAll(".roomClass")

        for (var i = 0; i < roomStyles.length; i++)  {
            roomStyles[i].style.border = "none"
        }

        document.getElementById(`room${key}`).style.border = "2px solid #248f39"
        this.props.sendProducts(product, "edit", key)
    }

    deleteProduct(data, productkey) {
        

        Swal.fire({
            
            title: `${strings.Areyousure}`,
            text: `${strings.Oncedeleted}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'

        }).then(willDelete => {
            
            if (willDelete.value) {
            
                var idToRemove = productkey;
                var data = this.state.productsArray
                var myArr = data.filter((el, key) => {
                    return key !== idToRemove;
                });

                this.setState({
                    productsArray: myArr
                }, () => {
                    let array = [];
                    myArr.map((obj) => {
                        array.push(obj.jobProductData);
                        return obj;
                    })

                    this.props.deleteCart(this.state.productsArray, array)

                })
            
            } else {
                return false;
            }

        });
    }

    truncateText (text, limit){
        if (text.length > limit){
            for (let i = limit; i > 0; i--){
                if(text.charAt(i) === ' ' && (text.charAt(i-1) !== ','||text.charAt(i-1) !== '.'||text.charAt(i-1) !== ';')) {
                    return text.substring(0, i) + '...';
                }
            }
             return text.substring(0, limit) + '...';
        }
        else
            return text;
      }

      formatCurrency(money){
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(money);
      }

      noAction(){

    }


    render() {

        const productsArray  = this.props.products;

        let roomsList = this.state.roomsList.map(room => {

            const products = productsArray.filter((product, key) => {
                product.key = key;
                return product.jobProductData.RoomId === room.id || (room.id === 1 && product.jobProductData.RoomId === null)
            });

            const total = products && products.length > 0 ? products.reduce(function (tot, arr) {

                return tot + arr.jobProductData.Price;
            }, 0) : 0;

            room.products = products;
            room.total = total;

            return room;
        });


        var result = productsArray && productsArray.length > 0 ? productsArray.reduce(function (tot, arr) {

            return tot + parseFloat(arr.jobProductData.Price);

        }, 0) : 0;

        localStorage.setItem("TotalPrice", result)

        const postData = localStorage.getItem("postData") ? JSON.parse(localStorage.getItem("postData")) : [];

        //console.log('postdata', postData);

        let roomArray = postData.filter( p => {
            return p !== null && p.RoomId === 99
        })


        return (
            <Fragment>
                <React.Fragment>
                <div className="panel-group" id="accessoriesAccordion">
                    {
                            roomsList.map((roomlist, key) => {
                                return (
                                    <Fragment key={key}>
                                    <div className="panel panel-default room-panel" >
                                        <div className="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accessoriesAccordion" data-target={"#accessories0"} >


                                            <h4 className="panel-title">

                                                <RoomTitle room={roomlist} rename={this.props.renameRoom} />
                                                <span>
                                                    {roomlist.products ? roomlist.products.length > 1 ? `${roomlist.products.length} items` : `${roomlist.products.length} item`   : ""}
                                                    <i className="fa fa-angle-down" />
                                                </span>
                                            </h4>
                                        </div>

                                        <div id={"accessories0"} className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <ul className="productlist">
                                                    {
                                                        roomlist.products && roomlist.products.length ? roomlist.products.map((roomproduct, key) => {

                                                            const status = roomproduct.jobProductData.Status ? roomproduct.jobProductData.Status : 'Pending';

                                                            return (
                                                                <li key={roomproduct.key} className="roomClass" id={"room" + roomproduct.key}>
                                                                    <div className="hding">
                                                                      <StickerName data={roomproduct.jobProductData} renameSticker={this.props.renameSticker} id={roomproduct.key} />
                                                                        {/*<a >{roomproduct.jobProductData.ProductName}</a>*/}

                                                                        <span>




                                                                                <button className="link-button"
                                                                                    title="Copy"
                                                                                    onClick={ () => this.copyProduct(roomproduct) }
                                                                                >
                                                                                    <i className="fa fa-clone" />
                                                                                </button>

                                                                            <button className="link-button" onClick={this.deleteProduct.bind(this, roomproduct.jobProductData, roomproduct.key)}>
                                                                                <i className="fa fa-trash" />
                                                                            </button>
                                                                        </span>

                                                                    </div>

                                                                      {roomproduct.jobProductData.ProductType && roomproduct.jobProductData.ProductType === 'accessories' ? (
                                                                        <Fragment>
                                                                        <div style={{padding: '0px', margin: '0px auto 15px'}}>
                                                                        <img alt={roomproduct.jobProductData.ProductName} style={{float: 'left', width: '75px', marginRight: '10px'}} src={roomproduct.jobProductData.image} />
                                                                        <p style={{fontSize: '14px'}}>{this.truncateText(roomproduct.jobProductData.ProductDescription, 150)}</p>
                                                                        </div>
                                                                        <div className="pdtl" style={{margintop: '10px'}}>
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Stock Code: <span>{roomproduct.jobProductData.StockCode}</span></label>
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Price: <span>{this.formatCurrency(roomproduct.jobProductData.HardwareCost)}</span></label>
                                                                            {status === 'In Progress' || status === 'Completed' ?
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Quantity:
                                                                                <span style={{width: '60px', textAlign: 'right', padding:'2px 10px'}}>{roomproduct.jobProductData.fixedQty}</span>
                                                                            </label>
                                                                            :
                                                                            <EditableQuantity data={roomproduct.jobProductData} updateQty={this.props.updateQty} id={roomproduct.key} />

                                                                            }
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Line Total: <span>{this.formatCurrency(roomproduct.jobProductData.Price)}</span></label>
                                                                        </div>
                                                                        </Fragment>
                                                                      ) : (
                                                                        <Fragment>
                                                                        <div className="pdtl col-md-7">
                                                                        <label>Width:  {roomproduct.jobProductData.Width}</label>
                                                                        <label>Height:  {roomproduct.jobProductData.Height}</label>
                                                                        { Number(roomproduct.jobProductData.Depth)  > 0 ?
                                                                        <label>Depth:  {roomproduct.jobProductData.Depth}</label>
                                                                        : ''
                                                                        }
                                                                        </div>
                                                                        <div className="pdt-img col-md-5"> <img alt={roomproduct.jobProductData.ProductName} src={roomproduct.jobProductData.image} /> </div>

                                                                        <div className="pdtl col-md-12">
                                                                            <label>Stock Code:  {roomproduct.jobProductData.StockCode}</label>
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Price: {"$ "+parseFloat(roomproduct.jobProductData.Price).toFixed(2)}</label>
                                                                        </div>
                                                                        </Fragment>
                                                                      )}
                                                                </li>
                                                            )
                                                        }) : ""
                                                    }
                                                </ul>
                                                <div className="room-total-section">Total: <span>{this.formatCurrency(roomlist.total) }</span></div>
                                            </div>
                                        </div>
                                    </div>

                                 </Fragment>
                                )
                            })
                    }

                </div>



                {/* <div className="total-cost-section">
                    <div className="row">
                        <div className="col-md-7" style={{fontSize: '17px'}}>
                            {strings.TotalCost}:
                        </div>
                        <div className="col-md-5">
                            <span>{this.formatCurrency(result)}</span>
                        </div>
                    </div>
                </div> */ }

            </React.Fragment>

            </Fragment>
        )
    }
}

class RoomTitle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.room.id,
            name: props.room.name
        }

        this.rename = this.rename.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState( {name: e.target.value} );
    }

    rename() {
        this.props.rename(this.state);
    }

    render() {

      const isEditable = (this.props.room.name === 'Accessories') ? true : false
        return (
            <ContentEditable
                id={this.props.room.id}
                html={this.state.name}
                disabled={isEditable}
                onChange={this.handleChange}
                onBlur={this.rename}
                tagName="label"
            />
        )
    }

}


class StickerName extends Component {

    constructor(props) {
        super(props);
        //console.log('props.data', props)
        this.state = {
            id: props.id,
            stickername: (props.data.StickerName) ? props.data.StickerName : props.data.ProductName
        }

        this.rename = this.rename.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState( {stickername: e.target.value} );
    }

    rename() {
        //console.log('this.state', this.state);
        this.props.renameSticker(this.state);
    }

    render() {


        return (
            <ContentEditable
                id={this.props.data.key}
                html={this.state.stickername}
                onChange={this.handleChange}
                onBlur={this.rename}
                tagName="label"
            />
        )
    }

}

class EditableQuantity extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            ProductQty: props.data.fixedQty ? props.data.fixedQty : 1
        }

        this.updateQty = this.updateQty.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeQty = this.changeQty.bind(this);
    }

    handleChange(e) {
        this.setState( {
          ProductQty: e.target.value
        }, () => {
          this.props.updateQty(this.state);
        });
    }

    updateQty() {
        //console.log('this.props', this.props);
        this.props.updateQty(this.state);
    }

    changeQty(qty, name, ev){
      this.setState(function(previousState, currentProps) {
        return {
           ProductQty:  (parseInt(previousState.ProductQty) + qty > 0) ? parseInt(previousState.ProductQty) + qty : 1
        }
      }, () => {
        this.props.updateQty(this.state);
      });
    }

    render() {

        //console.log('this.props', this.props);

        return (
            <label style={{ fontSize: "16px", fontWeight: "900" }}>Quantity:
            <div className="room order_info d-flex flex-row">
            <div className="ProductAddCart product_quantity clearfix">
                <input id="quantity_input" type="text" pattern="[0-9]*" step="1"
                       min="1"
                       max="99" value={this.state.ProductQty.toString()} onChange={this.handleChange}  />
                <div className="quantity_buttons">
                    <div id="quantity_inc_button" onClick={this.changeQty.bind(null, 1)} className="quantity_inc quantity_control"><i
                                className="fa fa-chevron-up"></i></div>
                    <div id="quantity_dec_button" onClick={this.changeQty.bind(null, -1)} className="quantity_dec quantity_control"><i
                                className="fa fa-chevron-down"></i></div>
                </div>
            </div>
            </div>{/*
              <ContentEditable
                id={this.props.data.key}
                html={this.state.ProductQty.toString()}
                onChange={this.handleChange}
                onBlur={this.updateQty}
                tagName="span"
                style={{width: '60px', textAlign: 'right', padding:'2px 10px', border:'1px solid #ddd'}}
            />*/}
            </label>

        )
    }

}

export default AccessoriesRoom
