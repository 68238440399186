import React, { Component } from 'react';
import config from '../../config';
import swal from "sweetalert";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import { strings } from '../../components/strings';

import validate from '../../components/Validation';

class Customers extends Component {
    constructor(props) {
        super(props);
		var authuser = JSON.parse(localStorage.getItem('customer'));

        this.state = {
			CustomerId: authuser.CustomerId,
			Token: authuser.token,
			Name: authuser.Name,
            Email: authuser.Email,
            DisplayName: authuser.DisplayName,
            MobileNumber: authuser.MobileNumber,
            Company: authuser.Company,
            ABN: authuser.ABN,
            ABNClass: 'form-control',
            InvoiceAddress: authuser.InvoiceAddress,
            ShippingAddress: authuser.ShippingAddress,
            pay_by_account: authuser.pay_by_account,
            CreditLimit : authuser.CreditLimit,
            CreditBalance: authuser.CreditBalance,
            nameClass: 'form-control',
            mobileClass: 'form-control',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleABN = this.handleABN.bind(this);
        this.onSelectInvoiceAddress = this.onSelectInvoiceAddress.bind(this);
        this.onSelectShippingAddress = this.onSelectShippingAddress.bind(this);
        this.handleInvoiceAddress = this.handleInvoiceAddress.bind(this);
        this.handleShippingAddress = this.handleShippingAddress.bind(this);
    }

    handleInvoiceAddress(data, details) {
        console.log(data, details);
    }

    handleShippingAddress(e) {

    }

    handleABN(e) {
        const value = e.target.value;
        const valid = false;

        this.setState( { ABN: value, ABNValid: valid } );

    }

    handleChange(e) {
        this.setState({ [e.target.name] : e.target.value, nameClass: 'form-control', mobileClass: 'form-control'});
    }

    onSelectInvoiceAddress = async(address) => {

        geocodeByPlaceId(address.place_id)
		.then(data => {

            this.setState({
				InvoiceAddress: data[0].formatted_address,
			})
		})
		.catch(error => console.log(error));
    }

    onSelectShippingAddress = async(address) => {
		geocodeByPlaceId(address.place_id)
		.then(data => {
            this.setState({
				ShippingAddress: data[0].formatted_address,
			})
		})
		.catch(error => console.log(error));
	}

  handleFormSubmit() {
    const {CustomerId, Name, MobileNumber,Company, InvoiceAddress, ShippingAddress, Token, DisplayName, ABN, CreditLimit} = this.state

    let valid = true;

    if (Name.length == 0) {
        valid = false;
        this.setState( {nameClass: 'form-control error'} );
    }

    if (MobileNumber.length === 0 || !validate.ValidatePhone(MobileNumber)) {
        valid = false;
        this.setState( {mobileClass: 'form-control error'} );
    }

    if (ABN && ABN.length > 0) {

        const weight = [10, 1, 3, 5, 7 , 9 , 11, 13, 15, 17, 19];

        let sum = 0;

        for(let i=0; i < ABN.length; i++) {

            let abn = ABN[i];

            if (i === 0) {
                abn -= 1;
            }

            //console.log('abn', ABN[i], weight[i], parseInt(abn) * weight[i]);

            sum += (parseInt(abn) * weight[i]);

        }

        if (sum % 89 > 0) {
            valid = false;
            this.setState( {ABNClass: 'form-control error'} );
        }

    }

    if (valid) {

        var	data = {
            CustomerId: CustomerId,
            Name: Name,
            DisplayName: DisplayName,
            MobileNumber: MobileNumber,
            Company: Company,
            InvoiceAddress: InvoiceAddress,
            ShippingAddress: ShippingAddress,
            ABN: ABN,
            CreditLimit: CreditLimit

        }

        fetch(config.BASE_URL + '/api/updatecustomer', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        }).then((response) => response.json())
        .then((data) => {

                localStorage.setItem("customer", JSON.stringify(data.customer));
                swal("", `${strings.Profileupdatedsuccessfully}`, "success");

            })
        .catch((error) => {
                console.log("Error, with message::", error)
        });
    }
  }


    render() {

        const CreditLimit = this.state.CreditLimit;
        const CreditBalance = this.state.CreditBalance ? this.state.CreditBalance : '';

        return (

            <div>
                <div className="dashboard-wrapper partscontent">
                    <div className="dashboardhome">
                        <div className="container-fluid dashboard-content jobspagecnt">
                            {/* ============================================================== */}
                            {/* pagecontent  */}
                            {/* ============================================================== */}
							<div className="row">
                            <div className="col-md-12">
                                <ol className="breadcrumb breadm">
                                    <li className="active"><a href="/dashboard">Dashboard</a></li>
                                    <li ><i className="fa fa-angle-right midarrow" /><a href="/customer/profile" style={{ cursor: "pointer" }}>Profile</a></li>
                                </ol>
                            </div>
							</div>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    {/* top heading section*/}
                                    <div className="inner-pagehead">
                                        <h1>Edit Profile</h1>

                                    </div>
                                    {/* top heading section*/}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <form autocomplete="false">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="name-data" className="col-form-label">Name:</label>
                                                <div>
                                                    <input className={this.state.nameClass} name="Name" type="text"	value={this.state.Name}
                                                        onChange={this.handleChange} />
                                                    <span class="error-message">This is required. Please enter your name.</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 offset-md-1">
                                            <div className="form-group">
                                                <label htmlFor="name-data" className="col-form-label">Display Name:</label>
                                                <div>
                                                    <input className={this.state.nameClass} name="DisplayName" type="text"	value={this.state.DisplayName}
                                                        onChange={this.handleChange} />
                                                    <span class="error-message">This is required. Please enter your display name.</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="email-data" className="col-form-label">Email:</label>
                                                <div>
                                                    <input className='form-control' name="Email" type="email" disabled
                                                        value={this.state.Email} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 offset-md-1">
                                            <div className="form-group">
                                                <label htmlFor="name-data" className="col-form-label">ABN:</label>
                                                <div>
                                                    <input className={this.state.ABNClass} name="ABN" type="text"	value={this.state.ABN}
                                                        onChange={this.handleABN} />
                                                    <span class="error-message">ABN is not valid</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="phone-data" className="col-form-label">Phone:</label>
                                                <div>
                                                    <input className={this.state.mobileClass} name="MobileNumber" type="text"
                                                        value={this.state.MobileNumber} onChange={this.handleChange} />
                                                    <span class="error-message">This is required. Invalid mobile number.</span>
                                                </div>
                                            </div>
                                        </div>
                                        { this.state.pay_by_account == 1 ?
                                            <div className="col-md-4 offset-md-1">
                                                <div className="form-group">
                                                    <label htmlFor="name-data" className="col-form-label">Credit Limit:</label>
                                                    <div>
                                                        <input className="form-control" type="text"	value={CreditLimit.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} disabled
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            : '' }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="phone-data" className="col-form-label">Company:</label>
                                                <div>
                                                    <input className="form-control" name="Company" type="text"
                                                        value={this.state.Company} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        { this.state.pay_by_account == 1 ?
                                            <div className="col-md-4 offset-md-1">
                                                <div className="form-group">
                                                    <label htmlFor="name-data" className="col-form-label">Credit Balance:</label>
                                                    <div>
                                                        <input className="form-control" type="text"	value={CreditBalance.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} disabled
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            : '' }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="phone-data" className="col-form-label">Invoice Address:</label>
                                                <div>
                                                    <GooglePlacesAutocomplete
                                                        idPrefix="InvoiceAddress"
                                                        required={false}
                                                        name="InvoiceAddress"
                                                        onSelect={this.onSelectInvoiceAddress}
                                                        inputClassName="form-control"
                                                        placeholder="Invoice Address"
                                                        initialValue = {this.state.InvoiceAddress}
                                                        debounce={400}
                                                        renderInput={(props) => (
                                                            <input name="InvoiceAddress" onKeyUp={(e) => { this.setState( { [e.target.name] : e.target.value} ) }} className="form-control" {...props} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="phone-data" className="col-form-label">Shipping Address:</label>
                                                <div>
                                                    <GooglePlacesAutocomplete
                                                        idPrefix="ShippingAddress"
                                                        name="ShippingAddress"
                                                        required={false}
                                                        onSelect={this.onSelectShippingAddress}
                                                        inputClassName="form-control"
                                                        placeholder="Shipping Address"
                                                        initialValue = {this.state.ShippingAddress}
                                                        debounce={400}
                                                        renderInput={(props) => (
                                                            <input name="ShippingAddress" onKeyUp={(e) => { this.setState( { [e.target.name] : e.target.value} ) }} className="form-control" {...props} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

									<div className="form-group">
									<button type="button" className="btn btn-primary" onClick={this.handleFormSubmit.bind(this)}>
										Save
									</button>
									</div>
                                </form>
								</div>
								</div>
                                </div>
                            </div>
                            {/* ============================================================== */}
                            {/* end pagecontent  */}
                            {/* ============================================================== */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Customers;
