import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import config from "../config";
//import Room from './Room'

//import AccessoriesRoom from './AccessoriesRoom';
//import TotalCost from './TotalCost';

import { Carousel } from 'react-responsive-carousel';

//import OrderConfirm from './OrderConfirm';

import { strings } from './strings';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Accessories extends Component {
  constructor(props) {
      super(props);
      //console.log(props)
      this.state = {
        stockCodes: (typeof props.stockcodes !== 'undefined') ? props.stockcodes : [],
        materialType: (typeof props.materialType !== 'undefined') ? props.materialType : [],
        doorStyle: (typeof props.doorStyle !== 'undefined') ? props.doorStyle : [],
        brand: (typeof props.brand !== 'undefined') ? props.brand : [],
        finish: (typeof props.finish !== 'undefined') ? props.finish : [],
        colour: (typeof props.colour !== 'undefined') ? props.colour : [],
        substrate: (typeof props.substrate !== 'undefined') ? props.substrate : [],
        material: (typeof props.material !== 'undefined') ? props.material : [],
        layouts: (typeof props.layouts !== 'undefined') ? props.layouts : [],
        updateKey: ' ',
        products: props.products,
        product: props.product,
        doorHinge: (typeof props.doorHinge !== 'undefined') ? props.doorHinge : [],
        shelves: (typeof props.shelves !== 'undefined') ? props.shelves : [],
        materialArray: [],
        productsArray: localStorage.getItem("postData") ? JSON.parse(localStorage.getItem("postData")) : [],
        colorBoxUrl: "https://www.polytec.com.au/docs/technical/polytec-technical-availability-guide.pdf",
        hardware: [],
        rooms: [],

        doorHardwareId: 0,

        cHeight: props.product && props.product.Height ? props.product.Height : "",
        cWidth: props.product && props.product.Width ? props.product.Width : "",
        cDepth: 0,
        cToeRecess: props.product && props.product.ToeRecess ? props.product.ToeRecess : "",
        cElevation: props.product && props.product.Elevation ? props.product.Elevation : "",
        cSoffitHeight: props.product && props.product.SoffitHeight ? props.product.SoffitHeight : "",
        cToeHeight: props.product && props.product.ToeHeight ? props.product.ToeHeight : "",

        HardwareLayout: props.product && props.product.HardwareLayout ? props.product.HardwareLayout : "",

        cWidthError: "",
        cHeightError: "",
        cDepthError: "",
        cToeHeightError: "",
        cToeRecessError: "",
        getRooms: false,
        productSave: props.productSave,
        editHinge: "",
        isLoaded: true,
        postData: {},
        jobProduct: localStorage.getItem("jobProduct") ? JSON.parse(localStorage.getItem("jobProduct")) : [],
        dynProName: "",
        colourfilter: [],
        mcolour: "1",
        mshelves: "1",
        productprice: "",
        carcasecost: "",
        project: {},



        details: "",

        MaterialType1 : 1,
        MaterialType2 : 1,
        MaterialType3 : 0,
        MaterialType4 : 0,
        MaterialType5 : 0,
        MaterialType6 : 0,

        MaterialBrand1 : 1,
        MaterialBrand2 : 1,
        MaterialBrand3 : 0,
        MaterialBrand4 : 0,
        MaterialBrand5 : 0,
        MaterialBrand6 : 0,

        MaterialFinish1 : 1,
        MaterialFinish2 : 1,
        MaterialFinish3 : 0,
        MaterialFinish4 : 0,
        MaterialFinish5 : 0,
        MaterialFinish6 : 0,

        MaterialSubstrate1 : 2,
        MaterialSubstrate2 : 2,
        MaterialSubstrate3 : 0,
        MaterialSubstrate4 : 0,
        MaterialSubstrate5 : 0,
        MaterialSubstrate6 : 0,

        MaterialColour1 : 1,
        MaterialColour2 : 1,
        MaterialColour3 : 0,
        MaterialColour4 : 0,
        MaterialColour5 : 0,


        isLogin: false,

        Width: 600,
        Height: 860,
        Depth: 0,
        Elevation: null,
        RightScribe: null,
        ToeHeight: 110,
        ToeRecess: 30,
        SoffitHeight: null,
        PicturePath: null,
        DoorId: 1,
        WidthMin: 200,
        WidthMax: 600,
        HeightMin: 900,
        HeightMax: 900,
        DepthMin: 200,
        DepthMax: 800,
        ToeHeightMin: 90,
        ToeHeightMax: 170,
        ToeRecessMin: 30,
        ToeRecessMax: 30,
        SoffitHeightMin: null,
        SoffitHeightMax: null,
        ElevationMin: null,
        ElevationMax: null,
        TotalHardwareCost: 0,
        NoOfDoors: 1,

        fixedQty: props.product.fixedQty ? props.product.fixedQty : 1,
        equalQty: props.product.equalQty ? props.product.equalQty : 3,
        adjustableQty: props.product.adjustableQty ? props.product.adjustableQty : 3,

        gapTop: props.product && props.product.DoorGapTop ? props.product.DoorGapTop: 3,
        gapBottom: props.product && props.product.DoorGapBottom ? props.product.DoorGapBottom: 0,
        gapLeft: props.product && props.product.DoorGapleft ? props.product.DoorGapLeft: 1.5,
        gapRight: props.product && props.product.DoorGapRight ? props.product.DoorGapRight: 1.5,

        HardwareId: 1,
        drawerHardwareId: 1,

        noOfShelve: 1,
        HingeId: 1,

        Gst: 0.1,
        allow : ["Pending"],
        productImage : ["/images/slide_img1.jpg", "/images/slide_img2.jpg", "/images/slide_img1.jpg", "/images/slide_img2.jpg"],

		      detailImage: [],
		        drawerGaps: props.product.drawerGaps,
		          show: true,
		            imageTips: {},
        lineTop: false,
        lineLeft: false,
        lineBottom: false,
        lineRight: false,
        stockCode: '',
        ProductType: 'accessories',
        ProductDescription: '',
        ProductStockCode: '',
        ProductPrice: 0,
        ProductQty: 1,
        ProductLineTotal: 0,
        ProductStockImage: '/images/slide_img1.jpg',
        imageheight: {},
        isCustomized: 0
      }

      this.handleChange = this.handleChange.bind(this)

      this.formatCurrency = this.formatCurrency.bind(this)
      this.addToCart = this.addToCart.bind(this)
      this.truncateText = this.truncateText.bind(this)
      this.AddQty = this.AddQty.bind(this)
      this.LessQty = this.LessQty.bind(this)
      this.setRoom = this.setRoom.bind(this)
      this.onImgLoad = this.onImgLoad.bind(this)
  }

  setRoom(){

  }
   componentDidMount(){
     //console.log(this.props)
     //this.props.setRoom({id: 2, name: 'Accessories'});
   }

    componentWillMount() {
        //this.getImageTips();
    }

  shouldComponentUpdate(newProps) {
      const product = newProps.product;

      if (product && product.ProductName) {
          //console.log('should', product)
          return true;
      }

      return false;
  }

  /* componentDidUpdate(newProps, prevState) {


  } */

  componentDidUpdate(newProps, prevState) {

    //console.log('new', newProps)
	  //console.log('this', this.props);
	  //console.log('this.state', this.state)

    if (this.props.updateKey !== prevState.updateKey) {

        const product = this.props.product;
		    //const products = this.props.products
         //console.log('3. new product', product)

		    var lastProduct = this.props.products.slice(-1).pop()

        //const MaterialData = (lastProduct) ? lastProduct.materialData : [];
        const hardwareData = (lastProduct) ? lastProduct.hardwareData : [];

        //console.log('MaterialData', MaterialData)
		    //console.log('new lastProduct', lastProduct);
        //console.log('new materialdata', MaterialData);
		    //console.log('new hardwareData', hardwareData);

        const noDoor =  7;
        const twoDoor = 3;

        let hingeId = product.HingeId ? product.HingeId : 1;

        if (product.NoOfDoors === 0) {
            hingeId = noDoor;
        }

        if (product.NoOfDoors === 2) {
            hingeId = twoDoor;
        }

        this.setState({
            product           : product,
            Width             : product.Width,
            Height            : product.Height,
            Depth             : 0,
            Elevation         : product.Elevation,
            RightScribe       : product.RightScribe,
            ToeHeight         : product.ToeHeight,
            ToeRecess         : product.ToeRecess,
            SoffitHeight      : product.SoffitHeight,
            PicturePath       : product.PicturePath,
            WidthMin          : product.WidthMin,
            WidthMax          : product.WidthMax,
            HeightMin         : product.HeightMax,
            HeightMax         : product.HeightMax,
            DepthMin          : product.DepthMin,
            DepthMax          : product.DepthMax,
            ToeHeightMin      : product.ToeHeightMin,
            ToeHeightMax      : product.ToeHeightMax,
            ToeRecessMin      : product.ToeRecessMin,
            ToeRecessMax      : product.ToeRecessMax,
            SoffitHeightMin   : product.SoffitHeightMin,
            SoffitHeightMax   : product.SoffitHeightMax,
            ElevationMin      : product.ElevationMin,
            ElevationMax      : product.ElevationMax,
            TotalHardwareCost : product.TotalHardwareCost,
            NoOfDoors         : product.NoOfDoors,
            noOfShelve        : product.noOfShelve ? product.noOfShelve : 1,
            mshelves          : product.mshelves ? product.mshelves : 2,
            HingeId           : hingeId,
            HardwareId        : product.hardwareId ? product.hardwareId : 1,

            HardwareLayout    : product.HardwareLayout ? product.HardwareLayout : '',

            drawerHardwareId  : product.hardwareId ? product.harwareId: 1,
            doorHardwareId    : hardwareData.doorHardwareId ? hardwareData.doorHardwareId: 1,

            fixedQty : product.fixedQty ? product.fixedQty : 1,
            equalQty : product.equalQty ? product.equalQty : 3,
            adjustableQty : product.adjustableQty ? product.adjustableQty : 3,

            DoorId: 1,

            MaterialType1 : 1,
            MaterialType2 : 1,
            MaterialType3 : 0,
            MaterialType4 : 0,
            MaterialType5 : 0,
            MaterialType6 : 0,

            MaterialBrand1 : 1,
            MaterialBrand2 : 1,
            MaterialBrand3 : 0,
            MaterialBrand4 : 0,
            MaterialBrand5 : 0,
            MaterialBrand6 : 0,

            MaterialFinish1 : 1,
            MaterialFinish2 : 1,
            MaterialFinish3 : 0,
            MaterialFinish4 : 0,
            MaterialFinish5 : 0,
            MaterialFinish6 : 0,

            MaterialSubstrate1 : 2,
            MaterialSubstrate2 : 2,
            MaterialSubstrate3 : 1,
            MaterialSubstrate4 : 1,
            MaterialSubstrate5 : 1,
            MaterialSubstrate6 : 1,

            MaterialColour1 : 2,
            MaterialColour2 : 2,
            MaterialColour3 : 0,
            MaterialColour4 : 0,
            MaterialColour5 : 0,

			hasDoor     : product.hasDoor,
			hasDrawer   : product.hasDrawer,
			drawerGaps  : product.drawerGaps,
            detailImage : !Array.isArray(product.detailImage) ? product.detailImage : [],

            Gst         : newProps.gst,
            material    : newProps.material,
            materialType: newProps.materialType,
            brand       : newProps.brand,
            substrate   : newProps.substrate,
            doorStyle   : newProps.door,
            hardware    : newProps.hardware,
            doorHinge   : newProps.hinge,
            shelves     : newProps.shelve,
            layouts     : newProps.layouts,
            stockCodes  : newProps.stockcodes,

            details     : product.details ? product.details : '',

            gapTop      : product.DoorGapTop ? product.DoorGapTop: 3,
            gapBottom   : product.DoorGapBottom ? product.DoorGapBottom : 0,
            gapRight    : product.DoorGapRight ? product.DoorGapRight : 1.5,
            gapLeft     : product.DoorGapLeft ? product.DoorGapLeft : 1.5,

            updateKey   : this.props.updateKey
            //stockCodes: newProps.stockCodes
        }, () => {

            //this.getMaterial(product);
            //this.getMaterialType();
            //this.getBrands();
            //this.getSubstrate();
            //this.getDoor();
            //this.getHardware();
            //this.getDoorHinge();
            //this.getShelves();
            //this.getProductStockCodes();

            //this.getGst();

            if (product.JobProductId > 0) {

                //this.getJobMaterials(product.JobProductId);
                //this.getJobDoors(product.JobProductId);
                //this.getJobHardwares(product.JobProductId);
                //this.getJobShelves(product.JobProductId);
                //this.getJobHinges(product.JobProductId);

            }

        });



        //this.recalculate();

    }

  }

  updateCart() {
	//console.log('home updateCart', this.state);
    this.props.updateCart(this.state);
  }

  addToCart(productstockcode, ev) {
    var ProductQty = (this.state[`qty${productstockcode.ProductStockCodeId}`]) ? (this.state[`qty${productstockcode.ProductStockCodeId}`]) : 1;
    //console.log('this.state[`qty${productstockcode.ProductStockCodeId}`]', this.state[`qty${productstockcode.ProductStockCodeId}`])
    //console.log('productstockcode', productstockcode)
    //console.log('productstockcode.Image', productstockcode.Image)
    const Image = productstockcode.Image && productstockcode.Image != null  ? productstockcode.Image.split(',') : ['/storage/upload/no-image.jpg']

    this.setState({
        ProductStockCode: productstockcode.StockCode,
        stockCode: productstockcode.StockCode,
        ProductDescription: productstockcode.Details,
        ProductPrice: productstockcode.HardwareCost ? productstockcode.HardwareCost : 0,
        FixedShelve: ProductQty,
        ProductStockImage: Image[0],
        ProductLineTotal: parseFloat(productstockcode.HardwareCost) * parseInt(ProductQty)
    }, () => {
      this.props.addToCart(this.state);
      this.setState({
          ProductQty: 1,
      });
    });
  }

  handleChange = (e) => {
    this.setState({
        [e.target.name]: parseInt(e.target.value)
    }, () => {
    });

  }

  formatCurrency(money){
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(money);
  }

  truncateText (text, limit){
    if (text.length > limit){
        for (let i = limit; i > 0; i--){
            if(text.charAt(i) === ' ' && (text.charAt(i-1) != ','||text.charAt(i-1) != '.'||text.charAt(i-1) != ';')) {
                return text.substring(0, i) + '...';
            }
        }
         return text.substring(0, limit) + '...';
    }
    else
        return text;
  }

  AddQty(qty, name, ev){
    this.setState(function(previousState, currentProps) {
      return {
         [name]: !isNaN(previousState[name]) ? parseInt(previousState[name]) + qty : 2
      }
    }, () => {
      console.log(this.state)
    });
  }

  LessQty(qty, name, ev){
    this.setState(function(previousState, currentProps) {
      return {
         [name]: !isNaN(previousState[name]) && (parseInt(previousState[name]) + qty > 0) ? parseInt(previousState[name]) + qty : 1
      }
    }, () => {
      console.log(this.state)
    });
  }

  onImgLoad(stockcode, ev){
    const {imageheight} = this.state
    imageheight[stockcode] = ev.target.offsetHeight
    this.setState({
      imageheight: imageheight
    });
  }
  render() {

    //console.log('4. product home', this.state);
    //const {imageheight} = this.state;
    //console.log('imageheight', imageheight);
    const product = this.props.product;

    //const status = product.Status ? product.Status : 'Pending';

    //const productSave = this.props.productSave;

    //console.log('5. jobProduct', this.props);
    const stockcodes = this.state.stockCodes

     const { history } = this.props
     const { params } = this.props.match
     let SelectedStockCode = {}
     if (Object.keys(params).length > 0) {
         var productId = params.id
         var productSku = params.prodsku
         if (typeof productSku !== 'undefined') {
            SelectedStockCode = stockcodes.filter(stockcode =>{
             return stockcode.StockCode.toString() == productSku.toString()
           });
         }

     }

    //const maxheight = 200
    //const desclength = imageheight < maxheight ? (maxheight-imageheight) + 150 : 150
    //console.log('imageheight', imageheight)

    const customer = localStorage.getItem("customer");
    const backbtn = customer ? '/dashboard/edit-order/603/'+product.ProductId : '/'+product.ProductId

    return (
      <>
                    <div className="col-xs-12 col-sm-6 col-md-9 midtabsection">
                      <div className="ProductGrids" style={{width: '100%', minHeight: '400px', background: '#FFF', borderRadius: '4px', padding: '25px'}}>
                      {
                        (Object.keys(SelectedStockCode).length == 0)  ?
                          stockcodes && stockcodes.length > 0 ? stockcodes.map((stockcode, key) => {
                            if (stockcode.ProductId === product.ProductId) {
                            const images = stockcode.Image !== null ? stockcode.Image.toString().split(',') : ['/storage/upload/no-image.jpg'];
                            const stockImage = images[0]
                            const qty = !isNaN(this.state["qty"+stockcode.ProductStockCodeId]) ? parseInt(this.state["qty"+stockcode.ProductStockCodeId]) : 1

                            const maxheight = 200
                            const {imageheight} = this.state
                            //console.log('imageheight', imageheight)
                            const imgheight = imageheight[stockcode.StockCode] ? imageheight[stockcode.StockCode] : 200
                            const desclength = imgheight < maxheight ? (maxheight-imgheight) + 150 : 100
                            //console.log('imgheight', imgheight)

                            const detailurl = customer ? '/dashboard/edit-order/603/'+stockcode.ProductId+'/'+stockcode.StockCode : '/'+stockcode.ProductId+'/'+stockcode.StockCode

                            return (
                            <>
                            <div className="col-xs-5 col-sm-5 col-md-5 ProductGridItem">
                              <div className="ProductImage"><img alt="" onLoad={this.onImgLoad.bind(null, stockcode.StockCode)} onClick={() => history.push(detailurl)} src={config.BASE_URL+stockImage} /></div>
                              <h3 onClick={() => history.push(detailurl)} className="ProductName">{stockcode.displayname ? stockcode.displayname : product.ProductName}</h3>
                              <div onClick={() => history.push(detailurl)} className="ProductDescription">{this.truncateText(stockcode.Details, desclength)}</div>
                              <div className="ProductPrice"><b>{this.formatCurrency(stockcode.HardwareCost)}</b> <span className="gst-included">{strings.GstIncluded}</span></div>
                              <div className="order_info d-flex flex-row">
                              <div className="ProductAddCart product_quantity clearfix">
                                  <input id="quantity_input" type="text" pattern="[0-9]*" step="1"
                                         min="1"
                                         max="99" value={qty} name={"qty"+stockcode.ProductStockCodeId} onChange={this.handleChange}  />
                                  <div className="quantity_buttons">
                                      <div id="quantity_inc_button" onClick={this.AddQty.bind(null, 1, "qty"+stockcode.ProductStockCodeId)} className="quantity_inc quantity_control"><i
                                                  className="fa fa-chevron-up"></i></div>
                                      <div id="quantity_dec_button" onClick={this.LessQty.bind(null, -1, "qty"+stockcode.ProductStockCodeId)} className="quantity_dec quantity_control"><i
                                                  className="fa fa-chevron-down"></i></div>
                                  </div>
                              </div>
                              <div className="ProductAddCart"><button style={{width: '150px', padding: '5px 10px', margin: '0px'}} className="upsize" onClick={this.addToCart.bind(null, stockcode)}>{strings.AddToCart}</button></div>
                              </div>

                            </div>
                            </>
                            )
                          }

                          return ;

                          }) : ''
                        :
                        SelectedStockCode.map((stockcode, key) => {
                        const qty = !isNaN(this.state["qty"+stockcode.ProductStockCodeId]) ? parseInt(this.state["qty"+stockcode.ProductStockCodeId]) : 1
                        return (
                        <>
                        <div className="ProductBackButton"><button onClick={() => history.push(backbtn)} title="Back" className="arrow prev link-button">« previous</button></div>
                        <div className="col-xs-6 col-sm-6 col-md-6 ProductDetail">
                          <div className="ProductImage">
                          <ImageSlider Image={stockcode.Image} />
                          </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 ProductDetail">
                          <h3 className="ProductName">{stockcode.displayname ? stockcode.displayname : product.ProductName}</h3>
                          <div className="ProductPrice"><b>{this.formatCurrency(stockcode.HardwareCost)}</b> <span className="gst-included">{strings.GstIncluded}</span></div>
                          <div className="ProductDescription">{stockcode.Details}</div>
                          {/*<div className="ProductAddCart"><input defaultValue={1} type="text" name={"qty"+SelectedStockCode[0].ProductStockCodeId} onChange={this.handleChange} /> <button  className="upsize" onClick={this.addToCart.bind(null, SelectedStockCode[0])}>{strings.AddToCart}</button></div>*/}
                          <div className="order_info d-flex flex-row">
                          <div className="ProductAddCart product_quantity clearfix">
                              <input id="quantity_input" type="text" pattern="[0-9]*" step="1"
                                     min="1"
                                     max="99" value={qty} name={"qty"+stockcode.ProductStockCodeId} onChange={this.handleChange}  />
                              <div className="quantity_buttons">
                                  <div id="quantity_inc_button" onClick={this.AddQty.bind(null, 1, "qty"+stockcode.ProductStockCodeId)} className="quantity_inc quantity_control"><i
                                              className="fa fa-chevron-up"></i></div>
                                  <div id="quantity_dec_button" onClick={this.LessQty.bind(null, -1, "qty"+stockcode.ProductStockCodeId)} className="quantity_dec quantity_control"><i
                                              className="fa fa-chevron-down"></i></div>
                              </div>
                          </div>
                          <div className="ProductAddCart"><button style={{padding: '5px 10px', margin: '0px'}} className="upsize" onClick={this.addToCart.bind(null, stockcode)}>{strings.AddToCart}</button></div>
                          </div>
                        </div>
                        <div style={{float: 'none', clear: 'both'}}></div>
                        </>
                      )})
                      }
                      <div style={{float: 'none', clear: 'both'}}></div>
                      </div>
                    </div>

                    <ReactTooltip place="top" html={true} />


      </>
    )
  }
}

class ImageSlider extends Component {

    constructor(props) {

        super(props);
        this.state = {
          isOpen: false,
          currentImage: 0
        }
        this.onClickItem = this.onClickItem.bind(this)
    }

    onClickItem(index){
      //console.log(ev)
      this.setState({ currentImage: index, isOpen: true })
    }

    render() {
      //console.log('this.props.Image', this.props.Image)
      const {Image} = this.props

      var images = Image && Image != '' ? Image.split(',') : ['/storage/upload/no-image.jpg'];

      var imageshtml = images.map( (img, key) => {
            var image = `${config.BASE_URL}${img}`
            return (
                <div key={key}>
                    <img alt="" id="carousel-img" src={image} />
                    <div className="overlay-bg" />
                </div>
            )
        });

        return (
          <>
            <Carousel  onClickItem={this.onClickItem} autoPlay={false}>
            { imageshtml }
            </Carousel>
            {
              this.state.isOpen &&
            <Lightbox
                wrapperClassName="ProductLargeImage"
                mainSrc={`${config.BASE_URL}${images[this.state.currentImage]}`}
                onCloseRequest={() => this.setState({ isOpen: false })}
            />
          }
          </>
        )
    }

}

export default withRouter(Accessories)
