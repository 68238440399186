import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'

class Sidebar extends Component {
    render() {
        return (
            <div className="nav-left-sidebar sidebar-dark">

                

                

                        <ul>

                            <li><a data-tip="My Details" href="/customer/profile" className="nav-link"><i className="fa fa-user" /></a></li>
                            <li><a data-tip="Projects" href="/dashboard" className="nav-link"><i className="fas fa-cube" /></a></li>
                    
                        </ul>

                        <ReactTooltip place="right" />

            </div>
        )
    }
}

export default Sidebar;