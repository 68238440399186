import React, { Component } from 'react'


class Newsletter extends Component {


  render() {
    return (
      <>
        <div className="form-group">
            <label>Name *</label>
            <input type="text" name="name" className="form-control" />
          </div>
          <div className="form-group">
            <label>Email *</label>
            <input type="text" name="name" className="form-control" />
          </div>
          <button className="btn btn-primary">Submit</button>
      </>
    )
  }

}

export default Newsletter