import React, { Component } from 'react';

import config from '../../config';
//import TotalCost from '../TotalCost';

class ViewOrder extends Component {

    constructor(props) {

        super(props);

        this.state = {
            jobdata: [],
            orderId: 0,
            TotalWeight: 0,
            totalCost: 0,
            jobrooms: [],
            strings: [],
            type: ''
        }

    }

    componentDidMount() {

        const orderId = this.props.match.params.id;

        fetch(config.BASE_URL + '/api/view-order/' + orderId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => { 
        
            this.setState( { 
                jobdata: data.jobdata, 
                orderId: orderId,
                TotalWeight: data.TotalWeight,
                totalCost: data.totalCost,
                jobrooms: data.jobrooms,
                strings: data.strings,
                type: data.type
            } )
            
        });

    }
    
    render() {

        const { jobdata, orderId, TotalWeight, totalCost, jobrooms } = this.state;

        const jobRooms = jobrooms.map( (j,key) => {

            return <JobData 
                        key={key}
                        data={j} 
                        strings={this.state.strings} 
                        index={key} 
                        jobData={jobdata} 
                        type={this.state.type}
                    />

        });

        return (
            <>
                <div className="view-order bg-white pt-4 pb-4 mb-50">

                    <div className="logo text-center p-4">

                        <img alt="" src={config.TOP_LOGO} />

                    </div>

                    <table className="table table-border bg-white">
                        <tbody className="bg-white">
                            <tr>
                                <td colSpan="5" 
                                    className="text-center">Custom Made Cabinetry for Australia's Cabinet Makers & Builders</td>
                            </tr>
                            <tr>
                                <td className="text-left" colSpan="2">
                                    info@flatpackswa.com.au<br />
                                    1800 497 143</td>
                                <td className="text-right" colSpan="3">
                                    8 Geelong Court &nbsp;<br />
                                    BIBRA LAKE WA 6163 &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h1 className="text-center m-2">{jobdata.ProjectName} - Job #{orderId}</h1>

                    <div className="ml-2 mr-2 border border-light">

                        <table className="table table-striped mt-0">

                            <tbody>

                                <tr>

                                    <td>Job #:</td>

                                    <td>{orderId}</td>

                                    <td>Contact No:</td>

                                    <td>{jobdata.ContactNo}</td>

                                </tr>    

                                <tr>

                                    <td>Job Belonging to:</td>

                                    <td></td>

                                    <td>Delivered to:</td>

                                    <td>                                    

                                    </td>

                                </tr>   

                                <tr>

                                    <td colSpan="2">{jobdata.Name} { jobdata.Company ? `(${jobdata.Company})` : '' }<br />{jobdata.Email}</td>

                                    <td colSpan="2">{jobdata.ShippingAddressLine1}</td>                                    

                                </tr>

                                <tr> 

                                    <td>Payment Method</td>

                                    <td>{jobdata.PaymentMethod}</td>

                                    <td>Pickup Option</td>
                                    <td>{jobdata.pickupOption}</td>

                                </tr>

                                <tr>

                                    <td>Estimated Weight:</td>

                                    <td>{TotalWeight.toFixed(1)} kg</td>

                                    <td>Total Cost:</td>

                                    <td>$ {totalCost.toFixed(2)}<br />GST Included</td>

                                </tr>

                                <tr>

                                    <td colSpan="4"></td>

                                </tr>

                                {jobRooms}

                            </tbody>

                        </table>                        

                    </div>

                   
                
                </div>
            </>
        )

    }

}

class JobData extends Component {

    constructor(props) {

        super(props);

    } 

    render() {

        const roomData = this.props.data.roomData;

        const productsData = this.props.data.productsData.filter( (p) => {
            return p.roomData === roomData;            
        });
        

        const jobProductData = productsData.map((p,key) => {

            return <ProductData 
                        key={`ProductData${key}`}
                        data={p} 
                        index={key} 
                        strings={this.props.strings} 
                        type={this.props.type} 
                    />

        });

        return (

            <>

                <tr>

                    <td>Room Number</td>

                    <td colSpan="3">{ parseInt(this.props.index) + 1 }</td>
                </tr>

                <tr>

                    <td>Room Name</td>

                    <td colSpan="3">{ roomData }</td>
                </tr>

                <tr>

                    <td>Room Description</td>

                    <td colSpan="3"></td>
                </tr>

                <tr>

                    <td>Company Label</td>

                    <td colSpan="3">{ this.props.jobData.Name } { this.props.jobData.Company ? `(${this.props.jobData.Company})` : '' }</td>
                </tr>

             
                { jobProductData }

             
            </>

        )
    }

}

class ProductData extends Component {

    /* constructor(props) {

        super(props);

    } */

    render() {

        const data = this.props.data;

        const strings = this.props.strings;

        const ProductId = data.ProductId;

        const lineTotal = parseFloat(data.HardwareCost) * parseFloat(data.fixedQty);

        const type = this.props.type;

        const detailImage = JSON.parse(data.detailImage);

        const attached_str = detailImage.map( img => {

            return config.UPLOAD_URL + img

        });

        let HeightLabel = strings.CabinetHeight;
        let WidthLabel = strings.CabinetWidth;

        let materialData = [];

        if (data.ProductType !== 'accessories') {

            materialData = data.materialData.map( (material, key) => {

                return <MaterialData key={`materialData${key}`} material={material} />
            });

        }

        if (data.ProductType === 'panels') {

            HeightLabel = strings.PanelHeight;
            WidthLabel = strings.PanelWidth;

            if (strings.ProductId) {

                if (strings.ProductId.PanelHeight !== '') {

                    HeightLabel = strings.ProductId.PanelHeight;

                }

                if (strings.ProductId.PanelWidth !== '') {

                    WidthLabel = strings.ProductId.PanelWidth;

                }

            }

        }

        const doorData = data.DoorData ? data.DoorData : false;
        const doorHardwareData = data.DoorhardwareData ? data.DoorhardwareData : false;
        const hingeData = data.hingeData ? data.hingeData : false;

        const image = data.image;

        return(

            <>

                <tr>
                    <td colSpan="2" />
                    <td colSpan="2" rowSpan="5" className="text-center"><img alt="" src={ image } style={ { width: '300px', border: 'solid 1px #ccc'}  } /></td>
                </tr>

                <tr>

                    <td>Room Product No</td>

                    <td>{parseInt(this.props.index) + 1} - {data.JobProductId}</td>
                </tr>

                <tr>

                    <td>Type</td>

                    <td>{ data.ProductType === 'accessories' ? 'Accessories' : 'Cabinets' }</td>
                </tr>

                <tr>

                    <td>Product Name</td>

                    <td>{ data.displayname !== '' ? data.displayname : data.ProductName }</td>

                </tr>

                <tr>

                    <td>Sticker Name</td>

                    <td>{ data.StickerName }</td>
                </tr>

                <tr>

                    <td>SKU</td>

                    <td colSpan="3">{ data.StockCode }</td>
                </tr>

                <tr>

                    <td>Quantity</td>

                    <td colSpan="3">{ data.ProductType === 'accessories' ? data.fixedQty : data.ProductQty }</td>
                </tr>

                {
                    data.ProductType === 'accessories' ? 

                        <>

                            <tr>

                                <td>Price</td>

                                <td colSpan="3">$ { data.HardwareCost.toFixed(2) }</td>
                            </tr>

                            <tr>

                                <td>Line Total</td>

                                <td colSpan="3">$ { lineTotal.toFixed(2) }</td>
                            </tr>

                            <tr>

                                <td>Description</td>

                                <td colSpan="3" className="white-space-wrap">{ data.ProductDescription }</td>
                            </tr>

                        </>

                    : ''

                }

                <tr>

                
                    <td>{ HeightLabel }</td>

                    <td colSpan="3">{ data.Height }</td>
                </tr>

                <tr>

                    <td>{ WidthLabel }</td>

                    <td colSpan="3">{ data.Width }</td>

                </tr>

                { 
                
                    data.Depth > 0 ? 

                    <>

                        <tr>

                            <td>{ strings.CabinetDepth }</td>

                            <td colSpan="3">{ data.Depth }</td>
                        </tr>

                    </>

                    : ''

                }

                { data.has_kicker === 1 ?

                    <>

                        <tr>

                            <td>Kicker Height</td>

                            <td colSpan="3">{ data.ToeHeight }</td>
                        </tr>

                    </>

                    : '' }

                {

                    (type === 'admin' && data.ProductType !== 'accessories') ?

                    <>

                        <tr>

                            <td>Door Cost</td>

                            <td colSpan="3">$ { parseFloat(data.DoorFinalCost).toFixed(2) }</td>
                        </tr>

                        <tr>

                            <td>Door Hinge Cost</td>

                            <td colSpan="3">$ { parseFloat(data.TotalHingeCost).toFixed(2) }</td>
                        </tr>

                        <tr>

                            <td>Hardware Cost</td>

                            <td colSpan="3">$ { parseFloat(data.HardwareCost).toFixed(2) }</td>
                        </tr>


                        <tr>

                            <td>Drawer Cost</td>

                            <td colSpan="3">$ { parseFloat(data.ShelvesCost).toFixed(2) }</td>

                        </tr>

                        <tr>

                            <td>Carcase Cost</td>

                            <td colSpan="3">$ { parseFloat(data.CarcaseFinalCost).toFixed(2) }</td>

                        </tr>

                    </>

                    : ''

                }

                { data.hasDoor === 1 ? 

                    <>

                        <tr>

                            <td>No Of Doors</td>

                            <td colSpan="3">{ data.NoOfDoors }</td>
                        </tr>


                        <tr>

                            <td>Door Style</td>

                            <td colSpan="3">{ doorData ? doorData.DoorName : '' }</td>
                        </tr>

                        <tr>

                            <td>Door Gap Top</td>

                            <td colSpan="3">{ doorData ? doorData.GapTop : '' }</td>

                        </tr>

                        <tr>

                            <td>Door Gap Bottom</td>

                            <td colSpan="3">{ doorData ? doorData.GapBottom : '' }</td>

                        </tr>

                        <tr>

                            <td>Door Gap Left</td>

                            <td colSpan="3">

                                { doorData ? doorData.GapLeft : '' }
                        
                            </td>

                        </tr>

                        <tr>

                            <td>Door Gap Right</td>

                            <td colSpan="3">{ doorData ? doorData.GapRight : '' }</td>

                        </tr>


                        <tr>

                            <td>Door Hardware</td>

                            <td colSpan="3">

                                { doorHardwareData ? doorHardwareData.HardwareName : '' }
                        
                            </td>

                        </tr>

                        <tr>

                            <td>Door Hinging</td>

                            <td colSpan="3">{ hingeData ? hingeData.HingeType : '' }</td>

                        </tr>


                    </>

                    : '' }


                    {

                        data.ProductType !== 'accessories' ?

                        <>

                            {
                                data.details !== '' ?

                                <>

                                    <tr>

                                        <td>Details</td>

                                        <td colSpan="3">{ data.details }</td>

                                    </tr>

                                </>

                                : ''
                            }

                            {

                                attached_str.length > 0 ? 

                                <>

                                    <tr>

                                        <td>Attached File</td>

                                        <td colSpan="3">{ attached_str.map( s => {
                                                return <>{s}<br /></>
                                            }) }</td>

                                    </tr>

                                </>

                                : ''
                            }

                        </>

                        : '' 

                    }

                    {

                        data.hasDrawer === 1 ?

                        <>

                            <tr>

                                <td>Drawer Hardware</td>

                                <td colSpan="3">{ data.DrawerhardwareData.HardwareName }</td>

                            </tr>

                            <tr>

                                <td>No of Fixed Shelves</td>

                                <td colSpan="3">{ data.FixedShelve }</td>

                            </tr>

                            <tr>

                                <td>No Of Adjustable Shelves</td>

                                <td colSpan="3">{ data.AdjustableShelve }</td>

                            </tr>

                            <tr>

                                <td>Drawer Equal</td>

                                <td colSpan="3">{ data.DrawerEqual }</td>
                            </tr>

                            <tr>

                                <td>Drawer Small</td>

                                <td colSpan="3">{ data.DrawerInner }</td>

                            </tr>

                            <tr>

                                <td>Drawer Inner</td>

                                <td colSpan="3">{ data.DrawerInner }</td>
                            </tr>

                            <tr>

                                <td>Drawer Gaps</td>

                                <td colSpan="3">{ data.drawerGaps }</td>

                            </tr>

                        </>

                        : ''

                    }
                

                {

                    data.hasShelve === 1 ?

                    <>
                        <tr>

                            <td>No of Fixed Shelve</td>

                            <td colSpan="3">{ data.fixedQty }</td>

                        </tr>

                        <tr>

                            <td>No of Adjustable Shelves</td>

                            <td colSpan="3">{ data.adjustableQty }</td>

                        </tr>
                    </>

                    : 

                    ""

                }


                {

                    data.ProductType === 'panels' ? 

                    <>

                        <tr>

                            <td>Short Edges</td>

                            <td colSpan="3">{ data.noShortEdges }</td>

                        </tr>

                        <tr>

                            <td>Long Edges</td>

                            <td colSpan="3">{ data.noLongEdges }</td>

                        </tr>

                    </>

                    : 

                    ""

                }

                
                { materialData }

                

            </>
        )

    }

}

class MaterialData extends Component {

    /* constructor(props) {

        super(props);

    } */

    render() {

        const material = this.props.material;

        return (

            <>

                <tr>

                    <td>{ material.Section } Type</td>

                    <td colSpan="3">{ material.MaterialType }</td>

                </tr>

                <tr>

                    <td>{ material.Section } Brand</td>

                    <td colSpan="3">{ material.BrandName }</td>

                </tr>

                <tr>

                    <td>{ material.Section } Finish</td>

                    <td colSpan="3">{ material.FinishName }</td>

                </tr>

                <tr>

                    <td>{ material.Section } Substrate</td>

                    <td colSpan="3">{ material.SubstrateName }</td>

                </tr>

                <tr>

                    <td>{ material.Section } Colours</td>

                    <td colSpan="3">{ material.ColourName }</td>

                </tr>

            </>

        )

    }

}


export default ViewOrder
