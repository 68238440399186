import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import swal from 'sweetalert';
import { strings } from '../components/strings';
import Modal from 'react-bootstrap-modal';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ReactTooltip from 'react-tooltip'

class Products extends Component {

    constructor(props) {
        super(props);

        var user = JSON.parse(localStorage.getItem('authuser'));

        this.state = {
            user: (user) ? user[0] : [],
            products: [],
            hinges: [],
            sort: [],
            open: false,
            columnWidth: [100, 200, 200, 200, 200, 200, 200, 200, 100]
        }

        this.getAllProducts = this.getAllProducts.bind(this);
        this.save = this.save.bind(this);
        this.doorFormatter = this.doorFormatter.bind(this);
        this.drawerFormatter = this.drawerFormatter.bind(this);

        this.delete = this.delete.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.submit = this.submit.bind(this);
        this.editFormatter = this.editFormatter.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.expandComponent = this.expandComponent.bind(this);
    }

    onSortChange(name, order) {
        const data = { sortField: name, sortOrder: order};
        localStorage.setItem("sortProduct", JSON.stringify(data));
    }

    submit(data) {

        const user = this.state.user;

        fetch(config.BASE_URL + '/api/admin/addproduct', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + user.token
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { products: data.products } )
        })
        .catch(error => console.log(error));
    }

    close() {
        this.setState( { open: false } );
    }

    open() {
        this.setState( { open: true } );
    }

    delete(product) {

        var authuser = this.state.user;

        const data = {id: product.ProductId}

        swal({
            title: `${strings.Areyousure}`,
            text: "Once deleted, you will not be able to recover this record",
            icon: "warning",
            buttons: true,
            dangerMode: true
          }).then(willDelete => {

            if (willDelete) {

                fetch(config.BASE_URL + '/api/admin/deleteproduct', {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': 'application/json',
                        "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
                    }
                })
                .then(data => data.json())
                .then(data => {
                  this.setState( {products: data.products} );
                })
                .catch(error => console.log(error));


            } else {
                return false;
            }

          });
      }



    doorFormatter(cell, row) {
        return row.hasDoor === 1 ? 'Yes' : 'No';
    }

    drawerFormatter(cell, row) {
        return row.hasDrawer === 1 ? 'Yes' : 'No';
    }

    save(rowData, cellName, cellValue){

		//console.log('columnData', rowData);

        const product = {
            ProductId: rowData.ProductId,
            ProductName: rowData.ProductName,
            Width: rowData.Width,
            Height: rowData.Height,
            Depth: rowData.Depth,
            ToeHeight: rowData.ToeHeight,
            DoorGapTop: rowData.DoorGapTop,
            DoorGapBottom: rowData.DoorGapBottom,
            DoorGapLeft : rowData.DoorGapLeft,
            DoorGapRight : rowData.DoorGapRight,
            hasDrawer: rowData.hasDrawer,
            hasShelve: rowData.hasShelve,
            DoorId: rowData.DoorId,
            hasDoor: rowData.hasDoor,
            NoOfDoors: rowData.NoOfDoors,
            stockcode: rowData.stockcode,
            HingeId: rowData.HingeId,
            TotalHardwareCost: rowData.TotalHardwareCost,

            WidthMin : rowData.WidthMin,
            WidthMax : rowData.WidthMax,
            HeightMin : rowData.HeightMin,
            HeightMax : rowData.HeightMax,
            DepthMin : rowData.DepthMin,
            DepthMax : rowData.DepthMax,
            ToeHeightMin : rowData.ToeHeightMin,
            ToeHeightMax : rowData.ToeHeightMax,

            max_inner_drawer: rowData.max_inner_drawer,
            max_equal_drawer: rowData.max_equal_drawer,
            max_small_drawer: rowData.max_small_drawer,
            has_inner: rowData.has_inner,
            has_kicker: rowData.has_kicker,
            InteriorMaterial: rowData.InteriorMaterial,
            ExteriorMaterial: rowData.ExteriorMaterial,
            DefaultMaterials: rowData.DefaultMaterials,

            FixedShelve : rowData.FixedShelve,
            AdjustableShelve : rowData.AdjustableShelve
        }

        const user = this.state.user;

        fetch(config.BASE_URL + '/api/updateProduct', {
            method: 'POST',
            body: JSON.stringify(product),
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + user.token
            }
        })
        .then(data => data.json())
        .then(data => {
            //console.log(data)
        })
        .catch(error => console.log(error));

    }

    getAllProducts() {

        const user = this.state.user;

        fetch(config.BASE_URL + '/api/admin/products', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + user.token
            }
        })
        .then(data => data.json())
        .then(data => {

               const sort = localStorage.getItem("sortProduct");

               this.setState( {
                   products: data.products,
                   hinges: data.hinges,
                   doors: data.doors,
                   sort: JSON.parse(sort),
               }, () => {

				for (let index = 0; index < 10; index += 1) {

					if ($(`.react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

					$(`.react-bs-container-header thead th:eq(${index})`).resizable({
					  minWidth: 50,
					  handles: 'e',
					  resize: (__event, ui) => {
						const newColumnWidths = [...this.state.columnWidth];
						newColumnWidths[index] = ui.size.width;
						this.setState({ columnWidth: newColumnWidths });
					  },
					});
				}
		    } )
        })
        .catch(error => console.log(error));
    }

    editFormatter(cell,row) {
        return  <>
                <DefaultMaterialsButton save={this.save} authuser={this.state.AuthUser} data={row} />
                  <button className="link-btn" onClick={ () => this.delete(row) }><i className="fa fa-trash" aria-hidden="true" /></button>
                </>;
    }

    enumFormatter(cell, row, enumObject) {

        return enumObject[cell];
    }



    componentDidMount() {
        this.getAllProducts();
    }

    isExpandableRow(row) {
        return true
    }

    expandComponent(row) {

        //console.log('expanded', row);

        return (
            <>
                <BSTable data={ row } hinges={this.state.hinges} save={this.save} />
                <BSTable2 data={ row } save={this.save} />
                <BSTable3 data={ row } save={this.save} doors={this.state.doors} />
            </>
        );
    }



    render() {

        //console.log('admin product render');

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.save
        }

        const productOptions = [];
        const hinges = [];

        const sort = this.state.sort;

        this.state.products.map( product => {
            productOptions[product.ProductName] = product.ProductName;
            return product;
        });


        this.state.hinges.map( h => {
            hinges[h.HingeId] = h.HingeType
            return h;
        });

        //console.log('options', this.state.hinges, hinges);


        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
            onRowClick: this.onRowClick,
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 25,
            onSortChange: this.onSortChange,
            sortName: sort ? sort.sortField : '',
            sortOrder: sort ? sort.sortOrder : '',
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        this.state.products.length > 0
                            ? this.state.products.length
                            : 5
                }
            ]
        };


        const createNumberEditor = (onUpdate, props) => (<NumberEditor onUpdate={ onUpdate } {...props}/>);



        /*const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,  // click to select, default is false
            clickToExpand: true  // click to expand row, default is false
        };*/

        //console.log('column', this.state.columnWidth);


        return (
            <div className="admin-wrapper">
					<HeaderAdmin />
					<div className="dashboard-wrapper partscontent">
						<div className="dashboardhome">
							<div className="container-fluid dashboard-content jobspagecnt">
								{/* ============================================================== */}
								{/* pagecontent  */}
								{/* ============================================================== */}
								<div className="row">
								<div className="col-md-12">
									<ol className="breadcrumb breadm">
										<li><a href="/admin">Admin</a></li>
										<li ><i className="fa fa-angle-right midarrow" /><a href="/admin/products" style={{ cursor: "pointer" }}>Products</a></li>
									</ol>
								</div>
								</div>
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										{/* top heading section*/}
										<div className="inner-pagehead">
											<h1>PRODUCTS</h1>
										</div>
										  <div className="products-table">

                                            <BootstrapTable
                                                ref='table'
                                                data={this.state.products}
                                                pagination={true}
                                                options={options}
                                                search={true}
                                                hover={true}
                                                cellEdit={cellEditProp}
                                                expandableRow={ this.isExpandableRow }
                                                expandComponent={ this.expandComponent }
                                                expandColumnOptions={ { expandColumnVisible: true } }
                                                trClassName={this.trClassFormat}>
                                                <TableHeaderColumn
                                                    hidden={true}
                                                    dataField='ProductId'
                                                    dataSort={true}
                                                    isKey={true}>
                                                        Product ID
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='ProductName'
                                                    dataSort={true}
                                                    width={this.state.columnWidth[2] + 'px'}
                                                    filterFormatted
                                                    formatExtraData={ productOptions }
                                                    filter={ { type: 'SelectFilter', options: productOptions,  delay: 1000 } }
                                                    >Product Name</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='Width'
                                                    dataSort={true}
                                                    customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                                                    width={this.state.columnWidth[3] + 'px'}
                                                    >Width
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='Height'
                                                    dataSort={true}
                                                    customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                                                    width={this.state.columnWidth[4] + 'px'}
                                                    >Height</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='Depth'
                                                    dataSort={true}
                                                    width={this.state.columnWidth[5] + 'px'}
                                                    customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }

                                                    >Depth</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='ToeHeight'
                                                    dataSort={true}
                                                    width={this.state.columnWidth[6] + 'px'}
                                                    customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }

                                                    >Kicker Height</TableHeaderColumn>
                                                {/**/}
                                                <TableHeaderColumn
                                                    dataField='TotalHardwareCost'
                                                    dataSort={true}
                                                    hidden={true}
                                                    width={this.state.columnWidth[7] + 'px'}
                                                    customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                                                    >Hardware Cost</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    tdStyle={{ cursor: "pointer", textAlign:"center"}}
                                                    thStyle={{ textAlign:"center" }}
                                                    dataFormat={ this.editFormatter.bind(this) }
                                                    width={this.state.columnWidth[8] + 'px'}
                                                    editable={false}>
                                                    <button onClick={this.open} className="btn add-btn purchasebtn"><i className="fa fa-plus" /></button>
                                                </TableHeaderColumn>
                                            </BootstrapTable>

                                        </div>




									</div>
								</div>
								{/* ============================================================== */}
								{/* end pagecontent  */}
								{/* ============================================================== */}
							</div>
						</div>
						<FooterAdmin />
                        <AddModal open={this.state.open} hinges={this.state.hinges} close={this.close} submit={this.submit} />
					</div>
				</div>
        )
    }

}

class BSTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hinges : props.hinges
        }

        this.hingeFormatter = this.hingeFormatter.bind(this);
        this.shelveFormatter = this.shelveFormatter.bind(this);
    }

    hingeFormatter(cell, row) {
        let hinge = '';

        this.state.hinges.map(h => {
            if (h.HingeId === row.HingeId) {
                hinge = h.HingeType;
            }
            return h;
        });

        return hinge;
    }

    drawerFormatter(cell, row) {
        return row.hasDrawer === 1 ? 'Yes' : 'No';
    }

    doorFormatter(cell, row) {
        //console.log('door', row);
        return `${row.hasDoor === 1 ? 'Yes' : 'No'}`;

    }

    shelveFormatter(cell, row) {
        //console.log('door', row);
        return `${row.hasShelve === 1 ? 'Yes' : 'No'}`;

    }

    render() {

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.props.save
        }

        const createNumberEditor = (onUpdate, props) => (<NumberEditor onUpdate={ onUpdate } {...props}/>);
        const createSelectEditor = (onUpdate, props) => (<SelectEditor onUpdate={ onUpdate } {...props}/>);

        const data = [];

        data.push(this.props.data)

        //console.log('expand', data);

      if (this.props.data) {
        return (
          <BootstrapTable
            cellEdit={cellEditProp}
            data={ data }>
            <TableHeaderColumn
                dataField='ProductId'
                hidden={true}
                isKey={true}></TableHeaderColumn>
            <TableHeaderColumn
                dataField='DoorGapTop'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: .01 }  } }
                >Door Gap Top</TableHeaderColumn>
            <TableHeaderColumn
                dataField='DoorGapBottom'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: .01 }  } }
                >Door Gap Bottom</TableHeaderColumn>

            <TableHeaderColumn
                dataField='DoorGapLeft'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: .01 }  } }
                >Door Gap Left</TableHeaderColumn>
            <TableHeaderColumn
                dataField='DoorGapRight'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: .01 }  } }
                >Door Gap Right</TableHeaderColumn>
            <TableHeaderColumn
                dataField='hasDoor'
                dataFormat={ this.doorFormatter }
                editable={ { type: 'checkbox', options: { values: '1:0' } } }
                >
                Has Door
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='hasDrawer'
                dataFormat={ this.drawerFormatter }
                editable={ { type: 'checkbox', options: { values: '1:0' } } }
                >
                Has Drawer
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='hasShelve'
                dataFormat={ this.shelveFormatter }
                editable={ { type: 'checkbox', options: { values: '1:0' } } }
                >
                Has Shelf
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='NoOfDoors'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >No Of Doors</TableHeaderColumn>
            <TableHeaderColumn
                dataField='HingeId'
                hidden={true}
                dataFormat={ this.hingeFormatter }
                customEditor={ { getElement : createSelectEditor, customEditorParameters: { options: this.state.hinges }  } }
            >Hinge</TableHeaderColumn>
          </BootstrapTable>);
      } else {
        return (<p>?</p>);
      }
    }
  }

  class BSTable2 extends React.Component {

    /* constructor(props) {
        super(props);

    }  */


    render() {

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.props.save
        }

        const createNumberEditor = (onUpdate, props) => (<NumberEditor onUpdate={ onUpdate } {...props}/>);
        //const createSelectEditor = (onUpdate, props) => (<SelectEditor onUpdate={ onUpdate } {...props}/>);

        const data = [];

        data.push(this.props.data)

        //console.log('expand', data);

      if (this.props.data) {
        return (
          <BootstrapTable
            cellEdit={cellEditProp}
            data={ data }>
            <TableHeaderColumn
                dataField='ProductId'
                hidden={true}
                isKey={true}></TableHeaderColumn>
            <TableHeaderColumn
                dataField='WidthMin'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Min Width</TableHeaderColumn>
            <TableHeaderColumn
                dataField='WidthMax'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Max Width</TableHeaderColumn>

            <TableHeaderColumn
                dataField='HeightMin'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Min Height</TableHeaderColumn>

            <TableHeaderColumn
                dataField='HeightMax'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Max Height</TableHeaderColumn>

            <TableHeaderColumn
                dataField='DepthMin'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Min Depth</TableHeaderColumn>

            <TableHeaderColumn
                dataField='DepthMax'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Max Depth</TableHeaderColumn>

            <TableHeaderColumn
                dataField='ToeHeightMin'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Min Kicker Height</TableHeaderColumn>

            <TableHeaderColumn
                dataField='ToeHeightMax'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Max Toe Height</TableHeaderColumn>

          </BootstrapTable>);
      } else {
        return (<p>?</p>);
      }
    }
  }

class BSTable3 extends React.Component {

    constructor(props) {
        super(props);

        this.innerFormatter = this.innerFormatter.bind(this);
        this.kickerFormatter = this.kickerFormatter.bind(this);
        this.interiorMaterial = this.interiorMaterial.bind(this);
        this.exteriorMaterial = this.exteriorMaterial.bind(this);
        this.doorStyle = this.doorStyle.bind(this);
    }

    doorStyle(cell, row) {

        //console.log('cell', cell);
        //console.log('row', row);
        //console.log('props', this.props.doors)

        const door = this.props.doors.filter( d => { 
        
            //console.log('d', d.DoorId, row.DoorId);    
            return d.DoorId === parseInt(row.DoorId) 

        });

        //console.log('door', door);

        return door.length > 0 ? door[0].DoorName : '';

    }

    exteriorMaterial(cell, row) {
        return `${row.ExteriorMaterial === 1 ? 'Yes' : 'No'}`;
    }

    interiorMaterial(cell, row) {
        return `${row.InteriorMaterial === 1 ? 'Yes' : 'No'}`;
    }

    innerFormatter(cell, row) {
        return `${row.has_inner === 1 ? 'Yes' : 'No'}`;
    }

    kickerFormatter(cell, row) {
        return `${row.has_kicker === 1 ? 'Yes' : 'No'}`;

    }


    render() {

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.props.save
        }

        const createNumberEditor = (onUpdate, props) => (<NumberEditor onUpdate={ onUpdate } {...props}/>);
        //const createSelectEditor = (onUpdate, props) => (<SelectEditor onUpdate={ onUpdate } {...props}/>);

        const data = [];

        const doors = this.props.doors.map( d => {
               return {
                   value: d.DoorId,
                   text: d.DoorName
               }
        });

       // console.log('doors', doors);

        data.push(this.props.data)

        //console.log('expand', data);

      if (this.props.data) {
        return (
          <BootstrapTable
            cellEdit={cellEditProp}
            data={ data }>
            <TableHeaderColumn
                dataField='ProductId'
                hidden={true}
                isKey={true}></TableHeaderColumn>
            <TableHeaderColumn
                dataField='max_inner_drawer'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Max Inner Drawer</TableHeaderColumn>
            <TableHeaderColumn
                dataField='max_equal_drawer'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Max Equal Drawer</TableHeaderColumn>

            <TableHeaderColumn
                dataField='max_small_drawer'
                customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                >Max Small Drawer</TableHeaderColumn>

            <TableHeaderColumn
                dataField='has_inner'
                dataFormat={ this.innerFormatter }
                editable={ { type: 'checkbox', options: { values: '1:0' } } }
                >Has Inner Drawer</TableHeaderColumn>

            <TableHeaderColumn
                dataField='has_kicker'
                dataFormat={ this.kickerFormatter }
                editable={ { type: 'checkbox', options: { values: '1:0' } } }
                >Has Kicker</TableHeaderColumn>

            <TableHeaderColumn
                dataField='InteriorMaterial'
                dataFormat={ this.interiorMaterial }
                editable={ { type: 'checkbox', options: { values: '1:0' } } }
                >Interior Section</TableHeaderColumn>

            <TableHeaderColumn
                dataField='ExteriorMaterial'
                dataFormat={ this.exteriorMaterial }
                editable={ { type: 'checkbox', options: { values: '1:0' } } }
                >Exterior Material</TableHeaderColumn>

            <TableHeaderColumn
                dataField='DoorId'
                dataFormat={ this.doorStyle }
                editable={ { type: 'select', options: { values: doors } } }
                >Door Style</TableHeaderColumn>

          </BootstrapTable>);
      } else {
        return (<p>?</p>);
      }
    }
}

class NumberEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value : props.defaultValue,
            step : props.step
        }

        this.handleChange = this.handleChange.bind(this);
    }

    getValue() {
      return this.state.value;
    }

    handleChange(e) {
        this.setState( {
            value: e.target.value
        })
    }

    render() {
      const { value, onUpdate, ...rest } = this.props;
      return [
        <input
          { ...rest }
          type="number"
          className="form-control numberEditor"
          step={this.state.step}
          onChange={this.handleChange}

        />
      ];
    }
  }

  class SelectEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value   : props.defaultValue,
            options : props.options
        }

        this.handleChange = this.handleChange.bind(this);
    }

    getValue() {
      return this.state.value;
    }

    handleChange(e) {
        this.setState( {
            value: e.target.value
        })
    }

    render() {

        const { value, onUpdate, ...rest } = this.props;

        const options = this.state.options.map( o => {

            return <option value={o.HingeId} selected={o.HingeId === value}>{o.HingeType}</option>
        });



      return (
        <select
          { ...rest }
          type="number"
          className="form-control numberEditor"
          onChange={this.handleChange}

        >
            {options}
        </select>
      );
    }
  }


  class AddModal extends Component {

    constructor(props) {
      super(props);

      this.state = {
        ProductName: '',
        Width: 500,
        Height: 800,
        Depth: 600,
        ToeHeight: 0,
        doorGapTop: 0,
        doorGapBottom: 0,
        doorGapLeft: 0,
        doorGapRight: 0,
        hasDrawer: 1,
        hasDoor: 1,
        NoOfDoors: 1,
        HingeId: 1,
        TotalHardwareCost: 200,
        errorCodeClass: 'form-control',
        errorClass: 'hidden',
        pnameClass: 'form-control'
      }

      this.submit = this.submit.bind(this);
      this.handleChange = this.handleChange.bind(this);

    }

    handleChange(e) {

      this.setState({ [e.target.name] : e.target.value, errorCodeClass: 'form-control', errorClass: 'hidden', pnameClass: 'form-control'} )
    }

    submit() {

      let valid = true;

      const pname = this.state.ProductName;

      if (pname.length === 0) {
          valid = false;
          this.setState( {pnameClass: 'form-control error'} );
      }

      if (valid) {
        this.props.submit(this.state);
        this.props.close();
      }
    }

    render() {

       /* const hinges = this.props.hinges.map( hinge => {
        return <option value={hinge.HingeId}>{hinge.HingeType}</option>
       }); */

      return (
        <Modal
            show={this.props.open}
            onHide={this.props.close}
            className="LoginModal AddProduct"
          >

         <Modal.Header closeButton>
           Add Product
         </Modal.Header>

          <Modal.Body>

            <div className="form-group">
                <label>Product Name</label>
                <input type="text" className={this.state.pnameClass} name="ProductName" value={this.state.ProductName} onChange={this.handleChange} />
                <span className="small text-warning message">Product name is required</span>
            </div>

            <div className="row">

                <div className="col-md-6">

                    <div className="form-group">
                        <label>Width</label>
                        <input type="number" className="form-control" name="Width" value={this.state.Width} onChange={this.handleChange} />
                    </div>

                </div>

                <div className="col-md-6">

                    <div className="form-group">
                        <label>Height</label>
                        <input type="number" className="form-control" name="Height" value={this.state.Height} onChange={this.handleChange} />
                    </div>

                </div>

            </div>

            <div className="row">

                <div className="col-md-6">

                    <div className="form-group">
                        <label>Depth</label>
                        <input type="number" className="form-control" name="Depth" value={this.state.Depth} onChange={this.handleChange} />
                    </div>

                </div>

                <div className="col-md-6">

                    <div className="form-group">
                        <label>Kicker Height</label>
                        <input type="number" className="form-control" name="ToeHeight" value={this.state.ToeHeight} onChange={this.handleChange} />
                    </div>

                </div>

            </div>

            <div className="row">

                <div className="col-md-6">

                    <div className="form-group">
                        <label>Door Gap Top</label>
                        <input type="number" step=".01" className="form-control" name="doorGapTop" value={this.state.doorGapTop} onChange={this.handleChange} />
                    </div>

                </div>

                <div className="col-md-6">

                    <div className="form-group">
                        <label>Door Gap Bottom</label>
                        <input type="number" step=".01" className="form-control" name="doorGapBottom" value={this.state.doorGapBottom} onChange={this.handleChange} />
                    </div>

                </div>

            </div>

            <div className="row">

                <div className="col-md-6">

                    <div className="form-group">
                        <label>Door Gap Left</label>
                        <input type="number" step=".01" className="form-control" name="doorGapLeft" value={this.state.doorGapLeft} onChange={this.handleChange} />
                    </div>

                </div>

                <div className="col-md-6">

                    <div className="form-group">
                        <label>Door Gap Right</label>
                        <input type="number" step=".01" className="form-control" name="doorGapRight" value={this.state.doorGapRight} onChange={this.handleChange} />
                    </div>

                </div>

            </div>

            <div className="row">

                <div className="col-md-6">

                    <div className="form-group select">
                        <label>Has Drawer</label>
                        <select name="hasDrawer" className="form-control" onChange={this.handleChange}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>

                </div>

                <div className="col-md-6">

                    <div className="form-group select">
                        <label>Has Door</label>
                        <select name="hasDoor" className="form-control" onChange={this.handleChange}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>

                </div>

            </div>

            <div className="row">

                <div className="col-md-12">

                    <div className="form-group">
                        <label>No Of Doors</label>
                        <input type="number" className="form-control" value={this.state.NoOfDoors} name="NoOfDoors" onChange={this.handleChange} />
                    </div>

                </div>
                {/*
                <div className="col-md-6">

                    <div className="form-group select">
                        <label>Hinge</label>
                        <select name="HingeId" className="form-control" value={this.state.HingeId} onChange={this.handleChange}>
                            {hinges}
                        </select>
                    </div>

                </div>
                */}
            </div>
            {/*
            <div className="form-group">
                <label>Hardware Cost</label>
                <input type="number" className="form-control" value={this.state.TotalHardwareCost} name="TotalHardwareCost" onChange={this.handleChange} />
            </div>
            */}

            <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
          </Modal.Body>

        </Modal>

      )

    }
  }

  class DefaultMaterialsButton extends Component {

    constructor(props) {
      super(props);

      this.state = {
        open: false
      }

      this.open = this.open.bind(this);
      this.close = this.close.bind(this);
    }

    close() {
      this.setState( {open:false} )
    }

    open() {
      this.setState( {open:true} );
    }



    render() {
      
      /*  var Defaults = [] */
      
      const {DefaultMaterials} = this.props.data

      const hasDefaults = DefaultMaterials && DefaultMaterials.materialType ? null : {color: '#333'}
      return (
        <>
          <button style={hasDefaults} onClick={this.open} className="link-btn" data-tip="Default Materials"><i className="fa fa-server"></i></button>
          <DefaultMaterialsModal save={this.props.save} data={this.props.data} open={this.state.open} close={this.close} />
          <ReactTooltip place="top" html={false} />
        </>
      )
    }
  }

  class DefaultMaterialsModal extends Component {

    constructor(props) {
      super(props);
      this.state = {
        DefaultMaterials: [
          {
            MaterialTypeId: '',
            BrandId: '',
            FinishId: '',
            SubstrateId: '',
            ColourId: ''
          },
          {
            MaterialTypeId: '',
            BrandId: '',
            FinishId: '',
            SubstrateId: '',
            ColourId: ''
          }
        ],
        FixedShelve: 0,
        AdjustableShelve: 0,
        brand: [],
        colour: [],
        doorstyle: [],
        finish: [],
        material: [],
        substrate: []
      }

      this.onSave = this.onSave.bind(this);
      this.changeHandler = this.changeHandler.bind(this);
      this.getMaterials = this.getMaterials.bind(this);
      this.handleShelve = this.handleShelve.bind(this);
    }

    componentDidUpdate(prevProps) {
      //console.log('this.props.open', this.props.open);
      //console.log('this.props.data.DefaultMaterials', this.props.data.DefaultMaterials)
      if (this.props.open !== prevProps.open && this.props.open) {
        const {DefaultMaterials, FixedShelve, AdjustableShelve} = this.props.data
        if (Array.isArray(DefaultMaterials)) {
          this.setState({
            DefaultMaterials: DefaultMaterials,
            FixedShelve: FixedShelve,
            AdjustableShelve: AdjustableShelve
          }, () => {
            console.log('this.state.DefaultMaterials', this.state.DefaultMaterials);
          })
        }
        this.getMaterials();
      }
    }

    getMaterials(){
      fetch(config.BASE_URL + '/api/getmaterials', {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
          }
        })
        .then(data => data.json())
        .then(data => {
          this.setState(data);
        })
        .catch(error => console.log(error));
    }

    onSave() {
      const {DefaultMaterials, FixedShelve, AdjustableShelve} = this.state;
      const data = this.props.data;
      data.DefaultMaterials = DefaultMaterials;
      data.FixedShelve = FixedShelve;
      data.AdjustableShelve = AdjustableShelve;

      this.props.save(data);
      this.props.close();
    }

    changeHandler(i, event){
      const {DefaultMaterials} = this.state
      DefaultMaterials[i][event.target.name] =  event.target.value

      if (event.target.name === 'MaterialTypeId') {
        DefaultMaterials[i]['BrandId'] =  ''
        DefaultMaterials[i]['FinishId'] =  ''
        DefaultMaterials[i]['SubstrateId'] =  ''
        DefaultMaterials[i]['ColourId'] =  ''
      }

      if (event.target.name === 'BrandId') {
        DefaultMaterials[i]['FinishId'] =  ''
        DefaultMaterials[i]['SubstrateId'] =  ''
        DefaultMaterials[i]['ColourId'] =  ''
      }

      if (event.target.name === 'FinishId') {
        DefaultMaterials[i]['ColourId'] =  ''
      }

      if (event.target.name === 'SubstrateId') {
        DefaultMaterials[i]['ColourId'] =  ''
      }

      this.setState( {
        DefaultMaterials: DefaultMaterials
      }, () => {
        console.log('DefaultMaterials', DefaultMaterials);
      })
    }


    handleShelve(e) {
        this.setState( {
            [e.target.name] : e.target.value
        });
    }

    render() {
      const {DefaultMaterials, FixedShelve, AdjustableShelve} = this.state
      const {material, brand, finish, substrate, colour} = this.state
      const default0 = DefaultMaterials[0]? DefaultMaterials[0]  : [];
      const default1 = DefaultMaterials[1]? DefaultMaterials[1]  : [];

      //console.log('DefaultMaterials', DefaultMaterials);

      const brandOptions0 = brand.filter((brand) => {
          //return default0.MaterialTypeId && parseInt(default0.MaterialTypeId) > 0  ? brand.MaterialTypeId == default0.MaterialTypeId :  brand
          return brand.MaterialTypeId === parseInt(default0.MaterialTypeId)
      });

      const finishOptions0 = finish.filter((finish) => {
          //return default0.BrandId && parseInt(default0.BrandId) > 0  ? finish.BrandId == default0.BrandId :  finish
          return finish.BrandId === parseInt(default0.BrandId)
      });

      const substrateOptions0 = substrate.filter((substrate) => {
          return substrate.BrandId === parseInt(default0.BrandId)
      });

      const colourOptions0 = colour.filter((colour) => {
          return colour.BrandId === parseInt(default0.BrandId) && colour.FinishId === parseInt(default0.FinishId) && colour.SubstrateId === parseInt(default0.SubstrateId)
          //return colour.ItemCode == '8417'
      });

      const brandOptions1 = brand.filter((brand) => {
          return brand.MaterialTypeId === parseInt(default1.MaterialTypeId)
      });

      const finishOptions1 = finish.filter((finish) => {
          return finish.BrandId === parseInt(default1.BrandId)
      });

      const substrateOptions1 = substrate.filter((substrate) => {
          return substrate.BrandId === parseInt(default1.BrandId)
      });

      const colourOptions1 = colour.filter((colour) => {
          return colour.BrandId === parseInt(default1.BrandId) && colour.FinishId === parseInt(default1.FinishId) && colour.SubstrateId === parseInt(default1.SubstrateId)
      });

      //console.log('brand', brand);

      return (
        <>
          <Modal
            show={this.props.open}
            onHide={this.props.close}
            className="LoginModal AddProduct admin-default-materials">
            <Modal.Header closeButton>
              Default Materials
            </Modal.Header>
            <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <h4>INTERIOR</h4>
                <div className="form-group select">
                    <label>Material Type</label>
                    <select className="form-control" 
                            name="MaterialTypeId" 
                            value={default0.MaterialTypeId}
                            onChange={this.changeHandler.bind(null, 0)}>
                        <option  value=""></option>
                        {
                            material && material.length > 0 ? material.map((material, key) => (
                                <option 
                                    key={key} 
                                    value={material.MaterialTypeId} 
                                    selected={material.MaterialTypeId === (default0.MaterialTypeId ? default0.MaterialTypeId : 0)} >
                                        {material.MaterialType}
                                </option>

                            )) : ""
                        }
                    </select>
                </div>

                <div className="form-group select">
                    <label>Brand</label>
                    <select className="form-control" 
                            value={default0.BrandId}
                            name="BrandId" 
                            onChange={this.changeHandler.bind(null, 0)}>
                        <option value=""></option>
                        {
                            brandOptions0 && brandOptions0.length > 0 ? brandOptions0.map((brand, key) => (
                                <option key={key} value={brand.BrandId} selected={brand.BrandId === (default0.BrandId ? default0.BrandId : 0)} >{brand.BrandName}</option>

                            )) : ""
                        }
                    </select>
                </div>

                <div className="form-group select">
                    <label>Finish</label>
                    <select className="form-control" 
                            value={default0.FinishId}
                            name="FinishId" 
                            onChange={this.changeHandler.bind(null, 0)}>
                        <option  value=""></option>
                        {
                            finishOptions0 && finishOptions0.length > 0 ? finishOptions0.map((finish, key) => (
                                <option key={key} value={finish.FinishId} selected={finish.FinishId === (default0.FinishId ? default0.FinishId : 0)} >{finish.FinishName}</option>

                            )) : ""
                        }
                    </select>
                </div>

                <div className="form-group select">
                    <label>Substrate</label>
                    <select className="form-control" 
                            name="SubstrateId" 
                            value={default0.SubstrateId}
                            onChange={this.changeHandler.bind(null, 0)}>
                        <option  value=""></option>
                        {
                            substrateOptions0 && substrateOptions0.length > 0 ? substrateOptions0.map((substrate, key) => (
                                <option key={key} value={substrate.SubstrateId} selected={substrate.SubstrateId === (default0.SubstrateId ? default0.SubstrateId : 0)} >{substrate.SubstrateName}</option>

                            )) : ""
                        }
                    </select>
                </div>

                <div className="form-group select">
                    <label>Colour</label>
                    <select className="form-control" 
                            name="ColourId" 
                            value={default0.ColourId}
                            onChange={this.changeHandler.bind(null, 0)}>
                        <option  value=""></option>
                        {
                            colourOptions0 && colourOptions0.length > 0 ? colourOptions0.map((colour, key) => (
                                <option key={key} value={colour.ColourId} selected={colour.ColourId === (default0.ColourId ? default0.ColourId : 0)} >{colour.ColourName}</option>

                            )) : ""
                        }
                    </select>
                </div>

              </div>
              <div className="col-md-6">
                <h4>EXTERIOR</h4>

                <div className="form-group select">
                    <label>Material Type</label>
                    <select className="form-control" 
                            value={default1.MaterialTypeId}
                            name="MaterialTypeId" 
                            onChange={this.changeHandler.bind(null, 1)}>
                        <option  value=""></option>
                        {
                            material && material.length > 0 ? material.map((material, key) => (
                                <option key={key} value={material.MaterialTypeId} selected={material.MaterialTypeId === (default1.MaterialTypeId ? default1.MaterialTypeId : 0)} >{material.MaterialType}</option>

                            )) : ""
                        }
                    </select>
                </div>

                <div className="form-group select">
                    <label>Brand</label>
                    <select className="form-control" 
                            name="BrandId" 
                            value={default1.BrandId}
                            onChange={this.changeHandler.bind(null, 1)}>
                        <option  value=""></option>
                        {
                            brandOptions1 && brandOptions1.length > 0 ? brandOptions1.map((brand, key) => (
                                <option key={key} value={brand.BrandId} selected={brand.BrandId === (default1.BrandId ? default1.BrandId : 0)} >{brand.BrandName}</option>

                            )) : ""
                        }
                    </select>
                </div>

                <div className="form-group select">
                    <label>Finish</label>
                    <select className="form-control" 
                            name="FinishId" 
                            value={default1.FinishId}
                            onChange={this.changeHandler.bind(null, 1)}>
                    <option  value=""></option>
                        {
                            finishOptions1 && finishOptions1.length > 0 ? finishOptions1.map((finish, key) => (
                                <option key={key} value={finish.FinishId} selected={finish.FinishId === (default1.FinishId ? default1.FinishId : 0)} >{finish.FinishName}</option>

                            )) : ""
                        }
                    </select>
                </div>

                <div className="form-group select">
                    <label>Substrate</label>
                    <select className="form-control" 
                            value={default1.SubstrateId}
                            name="SubstrateId" 
                            onChange={this.changeHandler.bind(null, 1)}>
                        <option  value=""></option>
                        {
                            substrateOptions1 && substrateOptions1.length > 0 ? substrateOptions1.map((substrate, key) => (
                                <option key={key} value={substrate.SubstrateId} selected={substrate.SubstrateId === (default1.SubstrateId ? default1.SubstrateId : 0)} >{substrate.SubstrateName}</option>

                            )) : ""
                        }
                    </select>
                </div>

                <div className="form-group select">
                    <label>Colour</label>
                    <select className="form-control" 
                            name="ColourId" 
                            value={default1.ColourId}
                            onChange={this.changeHandler.bind(null, 1)}>
                    <option  value=""></option>
                        {
                            colourOptions1 && colourOptions1.length > 0 ? colourOptions1.map((colour, key) => (
                                <option key={key} value={colour.ColourId} selected={colour.ColourId === (default1.ColourId ? default1.ColourId : 0)} >{colour.ColourName}</option>

                            )) : ""
                        }
                    </select>
                </div>

              </div>
            </div>

            <br />

            <div className="row">

                    <div className="col-md-12"><h4>SHELVES</h4></div>

            </div>

            <div className="row">

                <div className="col-md-6">

                    <div className="form-group">

                        <label>Fixed Shelve</label>

                        <input type="number" name="FixedShelve" className="form-control" value={FixedShelve} onChange={this.handleShelve} />

                    </div>

                </div>

                <div className="col-md-6">

                    <div className="form-group">

                        <label>Adustable Shelve</label>

                        <input type="number" name="AdjustableShelve" className="form-control" value={AdjustableShelve} onChange={this.handleShelve} />

                    </div>

                </div>

            </div>

            </Modal.Body>
            <Modal.Footer>
            <button className="btn cartbtn purchasebtn" onClick={this.onSave}> Save Changes </button>
            </Modal.Footer>
          </Modal>
        </>
      )
    }
  }


export default Products;
