import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import Modal from 'react-bootstrap-modal';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import swal from 'sweetalert';
import { strings } from '../components/strings';

class SlangTranslation extends Component {

	constructor(props) {
		super(props);
		var authuser = JSON.parse(localStorage.getItem('authuser'));
		this.state = {
			AuthUser: (authuser) ? authuser[0] : [],
			data: [],
			menus: [],
			columnWidth: [0, 15, 50, 15, 15, 20],
		}

		this.getList = this.getList.bind(this)
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.submit = this.submit.bind(this);

		this.onSortChange = this.onSortChange.bind(this);
		this.getMenus = this.getMenus.bind(this);
		this.addException = this.addException.bind(this);
		this.saveException = this.saveException.bind(this);
		this.deleteException = this.deleteException.bind(this);
	}

	getList() {
		fetch(config.BASE_URL + '/api/gettranslations', {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			  }
			})
			.then(data => data.json())
			.then(data => {
				//console.log(data.items)
				this.setState({data: data.items}, () => {
					for (let index = 0; index < 15; index += 1) {
					  if ($(`.react-bootstrap-table thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

					  $(`.react-bootstrap-table thead th:eq(${index})`).resizable({
						minWidth: 50,
						handles: 'e',
						resize: (__event, ui) => {
						const newColumnWidths = [...this.state.columnWidth];
						const container = $('.react-bootstrap-table').width();
						newColumnWidths[index] = ui.size.width / container * 100;
						this.setState({ columnWidth: newColumnWidths });
						},
					  });

					}
				  });
			})
			.catch(error => console.log(error));
	}

    componentDidMount() {
      this.getList();
			this.getMenus();
    }

		getMenus = async() => {
			await fetch(config.BASE_URL + '/api/getmenu', {
				headers: {
					"Content-Type": "application/json",
					'Accept': 'application/json'
				}
			})
			.then(data => data.json())
			.then(data => {
				// exctract secodn level menu
				this.setState({
					menus: data.menuItem,
				}, () => {
					//this.menuItemsUpdate = data.menuItem
					//console.log('this.state.menus', this.state);
				})
			})
			.catch(error => console.log(error));
		}

	open() {
		this.setState({open:true});
	}

	close() {
		this.setState({open:false});
	}

	submit(data) {
		this.close();
		this.addException(data)
	}

	deleteException(teId){
		swal({
        title: `${strings.Areyousure}`,
        text: "Once deleted, you will not be able to recover this record",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {

					var authuser = this.state.AuthUser;
					var data = {
						teId: teId,
						path: config.UPLOAD_PATH
					};

					fetch(config.BASE_URL + '/api/deleteexception', {
						method: "POST",
						body: JSON.stringify(data),
						headers: {
							"Content-Type": "application/json",
							'Accept': 'application/json',
							"Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
						}
					})
					.then(data => data.json())
					.then(data => {
						this.setState({data: data.items});
					})
					.catch(error => console.log(error));

        } else {
            return false;
        }
      });
	}

	addTranslation(data) {
		var authuser = this.state.AuthUser;
		data.path = config.UPLOAD_PATH;
		fetch(config.BASE_URL + '/api/addtranslation', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
		})
		.then(data => data.json())
		.then(data => {
			this.setState({data: data.items});
		})
		.catch(error => console.log(error));
	}

	addException(data) {
		var authuser = this.state.AuthUser;
		data.path = config.UPLOAD_PATH;
		fetch(config.BASE_URL + '/api/addexception', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
		})
		.then(data => data.json())
		.then(data => {
			this.setState({data: data.items});
		})
		.catch(error => console.log(error));
	}

	useSlangFormatter = (cell, row) =>{
		return row.transActive === 1 ? 'Yes' : 'No'
	}

	escapeFormatter = (cell, row) =>{
		return cell.replace(new RegExp("\\\\", "g"), "");
	}

	saveTranslation(data) {
		//console.log('data', data)
		var authuser = this.state.AuthUser;

		data.path = config.UPLOAD_PATH;

		fetch(config.BASE_URL + '/api/updatetranslation', {
		  method: "POST",
		  body: JSON.stringify(data),
		  headers: {
			  "Content-Type": "application/json",
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
		  })
		  .then(data => data.json())
		  .then(data => {

		  })
		  .catch(error => console.log(error));
	}

	saveException(data) {
		//console.log('data', data)
		var authuser = this.state.AuthUser;

		data.path = config.UPLOAD_PATH;

		fetch(config.BASE_URL + '/api/updateexception', {
		  method: "POST",
		  body: JSON.stringify(data),
		  headers: {
			  "Content-Type": "application/json",
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
		  })
		  .then(data => data.json())
		  .then(data => {

		  })
		  .catch(error => console.log(error));
	}

	formatHeaderLocation = (data, row) =>{
		return (<><button onClick={this.open} className="btn purchasebtn"><i className="fas fa-list"></i></button></>)
	}

	editFormatter = (data, row) =>{
		return <SlangTranslationButton delete={this.deleteException} update={this.saveException} submit={this.submit} menus={this.state.menus} data={row} open={this.open} close={this.close}  />
	}

	onSortChange(field, order) {
		console.log(field, order)
		localStorage.setItem("SlangSort", JSON.stringify({
		sortName: field,
		sortOrder: order
		}));
	}

	render() {
		var SlangSort = localStorage.getItem('SlangSort');

		if (SlangSort) {
			SlangSort = JSON.parse(SlangSort)
		}

		const defaultSort = {
			dataField: (SlangSort) ? SlangSort.sortName : null,
			order: (SlangSort) ? SlangSort.sortOrder : null,
		}


		const columns = [{
			dataField: 'transField',
			text: 'Unique Field',
			sort: true,
			editable: false,
			headerStyle: {width: this.state.columnWidth[0] + '%', verticalAlign: 'middle'},
			style: {verticalAlign: 'middle'},
			  onSort: this.onSortChange
		}, {
			dataField: 'transEn',
			sort: true,
			text: 'Normal Text',
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			formatter: this.escapeFormatter,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[1] + '%'},
			onSort: this.onSortChange

		}, {
			dataField: 'transSlang',
			text: 'Australian slang',
			sort: true,
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			formatter: this.escapeFormatter,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[2] + '%'},
			onSort: this.onSortChange
		}, {
			dataField: 'transActive',
			text: 'Use Slang?',
			sort: true,
			formatter: this.useSlangFormatter,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[3] + '%'},
			style: {verticalAlign: 'middle'},
			editor: {
				type: Type.SELECT,
				options: [{
				  value: '1',
				  label: 'Yes'
				}, {
				  value: '0',
				  label: 'No'
				}]
			},
			onSort: this.onSortChange
		}, {
			dataField: 'transLocation',
			text: 'Target Form',
			editable: false,
			sort: true,
			style: {whiteSpace: 'normal', padding: '8px', verticalAlign: 'middle'},
			//headerFormatter: this.formatHeaderLocation,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[4] + '%'},
			onSort: this.onSortChange
		}, {
			dataField: 'transId',
			text: '',
			editable: false,
			sort: false,
			style: {whiteSpace: 'normal', padding: '8px', verticalAlign: 'middle'},
			formatter: this.editFormatter,
			//headerFormatter: this.formatHeaderLocation,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[4] + '%'},
			onSort: this.onSortChange
		}];




		  return (
			<div className="admin-wrapper">
				<HeaderAdmin />
				<div className="dashboard-wrapper partscontent">
				  <div className="dashboardhome">
					  <div className="container-fluid dashboard-content jobspagecnt">
						<div className="row">
						  <div className="col-md-12">
							<ol className="breadcrumb breadm">
								<li><a href="/admin">Admin</a></li>
								<li><i className="fa fa-angle-right midarrow" /><a href="/admin/slangtranslation">Slang Translation</a></li>
							</ol>
						  </div>
						</div>

						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<BootstrapTable
									keyField="transId"
									data={ this.state.data }
									columns={ columns }
									sort={defaultSort}
									cellEdit={ cellEditFactory({
										mode: 'click',
										blurToSave: true,
										afterSaveCell: (oldValue, newValue, row, column) => {
											this.saveTranslation(row)
										}
									})}
								/>

							</div>
						</div>

						</div>
					</div>

				<FooterAdmin />
				</div>
			</div>
		  )
	}

}

class SlangTranslationButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  close() {
    this.setState( {open:false} )
  }

  open() {
    this.setState( {open:true} );
  }



  render() {
    return (
      <>
        <button onClick={this.open} className="link-btn" ><i className="fa fa-server"></i></button>
        <SlangTranslationModal delete={this.props.delete} update={this.props.update} menus={this.props.menus} save={this.props.save} submit={this.props.submit} data={this.props.data} open={this.state.open} close={this.close} />
      </>
    )
  }
}

class SlangTranslationModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
			teMenuId: 548,
			teMenuName: "Base Door Cabinets",
			teText: '',
			teTransId: props.data.transId,
	  	errorClass: '',
	  	SlangClass: 'form-control'
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

	handleChange(e) {
		if (e.target.name === 'teMenuId'){
			const {options, value} = e.target;
			this.setState({
				teMenuId: value,
				teMenuName: options[options.selectedIndex].textContent
			}, () => {
				console.log('this.state', this.state);
			})
		}else{
			this.setState({
				[e.target.name]: e.target.value
			}, () => {

			})
		}


	}

  submit() {
    const teText = this.state.teText
    let valid = true;

    if (teText === '') {
      valid = false;
      this.setState({SlangClass: 'form-control border-danger', errorClass: 'text-red'})
    }

    if (valid) {
      this.props.submit(this.state);
    }
  }

  render() {
		const {menus} = this.props
    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal InvoiceModal"
        >

       <Modal.Header closeButton>
         ADD TRANSLATION
       </Modal.Header>

        <Modal.Body>
				<div className="row">
				<div className="col-md-6">
          <div className="form-group col-md-12" style={{paddingLeft: '0px', marginTop: '10px', marginBottom: '10px'}}>
            <label>Field Name</label>
            <input disabled={true} type="text" className="form-control" name="transField" defaultValue={this.props.data.transField} />
          </div>
					<div className="form-group col-md-12" style={{paddingLeft: '0px', marginTop: '0px', marginBottom: '10px'}}>
            <label>Target Form</label>
            <input disabled={true}  type="text" name="transLocation" defaultValue={this.props.data.transLocation} className="form-control" />
          </div>
          <div className="form-group col-md-12" style={{paddingLeft: '0px', marginTop: '0px', marginBottom: '10px'}}>
            <label>Australian Slang</label>
						<input disabled={true}  type="text" name="transSlang" defaultValue={this.props.data.transSlang} className="form-control" />
          </div>

				</div>
				<div className="col-md-6">
				<div className="form-group col-md-12" style={{paddingLeft: '0px', marginTop: '10px', marginBottom: '10px'}}>
					<label>Second Level Menu</label>
					<select className="form-control" name="teMenuId" onChange={this.handleChange}>
						{menus && menus.length > 0 ?
							menus.map(menu => (
								<optgroup label={menu.text}>
									{menu.children && menu.children.length > 0 ?
										menu.children.map(child => (
											<option value={child.id}>{child.text}</option>
										)) : ''
									}
								</optgroup>
							)) : ''
						}
					</select>
				</div>
				<div className="form-group col-md-12" style={{paddingLeft: '0px', marginTop: '10px', marginBottom: '10px'}}>
					<label>Text to Show</label>
					<input  type="text" name="teText" className="form-control" onChange={this.handleChange} />
					<button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Add</button>
				</div>
				</div>
				</div>
				<TranslationExceptions delete={this.props.delete} update={this.props.update} data={this.props.data} menus={this.props.menus} />
         </Modal.Body>

      </Modal>

    )

  }

}

class TranslationExceptions extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			columnWidth: [0, 35, 55, 10],
		}

		this.onSortChange = this.onSortChange.bind(this);
		this.saveExceptions = this.saveExceptions.bind(this);
		this.editFormatter = this.editFormatter.bind(this);
		this.formatMenuName = this.formatMenuName.bind(this);
		this.deleteException = this.deleteException.bind(this);
	}

	saveExceptions(data) {
		this.props.update(data);
	}

	deleteException(teId){
		this.props.delete(teId);
	}

	onSortChange(field, order) {
		//console.log(field, order)
		localStorage.setItem("ExceptiongSort", JSON.stringify({
		sortName: field,
		sortOrder: order
		}));
	}

	editFormatter = (data, row) =>{
		return <button className="link-btn" onClick={ () => this.deleteException(row.teId) }><i className="fa fa-trash" /></button>
	}

	formatMenuName(data, row){
		return row.teMenuName
	}
	render() {
		var ExceptiongSort = localStorage.getItem('ExceptiongSort');

		if (ExceptiongSort) {
			ExceptiongSort = JSON.parse(ExceptiongSort)
		}

		const defaultSort = {
			dataField: (ExceptiongSort) ? ExceptiongSort.sortName : null,
			order: (ExceptiongSort) ? ExceptiongSort.sortOrder : null,
		}

		var menus = [];

		/* var d = this.props.menus ? this.props.menus.map((menu) => {
			menu.children.map((child) => {
				menus.push({
					value: child.id,
					label: child.text
				})
				return child
			})
			return menu;
		}) : [] */

		//console.log('this.props.menus', menus);

		const columns = [{
			dataField: 'teMenuName',
			sort: false,
			text: 'teMenuName',
			hidden: true,
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			formatter: this.escapeFormatter,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[0] + '%'},

		}, {
			dataField: 'teMenuId',
			text: 'Second Level Menu',
			sort: true,
			formatter: this.formatMenuName,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[1] + '%'},
			style: {verticalAlign: 'middle'},
			editor: {
				type: Type.SELECT,
				options: menus
			},
			onSort: this.onSortChange
		}, {
			dataField: 'teText',
			text: 'Text to Show',
			sort: true,
			formatter: this.useSlangFormatter,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[2] + '%'},
			style: {verticalAlign: 'middle'},
			onSort: this.onSortChange
		}, {
			dataField: 'teId',
			text: '',
			sort: false,
			formatter: this.editFormatter,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[3] + '%'},
			style: {verticalAlign: 'middle'}
		}];

		//console.log('this.props.data', this.props.data)
		const {exceptions} = this.props.data
		console.log('exceptions', exceptions)
		  return (
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<BootstrapTable
									keyField="teId"
									data={ exceptions }
									columns={ columns }
									sort={defaultSort}
									cellEdit={ cellEditFactory({
										mode: 'click',
										blurToSave: true,
										afterSaveCell: (oldValue, newValue, row, column) => {
											if (column.dataField === 'teMenuId') {
												const selectedmenu = menus.filter(menu => {
										        return newValue === menu.value
										    });
												row.teMenuName = selectedmenu[0] ? selectedmenu[0].label : row.teMenuName
											}

											this.saveExceptions(row)
										}
									})}
								/>

							</div>
						</div>
		  )
	}

}

export default SlangTranslation
