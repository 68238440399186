import React from 'react';
import {Elements} from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutForm';
//import config from '../config';
class MyStoreCheckout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			JobId: (props.JobId) ? props.JobId : 0,
			projectId: (props.projectId) ? props.projectId : 0,
			subtotal: (props.subtotal) ? props.subtotal : 0,
			Customer: (props.Customer) ? props.Customer : [],
			addressData: (props.addressData) ? props.addressData : [],
			productsData: (props.productsData) ? props.productsData : [],
        }

    }
    componentWillReceiveProps(newprops){

		//console.log('newprops', newprops)

        this.setState({
			JobId: (newprops.JobId) ? newprops.JobId : 0,
			projectId: (newprops.projectId) ? newprops.projectId : 0,
			subtotal: (newprops.subtotal) ? newprops.subtotal : 0,
			Customer: (newprops.CustomerId) ? newprops.Customer : [],
			addressData: (newprops.addressData) ? newprops.addressData : [],
			productsData: (newprops.productsData) ? newprops.productsData : [],
        });
		//console.log(newprops);
    }
    componentDidMount() {
		/*
		this.setState({
			projectid: project,
		}, () => {

			fetch(config.BASE_URL + '/api/getjobbyproject/'+project, {
				method: "GET",
				headers: {
					  'Content-Type': 'application/json',
					  'Accept': 'application/json'
				}
			})
			.then(data => data.json())
			.then(data => {
				console.log(data);
				this.setState({
					job: data
				}, () => {

				})
			})
			.catch(error => console.log(error));

		})
		*/
    }

  render() {

    return (
      <Elements>
		<InjectedCheckoutForm
			completePayment={this.props.completePayment}
			JobId={this.state.JobId}
			projectId={this.state.projectId}
			subtotal={this.state.subtotal}
			Customer = {this.props.Customer}
			addressData={this.state.addressData}
			redirect={this.props.redirect}
			result={this.props.result}
			assemblyCost={this.props.assemblyCost}
      		min_order = {this.props.min_order}
			productsData={this.props.productsData}
			saveJob={this.props.saveJob}
      		pickupOption={this.props.pickupOption}
          isPickUp = {this.props.isPickUp}
          shippingCost={this.props.shippingCost}
          TotalWeight={this.props.TotalWeight}
          customDelivery={this.props.customDelivery}
		/>
      </Elements>
    );
  }
}

export default MyStoreCheckout;
