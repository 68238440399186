import React, { Component } from 'react';
import Nestable from 'react-nestable';
const styles = {  
	position: "relative",
	padding: "6px 15px",
	fontSize: "16px",
	border: "1px solid rgb(255, 255, 255)",
	background: "#EEE",
	cursor: "pointer",
	borderRadius: "5px"
};

class AdminMenuProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
			items: props.items,
			parentid: props.itemid
        };
		this.onChange = this.onChange.bind(this)
    }
	
    componentDidMount() {

    }
	
	onChange = (data, item) =>{
		this.props.onChangeProductOrder(data, item, this.state.parentid)
	}	
	renderItem = ({ item, collapseIcon, handler }) => {
		return (
		  <div style={styles} key={item.item}>
			  {item.ProductName	}  
		  </div>
		);
	};


  render() {
	const { items } = this.state
	const itemsFix = items.map(item =>{
		if (!item.hasOwnProperty('id')) {
			item.id = item.ProductId
		}
		return item
	});
	
    return (
		<div className="row menu-products">						
			<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
				<h3 className="menuproducts-heading">Menu Products</h3>
				<Nestable 
					items={[...itemsFix]}
					collapsed={false}
					renderItem={this.renderItem}
					ref={el => this.refNestable = el}
					onChange={this.onChange}
					maxDepth={1}
				/>
			</div>
		</div>	
    );
  }
}

export default AdminMenuProducts;