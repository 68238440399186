import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';

class Outstanding extends Component {

    constructor(props) {
        super(props);

        var user = JSON.parse(localStorage.getItem('authuser'));

        this.state = {
            data: [],
            user: user[0],
            columnWidth: [100, 200, 200, 200, 200, 200, 200, 200, 100],            
        }

        this.buttons = this.buttons.bind(this);
        this.amountFormat = this.amountFormat.bind(this);
        this.openInvoice = this.openInvoice.bind(this);
    }

    openInvoice(row) {
        
        const file = row.File;

        if (file) {
            window.open(`${config.UPLOAD_URL}uploads/invoices/${file}`);
        }
    }

    amountFormat(cell) {

        const amount = cell;

        return (
            <>
                {amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </>
        )
    }

    buttons(cell, row) {
        return (
            <>
                <button onClick={ () => this.openInvoice(row)} className="link-btn"><i className="fa fa-receipt" /></button>
            </>
        )
    }

    componentDidMount() {

        const authuser = this.state.user;

        console.log(this.state.user);

        fetch(config.BASE_URL + '/api/getoutstanding', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { data: data.outstandings } );
        })
        .catch(error => console.log(error));

    }

    render() {
        return (
            <>
                <div className="admin-wrapper">
					<HeaderAdmin />
					<div className="dashboard-wrapper partscontent">
						<div className="dashboardhome">
							<div className="container-fluid dashboard-content jobspagecnt">
								{/* ============================================================== */}
								{/* pagecontent  */}
								{/* ============================================================== */}
								<div className="row">
								<div className="col-md-12">
									<ol className="breadcrumb breadm">
										<li><a href="/admin">Admin</a></li>
										<li ><i className="fa fa-angle-right midarrow" /><a style={{ cursor: "pointer" }}>Outstanding</a></li>
									</ol>
								</div>
								</div>
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										{/* top heading section*/}
										<div className="inner-pagehead">
											<h1>OUTSTANDING</h1>
										</div>
										  <div className="products-table">

                                            <BootstrapTable
                                                ref='table'
                                                data={this.state.data}
                                                pagination={true}                                               
                                                search={true}
                                                hover={true}
                                            >
                                                <TableHeaderColumn
                                                    hidden={true}
                                                    dataField='id'
                                                    dataSort={true}
                                                    isKey={true}>
                                                        Invoice Id
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='CustomerName'
                                                    dataSort={true}
                                                    width={this.state.columnWidth[2] + 'px'}
                                                    >Customer Name</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='CustomerEmail'
                                                    dataSort={true}                                                    
                                                    width={this.state.columnWidth[3] + 'px'}
                                                    >Email
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='ContactNo'
                                                    dataSort={true}                                                    
                                                    width={this.state.columnWidth[4] + 'px'}
                                                    >Contact No</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='InvoiceDate'
                                                    dataSort={true}
                                                    width={this.state.columnWidth[5] + 'px'}
                                                    >Invoice Date</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='ReceiptDate'
                                                    dataSort={true}
                                                    width={this.state.columnWidth[6] + 'px'}
                                                    >Receipt Date</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='Amount'
                                                    dataSort={true}
                                                    headerAlign='right'
                                                    dataAlign='right'
                                                    dataFormat={this.amountFormat}
                                                    width={this.state.columnWidth[7] + 'px'}                                                    
                                                    >Total Due</TableHeaderColumn>                                                
                                                <TableHeaderColumn
                                                    dataFormat={this.buttons}
                                                    dataSort={false}
                                                    headerAlign='center'
                                                    dataAlign='center'
                                                    width={this.state.columnWidth[7] + 'px'}                                                    
                                                    ></TableHeaderColumn>                                                    
                                            </BootstrapTable>

                                        </div>
									</div>
								</div>
								{/* ============================================================== */}
								{/* end pagecontent  */}
								{/* ============================================================== */}
							</div>
						</div>
						<FooterAdmin />                        
					</div>
				</div>
            </>
        )
    }

}

export default Outstanding;
