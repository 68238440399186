import React, { Component } from 'react';
import ScriptTag from 'react-script-tag';

//import '../assets/css/all.min.css';

//import '../assets/css/bootstrap.min.css';
//import '../assets/css/all.min.css';
//import '../assets/css/animate.css';
//import '../assets/css/nice-select.css';
//import '../assets/css/owl.min.css';
//import '../assets/css/jquery-ui.min.css';
//import '../assets/css/magnific-popup.css';
//import '../assets/css/flaticon.css';

//import '../assets/css/main.css';

const JS = props => (
    <>
        <ScriptTag type="text/javascript" src="../assets/js/jquery-3.3.1.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/modernizr-3.6.0.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/plugins.js" />
        <ScriptTag type="text/javascript" src="../assets/js/bootstrap.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/magnific-popup.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/jquery-ui.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/wow.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/waypoints.js" />
        <ScriptTag type="text/javascript" src="../assets/js/wow.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/nice-select.js" />
        <ScriptTag type="text/javascript" src="../assets/js/owl.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/counterup.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/paroller.js" />
        <ScriptTag type="text/javascript" src="../assets/js/main.js" />
        
    </>
)

const Header = () => {

    return (

        <>

            <header className="header-section">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="trade-only">
                            TRADE ONLY
                        </div>
                        <div className="logo">
                            <a href="index.html">
                                <img src="./images/logo-2-australia.png" alt="logo" />
                            </a>
                        </div>
                        <ul className="menu">
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <a href="#feature">Product Gallery</a>
                            </li>
                            <li>
                                <a href="#how">Software Feature</a>
                            </li>
                            <li>
                                <a href="#pricing">Contact Us</a>
                            </li>
                            <li>
                                <a href="#coverage">About Us</a>
                            </li>                            
                        </ul>
                        <div className="header-bar d-lg-none">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="header-right">
                            {
                                /* <select className="select-bar">
                                    <option value="en">En</option>
                                    <option value="Bn">Bn</option>
                                    <option value="pk">Pk</option>
                                    <option value="Fr">Fr</option>
                                </select> */ 
                            }
                            <a href="#0" className="header-button d-none d-sm-inline-block">Login</a>
                        </div> 
                    </div>
                </div>
            </header>
        
        </>
    )

}

const Footer = () => {

    return (

        <>

            <footer className="footer-section bg_img">
                <div className="container">
                    <div className="footer-top padding-top padding-bottom">
                        <div className="logo">
                            <a href="#0">
                                <img src="./assets/images/logo/footer-logo.png" alt="logo" />
                            </a>
                        </div>
                        <ul className="social-icons">
                            <li>
                                <a href="#0"><i className="fab fa-facebook-f"></i></a>
                            </li>
                            <li>
                                <a href="#0" className="active"><i className="fab fa-twitter"></i></a>
                            </li>
                            <li>
                                <a href="#0"><i className="fab fa-pinterest-p"></i></a>
                            </li>
                            <li>
                                <a href="#0"><i className="fab fa-google-plus-g"></i></a>
                            </li>
                            <li>
                                <a href="#0"><i className="fab fa-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-bottom">
                        <ul className="footer-link">
                            <li>
                                <a href="#0">About</a>
                            </li>
                            <li>
                                <a href="#0">FAQs</a>
                            </li>
                            <li>
                                <a href="#0">Contact</a>
                            </li>
                            <li>
                                <a href="#0">Terms of Service</a>
                            </li>
                            <li>
                                <a href="#0">Privacy</a>
                            </li>
                        </ul>
                    </div>
                    <div className="copyright">
                        <p>
                            Copyright © 2020.All Rights Reserved By <a href="#0">Mosto</a>
                        </p>
                    </div>
                </div>
            </footer>
            {/* <JS /> */ }
        </>
    )

}

const Preloader = () => {

    return (

        <>

            <div className="preloader">
                <div className="preloader-inner">
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
            <div className="overlay"></div>
        
        </>
    )

}

const Banner = () => {

    return (

        <>

            <section className="banner-1 bg_img oh" data-background="linear-gradient(#51BD58, #BFDE8E)">
                <div className="dot-1 d-none d-lg-block">
                    <img src="./assets/images/banner/dot-big.png" alt="banner" />
                </div>
                <div className="dot-2 d-none d-lg-block">
                    <img src="./assets/images/banner/dot-big.png" alt="banner" />
                </div>
                <div className="dot-3">
                    <img src="./assets/images/banner/dot-sm.png" alt="banner" />
                </div>
                <div className="dot-4">
                    <img src="./assets/images/banner/dot-sm.png" alt="banner" />
                </div>
                <div className="banner-1-shape d-none d-lg-block">
                    <img src="./assets/css/img/banner1-shape.png" alt="css" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="banner-content-1 cl-white">
                                <h1 className="title">An online platform for Australian Cabinet Makers & Builders and Tradies</h1>
                                <p>
                                Sign up to Australian’s №1 platform for Tradies for kitchen Renovation and we will send you a beer coaster in the mail!
                                </p>
                                <div className="banner-button-group">
                                    <a href="#0" className="button-4">Sign up</a>                                  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-1-slider-wrapper">
                                <div className="banner-1-slider owl-carousel owl-theme">
                                    <div className="banner-thumb">
                                        <img src="./images/macbook.png" alt="banner" className="macbook" />
                                        <img src="./images/mechanic.png" alt="banner" className="mechanic" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src="./images/macbook.png" alt="banner" className="macbook" />
                                        <img src="./images/mechanic.png" alt="banner" className="mechanic" />
                                    </div>
                                    
                                    {
                                        /*
                                            <div className="banner-thumb">
                                                <img src="./assets/images/banner/banner1-2.png" alt="banner" />
                                            </div>
                                            <div className="banner-thumb">
                                                <img src="./assets/images/banner/banner1-3.png" alt="banner" />
                                            </div>
                                            <div className="banner-thumb">
                                                <img src="./assets/images/banner/banner1-1.png" alt="banner" />
                                            </div>
                                            <div className="banner-thumb">
                                                <img src="./assets/images/banner/banner1-2.png" alt="banner" />
                                            </div>
                                            <div className="banner-thumb">
                                                <img src="./assets/images/banner/banner1-3.png" alt="banner" />
                                            </div>
                                        */

                                    }
                                </div>
                                <div className="ban-click">
                                    <div className="thumb">
                                        <img src="./assets/images/banner/click.png" alt="banner" />
                                    </div>
                                    <span className="cl-white">Click Me</span>
                                </div>
                                <div className="arrow">
                                    <img src="./assets/images/banner/arrow.png" alt="banner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )

}

const Sponsors = () => {

    return (

        <>

            <div className="sponsor-section padding-bottom padding-top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="spon-cont">
                                <p>Used by over 1,000,000 people worldwide</p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="sponsor-slider owl-theme owl-carousel">
                                <div className="sponsor-thumb">
                                    <img src="./assets/images/sponsor/sponsor1.png" alt="sponsor" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/images/sponsor/sponsor2.png" alt="sponsor" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/images/sponsor/sponsor3.png" alt="sponsor" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/images/sponsor/sponsor4.png" alt="sponsor" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

const Features = () => {

    return (

        <section className="amazing-feature-section pos-rel" id="feature">
            <div className="shape-container oh">
                <div className="bg_img feature-background" data-background="./assets/images/bg/amazing-feature-bg.jpg"></div>
                <div className="feature-top-shape d-none d-lg-block">
                    <img src="./assets/css/img/feature-shape.png" alt="css" />
                </div>
            </div>
            <div className="topper-feature oh padding-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-header left-style mb-lg-0 white-lg-black pos-rel">
                                <h5 className="cate">Explore Amazing Features</h5>
                                <h2 className="title">That will boost your productivity</h2>
                                <p>With our wide range of features, you can create a custom app 
                                no matter what your niche: restaurant, Realtor, small business,
                                rock band, and all the rest!</p>
                                <div className="downarrow d-none d-lg-block">
                                    <img src="./assets/images/feature/downarrow.png" alt="feature" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <a href="https://www.youtube.com/watch?v=GT6-H4BRyqQ" className="feature-video-area popup">
                                <div className="thumb">
                                    <img src="./assets/images/feature/fature-video.png" alt="feature" />
                                </div>
                                <div className="button-area">
                                    <h4 className="title">
                                        Watch Videos
                                    </h4>
                                    <div className="video-button">
                                        <i className="flaticon-play"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="amazing-feature-bottom padding-top padding-bottom pb-lg-0 pt-lg-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-xl-7">
                            <div className="section-header left-style cl-white">
                                <h5 className="cate">A Collection of Prominent Features</h5>
                                <h2 className="title">Delivering Exceptional User Experiences.</h2>
                                <p>Numerous features make it possible to customize the system
                                    in accordance with all your needs.</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row justify-content-center mb-30-none">
                                <div className="col-sm-6 col-lg-3">
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src="./assets/images/feature/am-fea1.png" alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Free Trial</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="am-item active">
                                        <div className="am-thumb">
                                            <img src="./assets/images/feature/am-fea2.png" alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Secure Data</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src="./assets/images/feature/am-fea3.png" alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Quick Access</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src="./assets/images/feature/am-fea4.png" alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">24h Support</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const Coverage = () => {

    return (

        <section className="coverage-section padding-top padding-bottom" id="coverage">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="section-header left-style coverage-header">
                            <h5 className="cate">Our stats say more than any words</h5>
                            <h2 className="title">Apps Without Borders</h2>
                            <p>
                                Mosta app are growing by 300% every year with a steady love from users around the world. We are also close to achieving 10 million cumulative downloads.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="coverage-right-area text-lg-right">
                            <div className="rating-area">
                                <div className="ratings">
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                </div>
                                <span className="average">5.0 / 5.0</span>
                            </div>
                            <h2 className="amount">312,921+</h2>
                            <a href="#0">Total User Reviews <i className="fas fa-paper-plane"></i></a>
                        </div>
                    </div>
                </div>
                <div className="coverage-wrapper bg_img" data-background="./assets/images/bg/world-map.png">
                    <div className="border-item-1">
                        <span className="name">North America</span>
                        <h2 className="title">70.7%</h2>
                    </div>
                    <div className="border-item-2">
                        <span className="name">Asia</span>
                        <h2 className="title">14.4%</h2>
                    </div>
                    <div className="border-item-3">
                        <span className="name">North Europe</span>
                        <h2 className="title">8.4%</h2>
                    </div>
                    <div className="border-item-4">
                        <span className="name">South America</span>
                        <h2 className="title">1.8%</h2>
                    </div>
                    <div className="border-item-5">
                        <span className="name">Africa</span>
                        <h2 className="title">1.8%</h2>
                    </div>
                    <div className="border-item-6">
                        <span className="name">Australia</span>
                        <h2 className="title">3%</h2>
                    </div>
                </div>
            </div>
        </section>

    )
}

const Pricing = () => {

    return (

        <section className="pricing-section padding-top oh padding-bottom pb-md-0 bg_img pos-rel" data-background="./assets/images/bg/pricing-bg.jpg" id="pricing">
            <div className="top-shape d-none d-md-block">
                <img src="./assets/css/img/top-shape.png" alt="css" />
            </div>
            <div className="bottom-shape d-none d-md-block">
                <img src="./assets/css/img/bottom-shape.png" alt="css" />
            </div>
            <div className="ball-2" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
            data-paroller-type="foreground" data-paroller-direction="horizontal">
                <img src="./assets/images/balls/1.png" alt="balls" />
            </div>
            <div className="ball-3" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
            data-paroller-type="foreground" data-paroller-direction="horizontal">
                <img src="./assets/images/balls/2.png" alt="balls" />
            </div>
            <div className="ball-4" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
            data-paroller-type="foreground" data-paroller-direction="horizontal">
                <img src="./assets/images/balls/3.png" alt="balls" />
            </div>
            <div className="ball-5" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
            data-paroller-type="foreground" data-paroller-direction="vertical">
                <img src="./assets/images/balls/4.png" alt="balls" />
            </div>
            <div className="ball-6" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
            data-paroller-type="foreground" data-paroller-direction="horizontal">
                <img src="./assets/images/balls/5.png" alt="balls" />
            </div>
            <div className="ball-7" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
            data-paroller-type="foreground" data-paroller-direction="vertical">
                <img src="./assets/images/balls/6.png" alt="balls" />
            </div>
            <div className="container">
                <div className="section-header pb-30 cl-white">
                    <h5 className="cate">Choose a plan that's right for you</h5>
                    <h2 className="title">Simple Pricing Plans</h2>
                    <p>
                        Mosto has plans, from free to paid, that scale with your needs. Subscribe to a plan that fits the size of your business.
                    </p>
                </div>
                <div className="range-wrapper-2">
                    <div className="pricing-range">
                        <div className="pricing-range-top pt-0">
                            <div className="tags-area">
                                <h3 className="tags">Eastimate Your Price</h3>
                            </div>
                            <div className="pricing-header">
                                <span className="cate">Pricing Options</span>
                                <div className="select-container">    
                                    <select className="select-bar">
                                        <option value="basic">Basic</option>
                                        <option value="standard">Standard</option>
                                        <option value="premium">Premium</option>
                                        <option value="pro">Premium Pro</option>
                                    </select>
                                </div>
                            </div>
                            <div className="amount-area">
                                <div className="item">
                                    <h2 className="title"><sup>$</sup>15.00</h2>
                                    <span className="info">Per Month</span>
                                </div>
                                <div className="item">
                                    <h2 className="title"><sup>$</sup>199.00</h2>
                                    <span className="info">Per Year</span>
                                </div>
                            </div>
                            <div className="invest-range-area">
                                <div className="invest-amount" data-min="1.00 USD" data-max="1000 USD">
                                    <div id="usd-range" className="invest-range-slider"></div>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-range-bottom">
                            <div className="left">
                                <span className="cate">People Joined Already</span>
                                <h2 className="title">455,326,234</h2>
                            </div>
                            <div className="right">
                                <a href="#0" className="custom-button">Download App</a>
                                <ul className="download-options">
                                    <li>
                                        <a href="#0" className="active"><i className="fab fa-windows"></i></a>
                                    </li>
                                    <li>
                                        <a href="#0"><i className="fab fa-apple"></i></a>
                                    </li>
                                    <li>
                                        <a href="#0"><i className="fab fa-android"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const Feature = () => {

    return (

        <section className="feature-section padding-top padding-bottom oh pos-rel">
            <div className="feature-shapes d-none d-lg-block">
                <img src="./assets/images/feature/feature-shape.png" alt="feature" />
            </div>
            <div className="container">
                <div className="section-header mw-725">
                    <h5 className="cate">Extra Crazy Features that will</h5>
                    <h2 className="title">Boost Your Productivity </h2>
                    <p>
                        In the process of making a app, the satisfaction of users is the most important and the focus is on usability and completeness
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-5 rtl">
                        <div className="feature--thumb pr-xl-4 ltr">
                            <div className="feat-slider owl-carousel owl-theme" data-slider-id="1">
                                <div className="main-thumb">
                                    <img src="./assets/images/feature/pro-main2.png" alt="feature" />
                                </div>
                                <div className="main-thumb">
                                    <img src="./assets/images/feature/pro-main.png" alt="feature" />
                                </div>
                                <div className="main-thumb">
                                    <img src="./assets/images/feature/pro-main3.png" alt="feature" />
                                </div>
                                <div className="main-thumb">
                                    <img src="./assets/images/feature/pro-main4.png" alt="feature" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="feature-wrapper mb-30-none owl-thumbs" data-slider-id="1">
                            <div className="feature-item">
                                <div className="feature-thumb">
                                    <div className="thumb">
                                        <img src="./assets/images/feature/pro1.png" alt="feature" />
                                    </div>
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Optimized Speed & Quality</h4>
                                    <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                                </div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-thumb">
                                    <div className="thumb">
                                        <img src="./assets/images/feature/pro2.png" alt="feature" />
                                    </div>
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Flexible Usability</h4>
                                    <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                                </div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-thumb">
                                    <div className="thumb">
                                        <img src="./assets/images/feature/pro3.png" alt="feature" />
                                    </div>
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Easy to Manage Your All Data</h4>
                                    <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                                </div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-thumb">
                                    <div className="thumb">
                                        <img src="./assets/images/feature/pro4.png" alt="feature" />
                                    </div>
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Designed for all devices</h4>
                                    <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const Newsletter = () => {

    return (

        <section className="newslater-section oh bg_img pos-rel" data-background="./assets/images/bg/newslater2.jpg">
            <div className="top-shape d-none d-lg-block">
                <img src="./assets/css/img/top-shape2.png" alt="css" />
            </div>
            <div className="bottom-shape d-none d-lg-block">
                <img src="./assets/css/img/bottom-shape2.png" alt="css" />
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="newslater-area padding-bottom padding-top">
                            <div className="section-header left-style cl-white">
                                <h5 className="cate">Be the first to know</h5>
                                <h2 className="title">About New Features</h2>
                                <p>
                                    If you want to receive monthly updates from us just pop your email in the box. You can unclassName= at any time. Your privacy & personal information will be treated.
                                </p>
                            </div>
                            <form className="className=-form">
                                <input type="text" placeholder="Enter Your Email" />
                                <button type="submit">className=</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 d-none d-lg-block">
                        <div className="className=-thumb-2">
                            <img src="./assets/images/newslater/newslater2.png" alt="newslater" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const How = () => {

    return (

        <section className="how-section padding-bottom padding-top" id="how">
            <div className="container">
                <div className="section-header">
                    <h5 className="cate">Describe Your App</h5>
                    <h2 className="title">Let’s See How It Work</h2>
                    <p>It's easier than you think.Follow 3 simple easy steps</p>
                </div>
                <div className="row justify-content-xl-between justify-content-center mb-40-none">
                    <div className="col-lg-4 col-sm-6 col-xl-3">
                        <div className="how-item">
                            <div className="how-thumb">
                                <img src="./assets/images/how/how1.png" alt="how" />
                            </div>
                            <div className="how-content">
                                <a href="#0" className="button-3 active">Download App</a>
                                <ul className="download-options">
                                    <li>
                                        <a href="#0"><i className="fab fa-windows"></i></a>
                                    </li>
                                    <li>
                                        <a href="#0" className="active"><i className="fab fa-apple"></i></a>
                                    </li>
                                    <li>
                                        <a href="#0"><i className="fab fa-android"></i></a>
                                    </li>
                                </ul>
                                <p>Download App either for Windows,Mac or Android</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xl-3">
                        <div className="how-item">
                            <div className="how-thumb">
                                <img src="./assets/images/how/how2.png" alt="how" />
                            </div>
                            <div className="how-content">
                                <a href="#0" className="button-3">Create Account</a>
                                <h5 className="title">14 days free trial</h5>
                                <p>Sign up for Mosta account. One account for all devices</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xl-3">
                        <div className="how-item">
                            <div className="how-thumb">
                                <img src="./assets/images/how/how3.png" alt="how" />
                            </div>
                            <div className="how-content">
                                <a href="#0" className="button-3">Enjoy The App</a>
                                <h5 className="title">Have any questions
                                    check our <a href="#0">FAQs</a></h5>
                                <p>Explore and share mosta</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const Sections = () => {

    return (

        <>

            <Banner />
            <Sponsors />
            <Features />
            <How />
            <Newsletter />
            <Feature />
            <Pricing />
            <Coverage />           

        </>

    )

}

class Home extends Component {

    render() {

        return (

            <>
                <Preloader />

                <Header />

                <Sections />

                <Footer />
            </>

        );
    }
}

export default Home;