import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import config from "../config";
import Modal from 'react-bootstrap-modal';

class PayByOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            customer: props.customer
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open() {
        this.setState( { open: true } );
    }

    close() {
        this.setState( { open: false } );
    }

    render() {
        return (
            <>
                <button className="link-btn" onClick={this.open}><i className="fa fa-credit-card" /></button>
                <PayByOrdersModal authuser={this.props.authuser} customer={this.state.customer} open={this.state.open} close={this.close} />
            </>
        )
    }
}

class PayByOrdersModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
        }

        this.orderSummary = this.orderSummary.bind(this);
        this.buttons = this.buttons.bind(this);
        this.setCompleted = this.setCompleted.bind(this);
        this.setPending = this.setPending.bind(this);
    }

    setCompleted(JobId) {
        const authuser = this.props.authuser;
        const data = {CustomerId : this.props.customer.CustomerId, JobId: JobId, Status: 'Completed'}

        fetch(config.BASE_URL + '/api/setpaybyorderstatus', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { data: data.data } );
        })
        .catch(error => console.log(error));

    }

    setPending(JobId) {
        const authuser = this.props.authuser;
        const data = {CustomerId : this.props.customer.CustomerId, JobId: JobId, Status: 'Pending'}

        fetch(config.BASE_URL + '/api/setpaybyorderstatus', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { data: data.data } );
        })
        .catch(error => console.log(error));
    }

    buttons(cell, row) {
        return (
            <>
                 
                { row.Status == 'Pending' || row.Status == 'In Progress' ? 
                    <button onClick={ () => this.setCompleted(row.JobId) } className="link-btn"><i className="fa fa-thumbs-up" /></button>
                : '' }

                { row.Status == 'Completed' ?
                    <button onClick={ () => this.setPending(row.JobId) } className="link-btn"><i className="fa fa-window-close" /></button>
                : '' }
            
            </>
            
        )
    }

    orderSummary() {
        let total = 0;
        let notpaid = 0;
        let paid = 0;
        

        this.state.data.map(order => {
            
            if (order.Status == 'Pending' || order.Status == 'In Progress') {
                notpaid += parseFloat(order.TotalCost);
            }

            if (order.Status == 'Completed') {
                paid += parseFloat(order.TotalCost);
            }

            total += parseFloat(order.TotalCost);
            

        });

        //console.log('paid', paid);
        //console.log('notpaid', notpaid);

        return (
            <div className="row invoice-summary">
                <div className="col-md-8" />
                <div className="col-md-4 p-0 m-0 border">
                    <table className="table table-border">
                        <tbody>
                            <tr>
                                <td className="text-right font-weight-bold">Not Paid</td>
                                <td className="text-right">{notpaid.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                            <tr>
                                <td className="text-right">Paid</td>
                                <td className="text-right">{paid.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                            <tr>
                                <td className="text-right">Total</td>
                                <td className="text-right">{total.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    componentDidMount() {

        const authuser = this.props.authuser;

        const data = {CustomerId : this.props.customer.CustomerId}

        fetch(config.BASE_URL + '/api/paybyaccountorders', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { data: data.data } );
        })
        .catch(error => console.log(error));
    }

    render() {

        const data = this.state.data;

        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text            
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 25,          
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        data.length > 0
                            ? data.length
                            : 5
                }
            ]
        };       

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.update
        }

        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="payByOrdersModal">
                    <Modal.Header closeButton>
                        Pay By Account Projects - {this.props.customer.Name}
                    </Modal.Header>
                    <Modal.Body>
                        <BootstrapTable
                            ref='table'
                            data={data}
                            pagination={true}
                            options={options}
                            cellEdit={cellEditProp}
                            search={true} 
                            hover={true}
                            >
                            <TableHeaderColumn 
                                dataField='OrderDate' 
                                dataSort={true}
                                editable={false}                                
                                >Order Date
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='ProjectName' 
                                dataSort={true}
                                editable={false}                                
                                >Project Name
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='JobId' 
                                dataSort={true}    
                                isKey={true}                            
                                >Job ID
                            </TableHeaderColumn>                           

                            <TableHeaderColumn 
                                dataField='TotalCost' 
                                dataSort={true}                                
                                headerAlign="right"                          
                                dataAlign="right"                                
                                >Amount
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='Status' 
                                dataSort={true}
                                >Status
                            </TableHeaderColumn>

                            
                            <TableHeaderColumn 
                                dataAlign='center'
                                headerAlign='center'
                                editable={false}
                                dataFormat={this.buttons}
                                >                                
                            </TableHeaderColumn>
                                      
                        </BootstrapTable>

                        {this.orderSummary()}

                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default PayByOrders;