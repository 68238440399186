import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => {
	var authuser = localStorage.getItem('authuser');
	const isLoggedIn = (authuser !== null && authuser !== '') ? true : false;
	return (
		<Route
		  {...rest}
		  render={props =>
			isLoggedIn ? (
			  <Component {...props} />
			) : (
			  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
			)
		  }
		/>
	)
}
export const PrivateRouteCustomer = ({ component: Component, ...rest }) => {
	var authcustomer = localStorage.getItem('customer');
	const isLoggedIn = (authcustomer !== null && authcustomer !== '') ? true : false;
	return (
		<Route
		  {...rest}
		  render={props =>
			isLoggedIn ? (
			  <Component {...props} />
			) : (
			  <Redirect to={{ pathname: '/', state: { from: props.location } }} />
			)
		  }
		/>
	)
}
