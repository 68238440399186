import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Form, Button } from 'react-bootstrap';
import config from "../config";

import Dropzone from 'react-dropzone'
import axios from 'axios';
import swal from "sweetalert";
import { strings } from '../components/strings';
import Modal from 'react-bootstrap-modal';
import Receipt from './Receipt';
 
class Invoice extends Component {

    constructor (props) {
        super(props)

        this.state = {
            open: false,
            customer: props.customer
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open() {
        this.setState( { open:true } );
    }

    close() {
        this.setState( { open:false } )
    }

    render() {
        return (
            <>
                <button className="link-btn" onClick={this.open}><i className="fa fa-list-alt" /></button>
                <InvoiceModal customer={this.props.customer} open={this.state.open} close={this.close} authuser={this.props.authuser} />
            </>
        )
    }
}

class InvoiceModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            open: false,
            projects: []
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.AmountFormat = this.AmountFormat.bind(this);
        this.buttons = this.buttons.bind(this);
        this.delete = this.delete.bind(this);
        this.openInvoice = this.openInvoice.bind(this);
        this.InvoiceSummary = this.InvoiceSummary.bind(this);
        this.mailInvoice = this.mailInvoice.bind(this);
        this.update = this.update.bind(this);
        this.saveReceipts = this.saveReceipts.bind(this);
        this.deleteReceipt = this.deleteReceipt.bind(this);
    }

    deleteReceipt(data) {

        const authuser = this.props.authuser;

        fetch(config.BASE_URL + '/api/deletereceipt', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { data: data.invoices } );
        })
        .catch(error => console.log(error));
    }

    saveReceipts(data) {
        const authuser = this.props.authuser;

        fetch(config.BASE_URL + '/api/addreceipt', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { open: false, data: data.invoices } );
        })
        .catch(error => console.log(error));
    }

    update(row) {

        var authuser = this.props.authuser
    
        fetch(config.BASE_URL + '/api/updateinvoice', {
          method: "POST",
          body: JSON.stringify(row),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
                  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.setState(  {data: data.invoices} );
          })
          .catch(error => console.log(error));
      }

    InvoiceSummary() {

        let total = 0;
        let notpaid = 0;
        let paid = 0;

        this.state.data.map(invoice => {
            
            if (invoice.Status === 'Not Paid') {
                notpaid += parseFloat(invoice.Amount);
            }

            if (invoice.Status === 'Paid') {
                paid += parseFloat(invoice.Amount);
            }

            total += parseFloat(invoice.Amount);

            return invoice;
            

        });

        return (
            <div className="row invoice-summary">
                <div className="col-md-8" />
                <div className="col-md-4 p-0 m-0 border">
                    <table className="table table-border">
                        <tbody>
                            <tr>
                                <td className="text-right font-weight-bold">Not Paid</td>
                                <td className="text-right">{notpaid.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                            <tr>
                                <td className="text-right">Paid</td>
                                <td className="text-right">{paid.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                            <tr>
                                <td className="text-right">Total</td>
                                <td className="text-right">{total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    mailInvoice(cell, row) {
        const authuser = this.props.authuser;

        const data = {CustomerId : row.CustomerId, id: row.id, path: `${config.UPLOAD_PATH}`};

        fetch(config.BASE_URL + '/api/mailinvoice', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            swal({
                title: `Sent!`,
                text: `The invoice sent to custmer`,
                icon: "success",
            })
        })
        .catch(error => console.log(error));
    }

    openInvoice(cell, row) {
        const authuser = this.props.authuser;

        const data = {CustomerId : row.CustomerId, id: row.id, path: `${config.UPLOAD_URL}`};

        fetch(config.BASE_URL + '/api/openinvoice', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            window.open(`${config.UPLOAD_URL}uploads/invoices/${data.invoice.File}`);
        })
        .catch(error => console.log(error));
    }

    delete(cell, row) {

        swal({
            title: `${strings.Areyousure}`,
            text: `${strings.Thisactioncannotbeundone}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                
                const authuser = this.props.authuser;

                const data = {CustomerId : row.CustomerId, id: row.id}
        
                fetch(config.BASE_URL + '/api/deleteinvoice', {
                    body: JSON.stringify(data),
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': 'application/json',
                        "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
                    }
                })
                .then(data => data.json())
                .then(data => {
                    this.setState( { data: data.invoices } );
                })
                .catch(error => console.log(error));
            } 
          });
    }

    buttons(cell, row) {
        return (
            <>
                <button data-tip="Delete Invoice" onClick={ () => this.delete(cell, row) } className='link-btn'><i className="fa fa-trash" /></button>
                <button data-tip="Open Invoice" onClick={ () => this.openInvoice(cell, row) } className="link-btn"><i className="fa fa-file-invoice" /></button>
                <button data-tip="Email Invoice" onClick={ () => this.mailInvoice(cell, row) } className="link-btn"><i className="fa fa-envelope" /></button>
                <Receipt authuser={this.props.authuser} save={this.saveReceipts} data={row} delete={this.deleteReceipt} />
                { /* <button data-tip="Receipt" onClick={ () => this.mailInvoice(cell, row) } className="link-btn"><i className="fa fa-receipt" /></button> */}
            </>
        )
    }

    AmountFormat(cell) {

        const amount = cell;

        return (
            <>
                {amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </>
        )
    }

    componentDidMount() {

        const authuser = this.props.authuser;

        const data = {CustomerId : this.props.customer.CustomerId}

        fetch(config.BASE_URL + '/api/getinvoices', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { data: data.invoices, projects: data.projects } );
        })
        .catch(error => console.log(error));
    }

    save(data) {

        const authuser = this.props.authuser;

        fetch(config.BASE_URL + '/api/addinvoice', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { open: false, data: data.invoices } );
        })
        .catch(error => console.log(error));
    }

    open() {
        this.setState( { open: true } )
    }

    close() {
        this.setState( { open: false } )
    }

    render() {

        const data = this.state.data;

        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text            
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 25,          
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        data.length > 0
                            ? data.length
                            : 5
                }
            ]
        };

        const statusOptions = ['Not Paid', 'Paid'];

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.update
        }

        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="InvoiceModal">
                    <Modal.Header closeButton>
                        Invoices / Customer - {this.props.customer.Name}
                    </Modal.Header>
                    <Modal.Body>

                        <BootstrapTable
                            ref='table'
                            data={data}
                            pagination={true}
                            options={options}
                            cellEdit={cellEditProp}
                            search={true} 
                            hover={true}
                            >
                            <TableHeaderColumn 
                                hidden={true}
                                dataField='id'
                                dataSort={true}                                                    
                                isKey={true}>
                                Invoice Id
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='CreatedDate' 
                                dataSort={true}
                                editable={false}                                
                                >Created Date
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='InvoiceNo' 
                                dataSort={true}
                                editable={ {type: 'text'} }
                                >Invoice No
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='Status' 
                                dataSort={true}
                                editable={ {type: 'select', options: { values: statusOptions } } }
                                >Status
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='Amount' 
                                dataSort={true}
                                editable={ {type: 'number'} }      
                                headerAlign="right"                          
                                dataAlign="right"
                                dataFormat={this.AmountFormat}
                                >Amount
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='Notes' 
                                dataSort={true}
                                editable={ {type: 'textarea'} }
                                >Notes
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataAlign='center'
                                headerAlign='center'
                                editable={false}
                                dataFormat={this.buttons}
                                >
                                <button onClick={this.open} className="btn add-btn purchasebtn"><i className="fa fa-plus" /></button>
                            </TableHeaderColumn>
                                      
                        </BootstrapTable>

                        {this.InvoiceSummary()}

                        <AddInvoiceModal 
                            close={this.close} 
                            open={this.state.open} 
                            save={this.save}
                            projects={this.state.projects}
                            customer={this.props.customer} 
                            authuser={this.props.authuser} />
                        
                    </Modal.Body>                
                </Modal>
                
            </>
        )    
    }
}


class AddInvoiceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            CustomerId: props.customer.CustomerId,
            InvoiceNo: '',
            File: '',
            Amount: 0,
            Notes: '',
            uploaded: '',
            Status: 'Not Paid',
            Projects: 'No project selected',
            ProjectSelected: [],
            InvoiceNoClass : 'form-control',
            AmountClass: 'form-control',
            projectClass: '',
        }

        this.drop = this.drop.bind(this);
        this.handleChange = this.handleChange.bind(this);       
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showProjectList = this.showProjectList.bind(this);
        this.selectProject = this.selectProject.bind(this);
    }

    selectProject(id) {

        let projectSelected = this.state.ProjectSelected;

        let Amount = 0;

        if (projectSelected.indexOf(id) >= 0) {
            projectSelected = this.state.ProjectSelected.filter(projectId => {
                return projectId !== id;
            })
        } else {
            projectSelected.push(id);
        }
        
        this.props.projects.map(project => {
            if (projectSelected.indexOf(project.JobId) >= 0) {
                Amount += parseFloat(project.TotalCost);
            }
            return project;
        })

        this.setState( {ProjectSelected: projectSelected, Amount: Amount} );
    }

    showProjectList() {
        const projectClass = this.state.projectClass;

        if (projectClass === 'show') {
            this.setState( { projectClass: ''} );
        } else {
            this.setState( {projectClass: 'show'} );
        }
    }

    handleSubmit(e) {

        //this.props.save(this.state);
        e.preventDefault();        
        this.props.save(this.state);
    }

  

    handleChange(e) {
        this.setState( { [e.target.name] : e.target.value, InvoiceNoClass: 'form-control', AmountClass: 'form-control'} );
    }

    drop(files) {
        
        const data = new FormData();
        
        var authuser = this.props.authuser;
    
        files.forEach((file) => {
            data.append(`file[]`, file, file.name);
        });

        data.append('path', `${config.UPLOAD_PATH}/uploads/invoices/`);        
    
        axios.post(
            config.BASE_URL + "/api/uploadinvoice", 
            data , 
            { 
                headers: { 
                    "Content-Type": "application/json",
		            'Accept': 'application/json',
		            "Authorization" : 'Bearer ' + authuser.token
                 }
            } 
        )
        .then(res => {

            const data = res.data;
            
            this.setState( { File: data.file, uploaded: data.uploaded} );
        });    
    }

    render() {

        const projectSelected = this.state.ProjectSelected;

        const projects = this.props.projects.map((project) => {
            return (
                <li onClick={ () => this.selectProject(project.JobId, project.ProjectName) }>
                    <span className={projectSelected.indexOf(project.JobId) >= 0 ? 'selected' : ''}></span>
                    {`${project.JobId} - ${project.ProjectName}`}
                </li>
            )
        });

        const projectNames = this.props.projects.filter( (project) => {
            return (projectSelected.indexOf(project.JobId) >= 0); 
        });

        const pnames = projectNames.map( project => {
            return [`${project.JobId} - ${project.ProjectName}`];
        });
        
    

        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className='addInvoiceModal'
                    >
                    <Modal.Header closeButton>
                        Upload Invoice
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            
                            <Form.Group>
                                <Form.Label>Invoice No.</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="InvoiceNo"
                                    onChange={this.handleChange}
                                    required
                                />                                
                            </Form.Group>                            
                            
                            <Form.Group>

                                <Form.Label>Upload Invoice</Form.Label>

                                <Dropzone
                                    onDrop={this.drop}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="drag-n-drop text-center">
                                                Drag 'n' drop the invoice file here, or click to select the invoice file
                                                <div className="uploaded-invoice">{this.state.uploaded}</div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                            </Form.Group>

                            <Form.Group style={ {position: 'relative'} }>

                                <Form.Label>Project</Form.Label>

                                <div onClick={this.showProjectList} className="project-list">{pnames.length > 0  ? pnames.join(", ") : 'No project selected'}</div>

                                <ul className={this.state.projectClass}>
                                    {projects}     
                                </ul>

                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control 
                                    type="number" 
                                    name="Amount"
                                    value={this.state.Amount}
                                    required
                                    onChange={this.handleChange}
                                />                                
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    name="Notes"
                                    onChange={this.handleChange}
                                    rows="2" 
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control 
                                    as="select"
                                    name="Status"
                                    onChange={this.handleChange}
                                >
                                    <option value="Not Paid">Not Paid</option>
                                    <option value="Paid">Paid</option>
                                </Form.Control>
                            </Form.Group>
                            <Button className="btn purchasebtn" type="submit">Save</Button>
                        </Form>
                    </Modal.Body>
                    
                </Modal>
            </>
        )
    }
}

export default Invoice