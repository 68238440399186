import React, { Component } from 'react';
import { withRouter } from 'react-router';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Dropzone, {useDropzone} from 'react-dropzone'
import axios from 'axios';

import swal from "sweetalert";

const styles = {
	position: "relative",
	padding: "4px 15px",
	fontSize: "16px",
	border: "1px solid rgb(249, 250, 250)",
	background: "#EEE",
	cursor: "pointer",
	borderRadius: "5px"
};

class UploadImage extends Component {
    constructor(props) {
        super(props);
    }

	onDrop = (acceptedFiles) =>{
		const data = new FormData();
		acceptedFiles.forEach((file) => {
			const reader = new FileReader()

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onload = (e) => {

				const binaryStr = e.target.result
				this.setState({
					fileData: binaryStr
				});
			}
			reader.readAsDataURL(file);

			//upload image
			data.append('folder', config.UPLOAD_PATH);
			data.append(`myimages[]`, file, file.name);
			axios.post(config.BASE_URL + "/api/uploadfrontimage", data, { } )
			.then(res => {
				this.props.onDropImage(res.data.filename || '', this.props.tipTarget)
			})
		})
	}

	onDrop = (acceptedFiles) =>{
		const data = new FormData();
		acceptedFiles.forEach((file) => {
			const reader = new FileReader()

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onload = (e) => {

				const binaryStr = e.target.result
				this.setState({
					fileData: binaryStr
				});
			}
			reader.readAsDataURL(file);

			//upload image
			data.append('folder', config.UPLOAD_PATH);
			data.append(`myimages[]`, file, file.name);
			axios.post(config.BASE_URL + "/api/uploadfrontimage", data, { } )
			.then(res => {
				this.props.onDropImage(res.data.filename || '', this.props.tipTarget)
			})
		})
	}
	onDeleteIcon = (ev) =>{
		ev.preventDefault()
		this.props.onDeleteIcon(this.props.tipTarget)
	}
  render() {
    const previewStyle = {
		display: 'inline',
		width: 100,
		height: 100,
    }
	const {defaultImages} = this.props
	console.log('this.props', this.props)
	var defaultImage = (defaultImages.hasOwnProperty(this.props.tipTarget)) ? defaultImages[this.props.tipTarget] : ''
	//console.log('defaultImage' , defaultImage)
    return (
		<>
		{defaultImage ? (
		<a href="" className="imagetips-delete-btn" onClick={(event) => this.onDeleteIcon(event)}><i className="fa fa-times"></i></a>
		) : ('')}
          <Dropzone
            onDrop={this.onDrop}
			multiple={true}
          >
            {({getRootProps, getInputProps}) => (
            <div className="menus-dropdown-dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="drag-n-drop text-center">
			  {defaultImage ? (
			  <>
				<img src={config.UPLOAD_URL + defaultImage} />
				</>
			  ) : (
				<i className="fa fa-image" /> )
			  }
			  Drag 'n' drop some files here, or click to select files</div>
            </div>
            )}
          </Dropzone>

		</>
	)
  }

}

class ImageTips extends Component {
    constructor(props) {
        super(props);
		var authuser = JSON.parse(localStorage.getItem('authuser'));
		//console.log('authuser', authuser);
				this.state = {
					AuthUser: (authuser) ? authuser[0] : [],
					imageTips: []
        };
        this.getImageTips = this.getImageTips.bind(this)
    }

    componentWillMount() {
        this.getImageTips();
    }

	onDropImage =(file, target) =>{
		var imageTips = this.state.imageTips
		imageTips[target] = file
		this.setState({
			imageTips: imageTips
		}, () => {
			//console.log(this.state)
		})
	}

	handleChangeText = (e, type) => {
		console.log('name', e.target.name)
		console.log('value', e.target.value)
		console.log('type', type)

		const regex = /\ssrc=(?:(?:'([^']*)')|(?:"([^"]*)")|([^\s]*))/gm
		let m = regex.exec(e.target.value)
		var extractSrc = (m) ? m[1] : e.target.value

		var imageTips = this.state.imageTips

		const oldObj = ('object' === typeof(imageTips[e.target.name])) ? imageTips[e.target.name] : {}
		oldObj[type] = (extractSrc) ? extractSrc : e.target.value
		imageTips[e.target.name] = oldObj
		this.setState({
			imageTips: imageTips
		}, () => {
			console.log('this.state', this.state)
		})

	}

	onDeleteIcon = (target) =>{
		var imageTips = this.state.imageTips
		imageTips[target] = ''
		this.setState({
			imageTips: imageTips
		}, () => {

		})
	}

    getImageTips() {
		var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/getimagetips', {
            method: "GET",
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
        })
		.then(data => data.json())
		.then(data => {
			this.setState({
				imageTips: data.imageTips
			})
		})
		.catch(error => console.log(error));
    }

	handleFormSubmit = () =>{
		var authuser = this.state.AuthUser;
		var data = {
			imageTips: this.state.imageTips
		};
        fetch(config.BASE_URL + '/api/updateimagetips', {
            method: "POST",
			body: JSON.stringify(data),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
        })
		.then(data => data.json())
		.then(data => {
			swal('', 'Update successful', 'success');
		})
		.catch(error => console.log(error));
	}

    render() {

        const { imageTips } = this.state
				console.log('imageTips', imageTips)
        return (

            <div className="admin-wrapper">
				<HeaderAdmin />
                <div className="dashboard-wrapper partscontent">
                    <div className="dashboardhome">
                        <div className="container-fluid dashboard-content jobspagecnt">
                            {/* ============================================================== */}
                            {/* pagecontent  */}
                            {/* ============================================================== */}
							<div className="row">
                            <div className="col-md-12">
                                <ol className="breadcrumb breadm">
                                    <li><a href="/admin">Admin</a></li>
                                    <li ><i className="fa fa-angle-right midarrow" /><a style={{ cursor: "pointer" }}>Video Tips</a></li>
                                </ol>
                            </div>
							</div>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										<form className="ImageTips-Form">


                      <Tabs defaultIndex={0}>
                        <TabList>
                            <Tab>Dimension</Tab>
                            <Tab>Materials</Tab>
							<Tab>Door Options</Tab>
							<Tab>Drawer Options</Tab>
							<Tab>Shelves Options</Tab>
                        </TabList>
                        <TabPanel>
							<div className="col-sm-2">
								<h4>Cabinet Height</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.CabinetHeight && imageTips.CabinetHeight.video ? imageTips.CabinetHeight.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="CabinetHeight" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.CabinetHeight && imageTips.CabinetHeight.text ? imageTips.CabinetHeight.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="CabinetHeight">
								 </textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'CabinetHeight'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4>Cabinet Width</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.CabinetWidth && imageTips.CabinetWidth.video ? imageTips.CabinetWidth.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="CabinetWidth" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.CabinetWidth && imageTips.CabinetWidth.text ? imageTips.CabinetWidth.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="CabinetWidth"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'CabinetWidth'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4>Cabinet Depth</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.CabinetDepth && imageTips.CabinetDepth.video ? imageTips.CabinetDepth.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="CabinetDepth" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.CabinetDepth && imageTips.CabinetDepth.text ? imageTips.CabinetDepth.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="CabinetDepth"> </textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'CabinetDepth'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							{/*
							<div className="col-sm-2">
								<h4>Kicker Recess</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.KickerRecess && imageTips.KickerRecess.video ? imageTips.KickerRecess.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="KickerRecess" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<input className="form-control" defaultValue={imageTips.KickerRecess && imageTips.KickerRecess.text ? imageTips.KickerRecess.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="KickerRecess" />
							</div>
							*/}
							<div className="col-sm-2">
								<h4>Kicker Height</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.KickerHeight && imageTips.KickerHeight.video ? imageTips.KickerHeight.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="KickerHeight" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.KickerHeight && imageTips.KickerHeight.text ? imageTips.KickerHeight.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="KickerHeight"></textarea>
							</div>
							<div className="col-sm-2">
								<h4>Panel/Filler Height</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.PanelHeight && imageTips.PanelHeight.video ? imageTips.PanelHeight.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="PanelHeight" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.PanelHeight && imageTips.PanelHeight.text ? imageTips.PanelHeight.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="PanelHeight"></textarea>
							</div>
							<div className="col-sm-2">
								<h4>Panel/Filler Width</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.PanelWidth && imageTips.PanelWidth.video ? imageTips.PanelWidth.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="PanelWidth" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.PanelWidth && imageTips.PanelWidth.text ? imageTips.PanelWidth.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="PanelWidth"></textarea>
							</div>

                        </TabPanel>

                        <TabPanel>
							<div className="col-sm-2">
								<h4>Interior Material</h4>
								<p>Video Url:</p>
								<textarea
								value={imageTips.InteriorMaterial && imageTips.InteriorMaterial.video ? imageTips.InteriorMaterial.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="InteriorMaterial" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.InteriorMaterial && imageTips.InteriorMaterial.text ? imageTips.InteriorMaterial.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="InteriorMaterial"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'InteriorMaterial'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4> Exterior Material</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.ExteriorMaterial && imageTips.ExteriorMaterial.video ? imageTips.ExteriorMaterial.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="ExteriorMaterial" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.ExteriorMaterial && imageTips.ExteriorMaterial.text ? imageTips.ExteriorMaterial.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="ExteriorMaterial"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'ExteriorMaterial'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>

                        </TabPanel>
                        <TabPanel>
							<div className="col-sm-2">
								<h4>Door Hinging</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.DoorHinging && imageTips.DoorHinging.video ? imageTips.DoorHinging.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="DoorHinging" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.DoorHinging && imageTips.DoorHinging.text ? imageTips.DoorHinging.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="DoorHinging"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'DoorHinging'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4>Door Gap Top</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.DoorGapTop && imageTips.DoorGapTop.video ? imageTips.DoorGapTop.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="DoorGapTop" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.DoorGapTop && imageTips.DoorGapTop.text ? imageTips.DoorGapTop.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="DoorGapTop"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'DoorGapTop'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4>Door Gap Bottom</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.DoorGapBottom && imageTips.DoorGapBottom.video ? imageTips.DoorGapBottom.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="DoorGapBottom" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.DoorGapBottom && imageTips.DoorGapBottom.text ? imageTips.DoorGapBottom.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="DoorGapBottom"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'DoorGapBottom'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4>Door Gap Left</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.DoorGapLeft && imageTips.DoorGapLeft.video ? imageTips.DoorGapLeft.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="DoorGapLeft" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.DoorGapLeft && imageTips.DoorGapLeft.text ? imageTips.DoorGapLeft.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="DoorGapLeft"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'DoorGapLeft'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4>Door Gap Right</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.DoorGapRight && imageTips.DoorGapRight.video ? imageTips.DoorGapRight.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="DoorGapRight" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.DoorGapRight && imageTips.DoorGapRight.text ? imageTips.DoorGapRight.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="DoorGapRight"></textarea>
							</div>
							<div className="col-sm-2">
								<h4>Harware Type</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.HarwareType && imageTips.HarwareType.video ? imageTips.HarwareType.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="HarwareType" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.HarwareType && imageTips.HarwareType.text ? imageTips.HarwareType.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="HarwareType"></textarea>
							</div>
                        </TabPanel>
                        <TabPanel>
							<div className="col-sm-2">
								<h4>Hardware</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.Hardware && imageTips.Hardware.video ? imageTips.Hardware.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="Hardware" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.Hardware && imageTips.Hardware.text ? imageTips.Hardware.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="Hardware"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'Hardware'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4>Drawer Gaps</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.DrawerGaps && imageTips.DrawerGaps.video ? imageTips.DrawerGaps.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="DrawerGaps"
								style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.DrawerGaps && imageTips.DrawerGaps.text ? imageTips.DrawerGaps.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="DrawerGaps"></textarea>
								{/*<UploadImage
									onDropImage={this.onDropImage}
									onDeleteIcon = {this.onDeleteIcon}
									tipTarget = {'DrawerGaps'}
									defaultImages = {this.state.imageTips}
								/> */}
							</div>
							<div className="col-sm-2">
								<h4>Equal Drawer Qty</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.EqualDrawer && imageTips.EqualDrawer.video ? imageTips.EqualDrawer.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="EqualDrawer" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.EqualDrawer && imageTips.EqualDrawer.text ? imageTips.EqualDrawer.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="EqualDrawer"></textarea>
							</div>
							<div className="col-sm-2">
								<h4>Small Drawer Qty</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.SmallDrawer && imageTips.SmallDrawer.video ? imageTips.SmallDrawer.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="SmallDrawer" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.SmallDrawer && imageTips.SmallDrawer.text ? imageTips.SmallDrawer.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="SmallDrawer"></textarea>
							</div>
							<div className="col-sm-2">
								<h4>Inner Drawer Qty</h4>
								<p>Video Url</p>
								<textarea
								value={imageTips.InnerDrawer && imageTips.InnerDrawer.video ? imageTips.InnerDrawer.video : ''}
								onChange={(event) => this.handleChangeText(event, 'video')}
								name="InnerDrawer" style={{height: '65px', width: '100%'}}>
								</textarea>
								<p>Text Tip:</p>
								<textarea className="form-control" value={imageTips.InnerDrawer && imageTips.InnerDrawer.text ? imageTips.InnerDrawer.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="InnerDrawer"></textarea>
							</div>
                        </TabPanel>
												<TabPanel>
												<div className="col-sm-2">
												<h4>Fix Shelves</h4>
												<p>Video Url</p>
												<textarea
												value={imageTips.FixedShelves && imageTips.FixedShelves.video ? imageTips.FixedShelves.video : ''}
												onChange={(event) => this.handleChangeText(event, 'video')}
												name="FixedShelves" style={{height: '65px', width: '100%'}}>
												</textarea>
												<p>Text Tip:</p>
												<textarea className="form-control" value={imageTips.FixedShelves && imageTips.FixedShelves.text ? imageTips.FixedShelves.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="FixedShelves"></textarea>
												</div>
												<div className="col-sm-2">
												<h4>Adjustable Shelves</h4>
												<p>Video Url</p>
												<textarea
												value={imageTips.AdjustableShelves && imageTips.AdjustableShelves.video ? imageTips.AdjustableShelves.video : ''}
												onChange={(event) => this.handleChangeText(event, 'video')}
												name="AdjustableShelves" style={{height: '65px', width: '100%'}}>
												</textarea>
												<p>Text Tip:</p>
												<textarea className="form-control" value={imageTips.AdjustableShelves && imageTips.AdjustableShelves.text ? imageTips.AdjustableShelves.text : ''} onChange={(event) => this.handleChangeText(event, 'text')} type="text" name="AdjustableShelves"></textarea>

												</div>
												</TabPanel>
                      </Tabs>
					  <div style={{float: 'none', clear: 'both', height: '20px'}}></div>

							<div className="form-group">
								<button type="button" className="btn btn-primary" onClick={this.handleFormSubmit.bind(this)}>
													Save
								</button>
							</div>


						</form>
                                </div>
                            </div>
                            {/* ============================================================== */}
                            {/* end pagecontent  */}
                            {/* ============================================================== */}
                        </div>
                    </div>
                    <FooterAdmin />
                </div>
            </div>
        )
    }
}
export default withRouter(ImageTips);
