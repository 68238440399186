import React, { Component } from 'react';

/* import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'; */

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip'

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import swal from "sweetalert";
//import Excel from './Excel';
import { CSVLink } from "react-csv";
import { strings } from '../components/strings';

class Jobs extends Component {

	constructor(props) {
        super(props);

		var user = JSON.parse(localStorage.getItem('authuser'));

        this.state = {
			user: (user) ? user[0] : [],
            jobs: [],
            hoverIdx: null,
            expanded: [],
			comments: [],
			searchtext: '',
			NewComment: '',
			page: 1,
			sizePerPage: 10,
			totalrecords: 0,
			JobId: '',
            Job: {},
            jobList: [],
            productsList: [],
            materialList: [],
            csvProductsList: [],
            totalMaterialsList: [],
            jobModal: false,
            clientName: "",
            jobStatus:"",
			count: 0,
			sort: [],
            quoationModal: false,
			downloadOrder: false,
			showStatus: 'all',
            allClass: 'active',
            pendingClass: '',
            inProgressClass: '',
			completedClass: '',
			customize: false,
            payByAccount: false,
            payByAccountClass: 'pay-by-account-button',
			columnWidth: [100, 100, 200, 200, 150, 150, 150, 100]

		}

		this.commentText = React.createRef();
		this.header_ref_1 = React.createRef();
		this.header_ref_2 = React.createRef();

		this.options = this.options.bind(this);
		this.expandComponent = this.expandComponent.bind(this);

		this.onSortChange = this.onSortChange.bind(this);
		this.generateCSV = this.generateCSV.bind(this);
		//this.submitComplete = this.submitComplete.bind(this);

		this.showAll = this.showAll.bind(this);
        this.showPending = this.showPending.bind(this);
        this.showInProgress = this.showInProgress.bind(this);
        this.showCompleted = this.showCompleted.bind(this);
		this.showPayByAccount = this.showPayByAccount.bind(this);
		this.showCustomizedOrder = this.showCustomizedOrder.bind(this);
		this.formatCurrency = this.formatCurrency.bind(this);
		this.previewOrder = this.previewOrder.bind(this);
	}

	previewOrder(jobid) {
			window.open(`/admin/view-order/${jobid}`, 'view-order', 'width=900 height=900 scrolls resizable');
	}
	
	showCustomizedOrder() {

		this.setState( { customize: true } );

	}

	formatCurrency(money){
		var formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		return formatter.format(money);
	}

	showPayByAccount() {

        const status = this.state.payByAccount;

        if (status) {

            this.setState( { payByAccount: false, payByAccountClass: 'pay-by-account-button', customize: false} );

        } else {

            this.setState( { payByAccount: true, payByAccountClass: 'pay-by-account-button active', customize: false } );

        }

    }

    showAll() {
        this.setState( { showStatus: 'all', allClass: 'active', pendingClass: '', inProgressClass: '', completedClass: '', customize: false } );
    }

    showPending() {
        this.setState( { showStatus: 'pending', allClass: '', pendingClass: 'active', inProgressClass: '', completedClass: '', customize: false } );
    }

    showInProgress() {
        this.setState( { showStatus: 'in-progress', allClass: '', pendingClass: '', inProgressClass: 'active', completedClass: '', customize: false } );
    }

    showCompleted() {
        this.setState( { showStatus: 'completed', allClass: '', pendingClass: '', inProgressClass: '', completedClass: 'active', customize: false } );
    }

	onSortChange(name, order) {
		const data = {sortField: name, sortOrder: order}
		localStorage.setItem("sort", JSON.stringify(data));
	}

	generateCSV(data){
		var subArray = []
		var array = []
		var csvRow = {}

		data.jobProduct.map((record, key) => {

			if (record.ProductType === 'accessories') {
				//return false
			}

			const aestTime = new Date(data.PaidDateTime).toLocaleString("en-AU", {timeZone: "Australia/Perth"}).replace(/:\d{2}\s/,' ');

			//console.log('record.DoorData', record.DoorData)

			var HingeType = record.hingeData && record.hingeData.HingeType ? record.hingeData.HingeType : ''
			csvRow.JobProductId = record.JobProductId
			csvRow.ProductStockCode = record.StockCode
			csvRow.Width = record.Width
			csvRow.Height = record.Height
			csvRow.Depth = record.Depth
			csvRow.SellerName = 'Flat Packs Australia'
			csvRow.HingeType = HingeType === 'Left' ? HingeType = 'L' : HingeType === 'Right' ? HingeType = 'R' :
			HingeType === 'Top' ? HingeType= 'T' : HingeType = 'B'
			csvRow.Quantity = record.ProductType === 'accessories' ? record.fixedQty : 1

			var comments = ` ProjectName: ${data.ProjectDesc} ClientName: ${data.ClientName} ContactNo: ${data.ContactNo} Email: ${data.Email} InvoiceAddress: ${data.InvoiceAddress} ShippingAddress: ${data.ShippingAddressLine1} PaidAmount: ${this.formatCurrency(data.PaidAmount)} PaymentMethod: ${data.PaymentMethod} PaidDateTime: ${aestTime} `

			comments += ` ProductName: ${record.ProductName} `
			if (record.Weight && record.Weight != 'null') {
				comments += ` Weight: ${record.Weight}Kg `
			}
			if (data.pickupOption != '') {
				comments += ` PickupOption: ${data.pickupOption} `
			}else{
				var shippingCost = data.shippingCost || 0
				comments += ` DeliveryCost: ${this.formatCurrency(shippingCost)} `
			}

			if (record.ProductType != 'accessories') {

				if (record.ProductType === 'panels') {
					comments += ` NoShortEdges: ${data.noShortEdges} NoLongEdges: ${data.noLongEdges}`
				}

				if (record.has_kicker == '1') {
					comments += ` KickerHeight: ${record.ToeHeight} `
				}

				if (record.hasDoor == '1') {
					comments += ` NoOfDoors: ${record.NoOfDoors} `
					comments += ` DoorStyle: ${record.DoorData.DoorName} `
					comments += ` GapTop: ${record.DoorData.GapTop} `
					comments += ` GapBottom: ${record.DoorData.GapBottom} `
					comments += ` GapLeft: ${record.DoorData.GapLeft} `
					comments += ` GapRight: ${record.DoorData.GapRight} `
					comments += ` DoorHardware: ${record.DoorhardwareData.HardwareName}`
					comments += ` HingeType: ${record.hingeData.HingeType}`
					comments += ` DoorCost: ${this.formatCurrency(record.DoorFinalCost)} `
					comments += ` DoorHingeCost: ${this.formatCurrency(record.TotalHingeCost)} `
				}
				if (record.hasDrawer == '1') {
					comments += ` DrawerEqual: ${record.DrawerEqual} `
					comments += ` DrawerSmall: ${record.DrawerSmall} `
					comments += ` DrawerInner: ${record.DrawerInner} `
					comments += ` DrawerGaps: ${record.drawerGaps} `

					if (record.DrawerSize !== '') {
						try{
							var DrawerSize = JSON.parse(record.DrawerSize)
							if (DrawerSize.equal) {
								comments += ` EqualDrawerRunner: ${DrawerSize.equal.runnerheight} `
								comments += ` EqualDrawerFace: ${DrawerSize.equal.faceheight} `
							}
							if (DrawerSize.small) {
								comments += ` SmallDrawerRunner: ${DrawerSize.small.runnerheight} `
								comments += ` SmallDrawerFace: ${DrawerSize.small.faceheight} `
							}
							if (DrawerSize.inner) {
								comments += ` InnerDrawerRunner: ${DrawerSize.inner.runnerheight} `
								comments += ` InnerDrawerFace: ${DrawerSize.inner.faceheight} `
							}
						}catch(err) {}
					}

					comments += ` DrawerHardware: ${record.DrawerhardwareData.HardwareName} `
					comments += ` DrawerCost: ${this.formatCurrency(record.DrawerCost)} `
				}
				if (record.hasShelve == '1') {
					comments += ` FixedShelves: ${record.fixedQty} `
					comments += ` AdjustableShelves: ${record.adjustableQty} `
					comments += ` ShelveCost: ${this.formatCurrency(record.ShelvesCost)} `
				}
				if (record.ProductType != 'panels') {
					comments += ` CarcaseCost: ${this.formatCurrency(record.CarcaseFinalCost)} `
				}

				comments += ` HardwareCost: ${this.formatCurrency(record.HardwareCost)} `
				comments += ` TotalProductCost: ${this.formatCurrency(record.Price)} `

				if (record.InteriorMaterial && record.materialData && record.materialData[0]) {
					comments += ` InteriorMaterial: ${record.materialData[0].MaterialType} InteriorColour: ${record.materialData[0].ColourName} InteriorBrand: ${record.materialData[0].BrandName} InteriorSubstrate: ${record.materialData[0].SubstrateName} InteriorFinish: ${record.materialData[0].FinishName}`
				}
				if (record.ExteriorMaterial && record.materialData && record.materialData[1]) {
					comments += ` ExteriorMaterial: ${record.materialData[1].MaterialType} ExteriorColour: ${record.materialData[1].ColourName} ExteriorBrand: ${record.materialData[1].BrandName} ExteriorSubstrate: ${record.materialData[1].SubstrateName} ExteriorFinish: ${record.materialData[1].FinishName}`
				}

				if (record.details != '') {
					comments += ` Details: ${record.details} `
				}
			}

			if (record.ProductType === 'accessories') {
				var TotalPrice = parseFloat(record.HardwareCost) * parseFloat(record.fixedQty)
				comments += ` TotalPrice: ${this.formatCurrency(TotalPrice)}  DisplayName: ${record.displayname}  ProductDescription: ${record.ProductDescription} `
			}

			csvRow.Comments = comments;
			subArray.push(Object.values(csvRow))
			//return record;
		})

		//console.log('subArray', subArray)


			if (subArray.length) {

				array.push(...subArray);

				this.setState({
						csvProductsList: array,
						clientName: data.ClientName,
						JobId: data.JobId
				}, () => {
						document.getElementById("csv-download").click()
				})
			}
	}

	downloadCSV = (event, JobId, clientName) => {
		event.preventDefault()
		const data = {
			JobId: JobId
		}
		var user = this.state.user
		fetch(config.BASE_URL + '/api/getreport', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + user.Token
			}
		})
		.then(resp => resp.json())
		.then(resp => {
			this.generateCSV(resp.data);
		})
		.catch(error => console.log(error));

/*
        this.setState({ jobModal: false, quoationModal: false, clientName: clientName, JobId: JobId }, () => {
            fetch(config.BASE_URL + `/api/getProductListOfSelectedJob/${JobId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(data => data.json())
                .then(data => {
                    this.setState({
                        csvProductsList: data,
                    }, () => {
						if (data.length == 0) {
							swal("", 'Project has no products to download', "error");
							return;
						}
                        data.map((productid, key) => {
                            this.getmaterials(productid.JobProductId, data.length, key)
                        })
                    })
                })
                .catch(error => console.log(error));
        })
				*/
	}

	getmaterials(productId, length, key) {
        fetch(config.BASE_URL + '/api/getMaterialListOfSelectedProduct/' + productId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(data => data.json())
            .then(data => {
                this.setState({
                    count: this.state.count + 1
                }, () => {
                    /* var length = this.state.csvProductsList.length */
				})

                data.map((mati, k) => {
					mati.productId = productId
					return mati;
                })

                this.state.materialList.push(data)
                // data.map((material)=>{
                if (length === this.state.count) {

					this.state.materialList.map((material) => {
						this.state.totalMaterialsList.push(...material)
						return material;
					})

                    let { csvProductsList, totalMaterialsList } = this.state
                    //console.log("lists",csvProductsList)
                    let len = csvProductsList.length
                    let array = []
                    if (csvProductsList && csvProductsList.length > 0) {
                        csvProductsList.map((record, key) => {
														console.log('record', record);
                            var comments = []

							comments.push(`Shelf Type : ${record.ShelveName} Shelf Quantity : ${record.NoOfShelves} Hinge Type : ${record.HingeType} Category : ${record.Category} Hardware : ${record.HardwareName}  Door Gaps : T${record.GapTop} B${record.GapBottom} L${record.GapLeft} R${record.GapRight} Details : ${record.details} Carcase Price : ${this.formatCurrency(record.MaterialCost)} Doors Price : ${this.formatCurrency(record.DoorsCost)} Shelves Price : ${this.formatCurrency(record.ShelvesCost)} Total Hardware Cost : ${this.formatCurrency(record.HardwareCost)} Total($) : ${this.formatCurrency(record.Price)} FixedQty : ${record.fixedQty} AdjustableQty : ${record.adjustableQty} EqualQty : ${record.equalQty} Details : ${record.details}`);

							/* comments.push("Shelf Type : " + record.ShelveName + "Shelf Quantity : " + record.NoOfShelves +" "+ "Hinge Type : " + record.HingeType +" "+ "Category : " + record.Category +" "+ "Hardware : " + record.HardwareName + " "+ "Door Gaps : " + "T" + record.GapTop +" "+ "B" + record.GapBottom +" "+ "L" + record.GapLeft +" "+ "R" + record.GapRight +" "+
							"Details : " + record.details + " " +
                            "Carcase Price : " + record.MaterialCost
                            +" "+
                            "Doors Price : " + record.DoorsCost
                            +" "+
                            "Shelves Price : " + record.ShelvesCost
                            +" "+
                            "Total Hardware Cost : " + record.HardwareCost
                            +" "+
                            "Total($) : " + record.Price
                            +" "+
                            "FixedQty : " + record.fixedQty
                            +" "+
                            "AdjustableQty : " + record.adjustableQty
                            +" "+
                            "EqualQty : " + record.equalQty
                            +" "+
                            "Details : " + record.details
							); */


                            totalMaterialsList.map((material, key1) => {
                                if (record.JobProductId === material.productId) {
                                    comments.push(`${material.Section} : ${material.materialtype} ${material.BrandName} ${material.FinishName} ${material.SubstrateName} ${material.ColourName}`)
								}
								return material;
                            })

							//console.log('record', record);

                            record.HingeType === 'Left' ? record.HingeType = 'L' : record.HingeType === 'Right' ? record.HingeType = 'R' :
                            record.HingeType === 'Top' ? record.HingeType = 'T' : record.HingeType = 'B'
                            record.ProductName =record["StockCode"]
                            record.DoorName = 'Flat Packs Australia'
                            delete record["Category"]
                            delete record["StockCodeStatusFlag"]
                            delete record["ShelveName"]
                            delete record["HardwareName"]
                            delete record["GapTop"]
                            delete record["GapBottom"]
                            delete record["GapLeft"]
                            delete record["GapRight"]
                            delete record["StockCode"]
                            delete record["MaterialCost"]
                            delete record["DoorsCost"]
                            delete record["ShelvesCost"]
                            delete record["HardwareCost"]
							delete record["Price"]
							delete record["details"]
							delete record["fixedQty"]
							delete record["adjustableQty"]
							delete record["equalQty"]

							var jobdata = this.state.jobs.filter(job => job.JobId === this.state.JobId);
							/* var jobdataArr = {}; */
							var commentsExtra = ''


							if (jobdata.length > 0) {

								var aestTime = new Date(jobdata[0].PaidDateTime).toLocaleString("en-AU", {timeZone: "Australia/Perth"}).replace(/:\d{2}\s/,' ');

								commentsExtra = ' ClientName : ' + jobdata[0].ClientName + ' ContactNo : ' + jobdata[0].ContactNo + ' Email : ' + jobdata[0].Email + ' Company : ' + jobdata[0].Company + ' InvoiceAddress : ' + jobdata[0].InvoiceAddress + ' ShippingAddress : ' + jobdata[0].ShippingAddressLine1 + ' PaidAmount : ' + jobdata[0].PaidAmount + ' PaymentMethod : ' + jobdata[0].PaymentMethod + ' PaidDateTime : ' + aestTime;
							}

                            record.comments = comments.join(" ") + commentsExtra;


                            var subArray=[]
                            subArray.push(Object.values(record))
                            array.push(...subArray);

                            len--
                            if (len === 0) {

                                this.setState({
                                    csvProductsList: array
                                }, () => {
                                    document.getElementById("csv-download").click()
                                    this.setState({
                                        count: 0,
                                        totalMaterialsList: [],
                                        materialList: []
                                    })
                                })
							}

							return record;

                        });

                    }
                    else {
                        alert("The products list is empty for your job")
                    }
                }
                // })
            })
            .catch(error => console.log(error));
    }

	options(cell, row) {

		return (
			<div className="row-buttons">
			{row.paymentStatus === 'succeeded' ? (
			<>
				<button onClick={(event) => this.previewOrder(row.JobId)} className="project-list-button" title="Preview Order"><i className="fas fa-eye" /></button>
				<button onClick={(event) => this.downloadOrder(event, row, row.JobId, row.ClientName)} className="project-list-button" title="Excel Download For Admin"><i className="fa fa-file-alt" /></button>
				<button onClick={(event) => this.downloadCSV(event, row.JobId, row.ClientName)} className="project-list-button"><i className="fa fa-file-csv" /></button>

			</>
			) : ('')
			}
			</div>
        )
	}

	downloadOrder = (event, joblist, JobId, clientName) => {
		event.preventDefault()
		var user = this.state.user

		swal({
			title: "",
			text: strings.GeneratingReportMessage,
			icon: "",
			button: {
				text: "test",
				value: true,
				visible: false,
				closeModal: true
			}
		});

		var data = {
				type: 'admin',
				folder: config.UPLOAD_PATH,
				api_url: config.BASE_URL,
        frontend_url: config.UPLOAD_URL,
				logo: config.TOP_LOGO
		}

		fetch(config.BASE_URL + '/api/generateexcel/'+JobId, {
				body: JSON.stringify(data),
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					'Accept': 'application/json',
					"Authorization": 'Bearer ' + user.token
				}
		})
		.then(data => data.json())
		.then(data => {
			const link = document.createElement('a');
			//link.target = "_blank";
			link.href = data.data.fileurl;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			swal.close()
		})
		.catch(error => console.log(error));
    }

	downloadOrderClient = (event, joblist, JobId, clientName) => {
		event.preventDefault()
		var user = this.state.user

		swal({
			title: "",
			text: strings.GeneratingReportMessage,
			icon: "",
			button: {
				text: "test",
				value: true,
				visible: false,
				closeModal: true
			}
		});

		var data = {
				folder: config.UPLOAD_PATH,
				logo: config.TOP_LOGO
		}

		fetch(config.BASE_URL + '/api/generateclientexcel/'+JobId, {
				body: JSON.stringify(data),
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					'Accept': 'application/json',
					"Authorization": 'Bearer ' + user.token
				}
		})
		.then(data => data.json())
		.then(data => {
			const link = document.createElement('a');
			//link.target = "_blank";
			link.href = data.data.fileurl;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			swal.close()
		})
		.catch(error => console.log(error));
    }

    closeDownloadExcel(excel) {
        this.setState({
            downloadOrder: excel
        })
	}

	componentDidUpdate() {
		ReactTooltip.rebuild();
	}

	componentDidMount() {

		var user = this.state.user;

		fetch(config.BASE_URL + '/api/getjoblistlimit', {
			method: 'post',
			boody: [],
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + user.token
			}
		})
		.then(data => data.json())
		.then(data => {

			const priorities = data.data.filter( val => {
				return val.Status === 'Priority'
			});

			const ids = priorities.map( val => {
				return val.JobId;
			});

			const sort = localStorage.getItem("sort");

			this.setState({
				jobs : data.data,
				expanded: ids,
				sort: JSON.parse(sort),
				totalrecords: data.data.count
			}, () => {

				for (let index = 0; index < 10; index += 1) {

					if ($(`.react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

					$(`.react-bs-container-header thead th:eq(${index})`).resizable({
					  minWidth: 50,
					  handles: 'e',
					  resize: (__event, ui) => {
						const newColumnWidths = [...this.state.columnWidth];
						newColumnWidths[index] = ui.size.width;
						this.setState({ columnWidth: newColumnWidths });
					  },
					});
				}
			});

		})
		.catch(error => console.log(error));
	}

	isExpandableRow() {

		return true
    }

    expandComponent(row) {

        return (
            <>
                <ChatBox data={row} id={row.JobId} user={this.state.user} />
            </>
        );
    }


	render() {

		let hasCustomize = 0;

		let data = [];

		const { csvProductsList, clientName, JobId, jobs, payByAccount, showStatus, customize } = this.state;

		jobs.map( job => {

			if (job.customize === 1) {
				hasCustomize = 1;
			}

			return job;

		});

		if (customize) {

			data = jobs.filter( (job) => {

				return job.customize === 1

			});

		} else {

			data = jobs.filter( (job) => {

					return (
						(showStatus === 'all') ||
						(showStatus === 'pending' && job.ProjectStatus === 'Pending') ||
						(showStatus === 'in-progress' && job.ProjectStatus === 'In Progress') ||
						(showStatus === 'completed' && job.ProjectStatus === 'Completed')
					)
			});


			if ( payByAccount ) {

				data = data.filter( job => {

					return job.PaymentMethod === 'Pay By Account'

				});

			}

		}

		/* const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
			onRowClick: this.onRowClick,
			expanding: this.state.expanded,
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
			sizePerPage: 25,
			onSortChange : this.onSortChange,
			sortName: sort ? sort.sortField : '',
			sortOrder: sort ? sort.sortOrder : '',
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        data.length > 0
                            ? data.length
                            : 5
                }
            ]
		};

		const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.save
		}

		const columnWidth = this.state.columnWidth; */

		const columns = [
			{
				dataField: 'JobId',
				text: 'JobId',
				sort: true
			},
			{
				dataField: 'ProjectName',
				text: 'Project Name',
				sort: true
			},
			{
				dataField: 'ClientName',
				text: 'Client Name',
				sort: true
			},
			{
				dataField: 'Email',
				text: 'Email',
				sort: true,
			},
			{
				dataField: 'ContactNo',
				text: 'Contact',
				sort: true,
			},
			{
				dataField: 'TotalCost',
				text: 'Total Price',
				sort: true
			},
			{
				dataField: 'options',
				text: 'Option',
				formatter: this.options,
				headerAlign: 'center',
				align: 'center'
			}
		];

		const expandRow = {
			renderer: this.expandComponent ,

			showExpandColumn: true,

			expandByColumnOnly: false,

			expanded: this.state.expanded,

			expandHeaderColumnRenderer: () => {
				return "";
			},

			expandColumnRenderer: (expanded, rowKey, expandable) => {

				if (expanded.expanded)
				 	return <span className="fa fa-minus glyphicon glyphicon-minus" />;
				else
				 	return <span className="fa fa-plus glyphicon glyphicon-plus" />;
			},

			onExpandAll: (isExpandAll, results, e) => {
			  // ...
			}
		};

		const pagination = paginationFactory({
			page: 1,
		});

		const { SearchBar } = Search;

		const searchOptions = {
			defaultSearch: 0
		}

		return (

				<div className="admin-wrapper">
					<HeaderAdmin />
					<div className="dashboard-wrapper partscontent">
						<div className="dashboardhome">
							<div className="container-fluid dashboard-content jobspagecnt">

								<div className="row">
								<div className="col-md-12">
									<ol className="breadcrumb breadm">
										<li><a href="/admin">Admin</a></li>
										<li ><i className="fa fa-angle-right midarrow" /><a href="/admin/jobs" style={{ cursor: "pointer" }}>Projects</a></li>
									</ol>
								</div>
								</div>
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

										<div className="inner-pagehead">
											<h1>PROJECTS</h1>
										</div>

										<div className="jobs-table">

											<ToolkitProvider
												keyField='JobId'
												data={ data }
												columns={ columns }
												noDataIndication="Table is Empty"
												search = { searchOptions }
											>
											{
												props => (
													<div>
														<div className="row">
															<div className="col-md-5">
																<SearchBar { ...props.searchProps } />
																<button
																	onClick={this.showPayByAccount}
																	data-tip="Show Order Pay By Account"
																	className={this.state.payByAccountClass}>
																	<i className="fas fa-user-circle" />
																</button>

																{ hasCustomize == 1 ?

																		<button
																			onClick={this.showCustomizedOrder}
																			data-tip="Show Order with Customized Product"
																			className="customized-button">
																			<i className="fas fa-shopping-cart" />
																		</button>

																		:  ""
																}

															</div>
															<div className="col-md-7 text-right buttons">
																<button className={this.state.allClass} onClick={this.showAll}>All</button>
																<button className={this.state.pendingClass} onClick={this.showPending}>Pending</button>
																<button className={this.state.inProgressClass} onClick={this.showInProgress}>In Progress</button>
																<button className={this.state.completedClass} onClick={this.showCompleted}>Completed</button>
															</div>
														</div>
														<BootstrapTable
															{ ...props.baseProps }
															pagination={ pagination }
															expandRow={ expandRow }
														/>
													</div>
												)
											}
											</ToolkitProvider>


											{/* <BootstrapTable
                                                ref='table'
                                                data={ data }
                                                pagination={true}
                                                options={options}
                                                search={true}
                                                hover={true}
                                                cellEdit={cellEditProp}
                                                expandableRow={ this.isExpandableRow }
                                                expandComponent={ this.expandComponent }
                                                expandColumnOptions={ { expandColumnVisible: true } }
                                                trClassName={this.trClassFormat}>
                                                <TableHeaderColumn
                                                    dataField='JobId'
													dataSort={true}
													editable={false}
													width={columnWidth[1] + 'px'}
													tdStyle={{ cursor: "pointer", textAlign:"center"}}
													thStyle={{ textAlign:"center" }}
                                                    isKey={true}>
                                                        Job ID
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField='ProjectName'
													dataSort={true}
													editable={false}
													width={columnWidth[2] + 'px'}
													tdStyle={{ cursor: "pointer", textAlign:"center"}}
													thStyle={{ textAlign:"center"}}
													ref={this.header_ref_1}
                                                    >Project Name
                                                </TableHeaderColumn>

                                                <TableHeaderColumn
                                                    dataField='ClientName'
													dataSort={true}
													editable={false}
													width={columnWidth[3] + 'px'}
													tdStyle={{ cursor: "pointer", textAlign:"center" }}
													thStyle={{ textAlign:"center" }}
													ref={this.header_ref_2}
                                                    >Client Name
                                                </TableHeaderColumn>

												<TableHeaderColumn
                                                    dataField='Email'
													dataSort={true}
													editable={false}
													width={columnWidth[4] + 'px'}
													tdStyle={{ cursor: "pointer", textAlign:"center" }}
													thStyle={{ textAlign:"center" }}
                                                    >Email
                                                </TableHeaderColumn>

												<TableHeaderColumn
                                                    dataField='ContactNo'
													dataSort={true}
													editable={false}
													width={columnWidth[5] + 'px'}
													tdStyle={{ cursor: "pointer", textAlign:"center" }}
													thStyle={{ textAlign:"center" }}
                                                    >Contact
                                                </TableHeaderColumn>

												<TableHeaderColumn
                                                    dataField='TotalCost'
													dataSort={true}
													editable={false}
													width={columnWidth[6] + 'px'}
													tdStyle={{ cursor: "pointer", textAlign:"center" }}
													thStyle={{ textAlign:"center" }}
                                                    >Total Price
                                                </TableHeaderColumn>

												<TableHeaderColumn
													dataField='options'
													editable={false}
													tdStyle={{ cursor: "pointer", textAlign:"center"}}
													thStyle={{ textAlign:"center" }}
													width={columnWidth[7] + 'px'}
													dataFormat={ this.options }
                                                    >Option
                                                </TableHeaderColumn>

											</BootstrapTable>

											*/ }

											{/*<Excel joblist={this.state.joblist} downloadOrder={this.state.downloadOrder} closeDownload={this.closeDownloadExcel.bind(this)} />*/}

											<div style={{ display: "none" }}>
												<CSVLink id="csv-download" data={csvProductsList} filename={clientName + "-" + JobId + ".ord"} />
											</div>

										</div>

									</div>
								</div>

							</div>
						</div>
						<FooterAdmin />
					</div>
				</div>

		)

	}

}

/*
class Jobs extends Component {

    constructor(props) {
        super(props);

		var user = JSON.parse(localStorage.getItem('authuser'));
        this.state = {
			user: (user) ? user[0] : [],
            jobs: [],
            hoverIdx: null,
            expanded: [],
			comments: [],
			searchtext: '',
			NewComment: '',
			page: 1,
			sizePerPage: 10,
			totalrecords: 0,
			JobId: '',
            Job: {},
            jobList: [],
            productsList: [],
            materialList: [],
            csvProductsList: [],
            totalMaterialsList: [],
            jobModal: false,
            clientName: "",
            jobStatus:"",
            count: 0,
            quoationModal: false,
            downloadOrder: false

        }
		this.commentText = React.createRef();
        this.options = this.options.bind(this);

		//this.submitComplete = this.submitComplete.bind(this);

    }

    updateStatus = (JobId,Status) => {
        this.setState({
            JobId: JobId,
            jobStatus:Status,
            jobModal: true,
            quoationModal: false
        })
    }
    uploadQuotation = (Job) => {
        let JobId = Job.JobId
        this.setState({
            JobId,
            Job,
            jobModal: false,
            quoationModal: true
        })
    }

    downloadCSV = (event, JobId, clientName) => {
		event.preventDefault()
        this.setState({ jobModal: false, quoationModal: false, clientName: clientName, JobId: JobId }, () => {
            fetch(config.BASE_URL + `/api/getProductListOfSelectedJob/${JobId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(data => data.json())
                .then(data => {
                    this.setState({
                        csvProductsList: data,
                    }, () => {
						if (data.length == 0) {
							swal("", 'Project has no products to download', "error");
							return;
						}
                        data.map((productid, key) => {
                            this.getmaterials(productid.JobProductId, data.length, key)
                        })
                    })
                })
                .catch(error => console.log(error));
        })
    }

    getmaterials(productId, length, key) {
        fetch(config.BASE_URL + '/api/getMaterialListOfSelectedProduct/' + productId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(data => data.json())
            .then(data => {
                this.setState({
                    count: this.state.count + 1
                }, () => {
                    var length = this.state.csvProductsList.length
                })
                data.map((mati, k) => {
                    mati.productId = productId
                })

                this.state.materialList.push(data)
                // data.map((material)=>{
                if (length === this.state.count) {
                    this.state.materialList.map((material) => {
                        this.state.totalMaterialsList.push(...material)
                })
                    let { csvProductsList, totalMaterialsList } = this.state
                    //console.log("lists",csvProductsList)
                    let len = csvProductsList.length
                    let array = []
                    if (csvProductsList && csvProductsList.length > 0) {
                        csvProductsList.map((record, key) => {

                            var comments = []
							comments.push("Shelf Type : " + record.ShelveName + "Shelf Quantity : " + record.NoOfShelves +" "+ "Hinge Type : " + record.HingeType +" "+ "Category : " + record.Category +" "+ "Hardware : " + record.HardwareName + " "+ "Door Gaps : " + "T" + record.GapTop +" "+ "B" + record.GapBottom +" "+ "L" + record.GapLeft +" "+ "R" + record.GapRight +" "+
							"Details : " + record.details + " " +
                            "Carcase Price : " + record.MaterialCost
                            +" "+
                            "Doors Price : " + record.DoorsCost
                            +" "+
                            "Shelves Price : " + record.ShelvesCost
                            +" "+
                            "Total Hardware Cost : " + record.HardwareCost
                            +" "+
                            "Total($) : " + record.Price
                            +" "+
                            "FixedQty : " + record.fixedQty
                            +" "+
                            "AdjustableQty : " + record.adjustableQty
                            +" "+
                            "EqualQty : " + record.equalQty
                            +" "+
                            "Details : " + record.details
                            );
                            totalMaterialsList.map((material, key1) => {
                                if (record.JobProductId === material.productId) {
                                    comments.push(`${material.Section} : ${material.materialtype} ${material.BrandName} ${material.FinishName} ${material.SubstrateName} ${material.ColourName}`)
                                }
                            })

							//console.log('record', record);

                            record.HingeType === 'Left' ? record.HingeType = 'L' : record.HingeType === 'Right' ? record.HingeType = 'R' :
                            record.HingeType === 'Top' ? record.HingeType = 'T' : record.HingeType = 'B'
                            record.ProductName =record["StockCode"]
                            record.DoorName = 'Flat Packs Australia'
                            delete record["Category"]
                            delete record["StockCodeStatusFlag"]
                            delete record["ShelveName"]
                            delete record["HardwareName"]
                            delete record["GapTop"]
                            delete record["GapBottom"]
                            delete record["GapLeft"]
                            delete record["GapRight"]
                            delete record["StockCode"]
                            delete record["MaterialCost"]
                            delete record["DoorsCost"]
                            delete record["ShelvesCost"]
                            delete record["HardwareCost"]
							delete record["Price"]
							delete record["details"]
							delete record["fixedQty"]
							delete record["adjustableQty"]
							delete record["equalQty"]

							var jobdata = this.state.jobs.filter(job => job.JobId == this.state.JobId);
							var jobdataArr = {};
							var commentsExtra = ''


							if (jobdata.length > 0) {

								var aestTime = new Date(jobdata[0].PaidDateTime).toLocaleString("en-AU", {timeZone: "Australia/Perth"}).replace(/:\d{2}\s/,' ');

								commentsExtra = ' ClientName : ' + jobdata[0].ClientName + ' ContactNo : ' + jobdata[0].ContactNo + ' Email : ' + jobdata[0].Email + ' Company : ' + jobdata[0].Company + ' InvoiceAddress : ' + jobdata[0].InvoiceAddress + ' ShippingAddress : ' + jobdata[0].ShippingAddressLine1 + ' PaidAmount : ' + jobdata[0].PaidAmount + ' PaymentMethod : ' + jobdata[0].PaymentMethod + ' PaidDateTime : ' + aestTime;
							}

                            record.comments = comments.join(" ") + commentsExtra;


                            var subArray=[]
                            subArray.push(Object.values(record))
                            array.push(...subArray);

                            len--
                            if (len === 0) {

                                this.setState({
                                    csvProductsList: array
                                }, () => {
                                    document.getElementById("csv-download").click()
                                    this.setState({
                                        count: 0,
                                        totalMaterialsList: [],
                                        materialList: []
                                    })
                                })
                            }
                        });

                    }
                    else {
                        alert("The products list is empty for your job")
                    }
                }
                // })
            })
            .catch(error => console.log(error));
    }



    options(row) {
        return (
			<div className="row jobs-options"> &nbsp;&nbsp;
			{row.paymentStatus == 'succeeded' ? (
			<>
				<button onClick={(event) => this.downloadOrder(event, row, row.JobId, row.ClientName)} className="project-list-button"><i className="fa fa-file-alt" /></button>
				<button onClick={(event) => this.downloadCSV(event, row.JobId, row.ClientName)} className="project-list-button"><i className="fa fa-file-csv" /></button>

			</>
			) : ('')
			}
			</div>
        )
    }

	componentDidMount() {
		var user = this.state.user;

		//console.log('user', user);

		fetch(config.BASE_URL + '/api/getjoblistlimit', {
			body: JSON.stringify({limit: this.state.sizePerPage, page: this.state.page}),
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + user.token
			}
		})
		.then(data => data.json())
		.then(data => {

			const ids = data.data.map( val => {
				return val.JobId
			});

			this.setState({
				jobs : data.data,
				expanded: ids,
				totalrecords: data.count
			}, () => {
				ids.map( jobId => {
					this.getComments(jobId);
				})
			});

		})
		.catch(error => console.log(error));

		//setInterval(this.fetchLatestComments, 60000);

	}

    downloadOrder = (event, joblist, JobId, clientName) => {
		event.preventDefault()
		var user = this.state.user
        this.setState({
			generateXls: true,
			downloadReady: false
			/*
            joblist: joblist,
            jobModal: false,
            quoationModal: false,
            clientName: clientName,
            JobId: JobId,
            downloadOrder: true

		}, () => {
			var data = {
				folder: config.UPLOAD_PATH
			}

			fetch(config.BASE_URL + '/api/generateexcel/'+JobId, {
				body: JSON.stringify(data),
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					'Accept': 'application/json',
					"Authorization": 'Bearer ' + user.token
				}
			})
			.then(data => data.json())
			.then(data => {
				this.setState({
					generateXls: false,
					downloadReady: true
				}, () => {
					console.log('datadownload', data)
					const link = document.createElement('a');
					//link.target = "_blank";
					link.href = data.data.fileurl;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				});
			})
			.catch(error => console.log(error));
		})


    }

    closeDownloadExcel(excel) {
        this.setState({
            downloadOrder: excel
        })
    }

    handleOnExpand = (row, isExpand, rowIndex, e) => {
        const JobId = row.JobId;
		if (isExpand){
			this.setState(prevState => ({
				expanded: [JobId, ...prevState.expanded],
			}));

			this.getComments(JobId, () => {
				//console.log(this.state.expanded);
			});
		}else{
			this.setState({
				expanded: this.state.expanded.filter((item, i) => item !== JobId)
			}, () => {
				//console.log(this.state.expanded);
			})
		}

	}


	getCommentFromState = (JobId) => {
		var comments = this.state.comments;
		if (comments.length > 0) {
			var data = comments.filter(comment => comment.jobId == JobId);
			if (data.length > 0) {
				return {found: true, data: data}
			}else{
				return {found: false, data: []}
			}
		}else{
			return {found: false, data: []}
		}
	}

	async getComments (JobId) {
		var user = this.state.user;
            await fetch(config.BASE_URL + '/api/getcomments/'+JobId, {
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
					"Authorization": 'Bearer ' + user.token
                }
            })
            .then(data => data.json())
            .then(data => {
				var comments = this.state.comments;
				data.map(function(item) {
					var data = comments.filter(comment => comment.commentId == item.commentId);
					if (data.length == 0) {
						comments.push(item);
					}
				});

				this.setState({ comments: comments });
				return comments;
            })
            .catch(error => console.log(error));
			return [];
	}


	async searchJobs (keyword) {
		var user = this.state.user;
		await fetch(config.BASE_URL + '/api/searchjobs', {
			body: JSON.stringify({keyword: keyword, limit: this.state.sizePerPage, page: this.state.page}),
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + user.token
			}
		})
            .then(data => data.json())
            .then(data => {
				const ids = data.data.map( val => {
					return val.JobId
				});

				this.setState({
					jobs : data.data,
					expanded: ids,
					totalrecords: data.count
				}, () => {
					ids.map( jobId => {
						this.getComments(jobId);
					})
				});
            })
            .catch(error => console.log(error));

	}

	  handleTableChange = (type, { page, sizePerPage }) => {

		const currentIndex = (page - 1) * sizePerPage;

		console.log('table change', type);

		setTimeout(() => {
		  this.setState(() => ({
			page,
			sizePerPage
		  }));

			this.setState({
				page,
				sizePerPage
			}, () => {
				this.searchJobs(this.state.searchtext);
			})

		}, 100);
	  }


    render() {
	let input = null;

	const { jobs, sizePerPage, page } = this.state;

	const MyCustomSearch = (props) => {
	  let input;
	   const onSearchChange = (e) => {
			this.searchJobs(input.value);
			this.setState({searchtext: input.value});
	  };
	  return (
		<div className="customer-search-input">
			<DelayInput
				minLength={2}
				className="form-control"
				delayTimeout={800}
			  	inputRef={ n => input = n }
			  	value={this.state.searchtext}
				onChange={event => onSearchChange()}
				/>
		</div>
	  );
	};



        const columns =
            [
                {
                    dataField: 'JobId',
					text: 'Job Id',
					sort: true
                },
                {
                    dataField: 'ProjectName',
                    text: 'Project Name',
					style: {width: '100px'},
					headerStyle: {width: '100px'},
					sort: true
                },
                {
                    dataField: 'ClientName',
					text: 'client Name',
					sort: true
                },
                {
                    dataField: 'Email',
                    text: 'Email',
					style: {width: '100px'},
					headerStyle: {width: '100px'},
					sort: true
                },
                {
                    dataField: 'ContactNo',
					text: 'Contact',
					sort: true
                },
                {
                    dataField: 'TotalCost',
					text: 'Total Price $',
					sort: true
                },
                {
                    dataField: 'options',
                    align: 'center',
                    text: 'Options',
                    headerAlign: 'center'
                },
            ];



        const data = this.state.jobs.map( (job) => {

                    job.options = this.options(job);

                    return job
                }

            )

        const expandRow = {
                parentClassName: 'parent-expand',
                renderer: (row, rowIndex) => (

					<>
						<ChatBox id={row.JobId} data={row} user={this.state.user} />
					</>

                ),
                expanded: this.state.expanded,
                onExpand: this.handleOnExpand,
				showExpandColumn: true,
				expandByColumnOnly: true,
                expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                    if (isAnyExpands) {
                      return <i className="fa fa-minus" />;
                    }
                    return <i className="fa fa-plus" />;
                  },

                expandColumnRenderer: ({ expanded }) => {
                    if (expanded) {
                      return (
                        <i className="fa fa-minus" />
                      );
                    }
                    return (
                      <i className="fa fa-plus" />
                    );
                 }
              };


			const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
			  <div>
				<BootstrapTable
					noDataIndication="Loading Jobs..."
					remote
					keyField="JobId"
					data={ data }
					columns={ columns }
					pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
					onTableChange={ onTableChange }
					expandRow = { expandRow }
					onExpand = {this.handleOnExpand}
				/>
			  </div>
			);

			const { jobList, csvProductsList,headers, clientName, JobId } = this.state

			return (
				<div className="admin-wrapper">
					<HeaderAdmin />
					<div className="dashboard-wrapper partscontent">
						<div className="dashboardhome">
							<div className="container-fluid dashboard-content jobspagecnt">

								<div className="row">
								<div className="col-md-12">
									<ol className="breadcrumb breadm">
										<li><a href="/admin">Admin</a></li>
										<li ><i className="fa fa-angle-right midarrow" /><a style={{ cursor: "pointer" }}>Projects</a></li>
									</ol>
								</div>
								</div>
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

										<div className="inner-pagehead">
											<h1>PROJECTS</h1>
										</div>
										  <div className="jobs-table">
											<div className="col-sm-8"></div>
											<div className="col-sm-4">
											<MyCustomSearch />
											</div>

											  <RemotePagination
												data={ data }
												page={ page }
												search={ {searchFormatted: true}}
												sizePerPage={ sizePerPage }
												totalSize={this.state.totalrecords}
												onTableChange={ this.handleTableChange }
											  />
											<Excel joblist={this.state.joblist} downloadOrder={this.state.downloadOrder} closeDownload={this.closeDownloadExcel.bind(this)} />
											<div style={{ display: "none" }}>
												<CSVLink id="csv-download" data={csvProductsList} filename={clientName + "-" + JobId + ".csv"} />
											</div>
										  </div>

									</div>
								</div>

							</div>
						</div>
						<FooterAdmin />
					</div>
				</div>
			)
    }
}
*/

class ChatBox extends Component {

	constructor(props) {
		super(props);

		this.state = {
			id: props.id,
			data: props.data,
			user: props.user,
			NewComment: '',
			status: props.data.Status,
			pStatus: props.data.ProjectStatus,
			comments: []
		}

		this.submitComment = this.submitComment.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.renderComments = this.renderComments.bind(this);

		this.MsRef = React.createRef();
	}

	renderComments(comments) {

		let commentHTML = '';

		//const comments = this.state.comments;

		//console.log('render comment', comments);

		//var comments = this.getCommentFromState(jobId);

		if (comments.length > 0){
			commentHTML = comments.map( item => {
				return (
					<dl key={item.commentId} className="job-comments-wrapper">
					  <dt><b className="comment_name">{item.senderName} &nbsp;</b> <span className="comment_date">{item.createdDate}</span> &nbsp; : &nbsp; &nbsp; </dt>
					  <dd><div className="comment_text">{item.commentText}</div></dd>
					</dl>
				)
			});
		}

		return commentHTML;

	}

	formatIndicator(status) {
		//console.log(jstatus);
		var indColor = (status == 'Pending') ? 'statusCircle Pending' : (status == 'Completed') ? 'statusCircle Completed' : (status == 'Priority') ? 'statusCircle Priority' : 'statusCircle Other';
		return (
			<div className={indColor} style={ { left: '18px' } } />
		)
	}
	handleInputChange = (jobId, value, name) => {
		this.setState( {
			NewComment: value
		})
	};

	submitComplete = (row) => {

		var user = this.state.user;

		//console.log(this.state.user);

		var data = {
			projectId: (row.ProjectId) ?  row.ProjectId : 0,
			commentText: this.state.NewComment,
			senderId: user.Id,
			senderName: user.Name,
			receiverId: (row.CustomerId) ? row.CustomerId : 0,
			receiverName: row.ClientName
		}

		this.setState( { status: 'Completed' } );

		fetch(config.BASE_URL + '/api/jobcomplete/'+row.JobId, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + user.token
			}
		})
		.then(data => data.json())
		.then(data => {



			if (data.length > 0){

				let comments = this.state.comments;

				comments.push(data[0]);

				this.setState({
					comments: comments,
					NewComment: '',
				}, () => {
					this.MsRef.current.scrollTop = this.MsRef.current.scrollHeight;
					console.log('this state', this.state);
				})
			}
		})
		.catch(error => console.log(error));

	}

	submitComment = (row) => {

		//console.log('submitting', this.state);

		if (this.state.NewComment == '') return;

		var user = this.state.user;

		var commentdata = {
			jobId: row.JobId,
			projectId: (row.ProjectId) ?  row.ProjectId : 0,
			commentText: this.state.NewComment,
			senderId: user.Id,
			senderName: user.Name,
			receiverId: (row.CustomerId) ? row.CustomerId : 0,
			receiverName: row.ClientName,
			sender: 'admin',
			logo: config.UPLOAD_PATH + config.TOP_LOGO,
			appurl: config.UPLOAD_URL
		}


		this.setState( { NewComment: '' } );

		fetch(config.BASE_URL + '/api/addcomment', {
			method: 'POST',
			body: JSON.stringify(commentdata),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + user.token
			}
		})
		.then(data => data.json())
		.then(data => {

			let comments = this.state.comments;

			comments.push(data[0]);

			this.setState(  {comments: comments, status : this.state.pStatus} );

			this.MsRef.current.scrollTop = this.MsRef.current.scrollHeight;


		})
		.catch(error => console.log(error));

	}

	fetchLatestComments(){
		fetch(config.BASE_URL + '/api/getlatestcommentsByJobId/' + this.state.id, {
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			}
		})
		.then(data => data.json())
		.then(data => {
			//console.log('fetch', data);
			if (data.length > 0) {
				this.setState({
					comments: data,
					NewComment: ''
				});

				this.MsRef.current.scrollTop = this.MsRef.current.scrollHeight;
			}


		})
		.catch(error => console.log(error));
}

	componentDidMount() {
		this.fetchLatestComments();
	}

	/*componentDidUpdate(prevProps, prevState) {
		if (prevState.comments != this.state.comments) {
			//console.log('update', this.state);
		}
	}*/


	render() {

		const id = this.state.id;
		const data = this.state.data;
		const chatbox = `chatbox-${id}`;

		const comments = this.renderComments(this.state.comments);

		//console.log('rendering', this.state.id, this.state);

		return (
			<>
				<div key={id} id={chatbox} className="row-expansion-style" >
					{this.formatIndicator(this.state.status)}

					<div className="chat-box" ref={this.MsRef} style={ { marginLeft: '50px', height: '100px', overflow: "scroll" } }>

						{comments}

					</div>

                      <div className="admin chat-box-input" style={ { marginLeft: '40px' } }>
                          <div className="d-flex">
                              <div className="form-group mr-2">
							  	<input name={'mycomment'+data.JobId} type="text" spellCheck="true"  className="form-control mr-2" placeholder={strings.Enteryourcomment} value={this.state.NewComment} onChange={event => this.handleInputChange(data.JobId, event.target.value, event.target.name)} />

                              </div>
                              <div style={ {display: 'flex'} } >
                                  <button className="btn purchasebtn cartbtn" onClick={() => this.submitComment(data)}>Submit</button> &nbsp;

								  {data.paymentStatus == 'succeeded' ? (
								  	<button className="btn purchasebtn cartbtn" onClick={() => this.submitComplete(data)}>Deliver To Customer</button>
								  ) : "" }

                              </div>
                          </div>
                      </div>
				</div>
			</>
		)
	}


}

export default Jobs;
