import React, { Component } from 'react';
import Header from  '../components/Dashboard/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Dashboard/Sidebar';
import Orders from  '../components/Dashboard/Orders';
import config from '../config';

import swal from 'sweetalert';
import { strings } from '../components/strings';

import { DoLogout } from '../components/Dashboard/Functions'

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            products: {},
            productSave:"save",
            isLogin: false,
            projects: [],
            hasProject: true,
            customer: {Name: '', Email: '', MobileNumber: ''}
        }

        this.getProducts = this.getProducts.bind(this);
        this.addproject = this.addproject.bind(this);
        this.updateProject = this.updateProject.bind(this);
        this.reorder = this.reorder.bind(this);
        this.delete = this.delete.bind(this);
        this.getCustomer = this.getCustomer.bind(this);
    }

    getCustomer() {
        const customer = localStorage.getItem("customer");

        try {
            
            JSON.parse(customer);
        
        } catch (e) {
            
            localStorage.removeItem("customer");
            window.location = "/";

        }

        return customer;
    }

    delete(id) {

        const data = {jobId: id, customerId : this.state.customer.CustomerId}
		
		var customer_obj = this.state.customer;
		var Token = customer_obj.token;
		
        fetch(config.BASE_URL + '/api/deleteorder', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {

            this.setState({projects:data.jobs});
            swal({
                icon: 'success',
                text: `Job#${id} successfully choffed off`
            });
                
                
            
        })    
        .catch(error => console.log(error));
    }

    reorder(data) {

        var customer_obj = this.state.customer;
        
        var Token = customer_obj.token;        
        
        fetch(config.BASE_URL + '/api/reorder', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {

            const old = data.old;
            
            this.setState({projects:data.jobs});
            swal({
                icon: 'success',
                text: `${strings.Duplicatesuccess}`
            });
                
                
            
        })    
        .catch(error => console.log(error));
    }

    updateProject(data) {

        var customer_obj = this.state.customer;

        var Token = customer_obj.token;        

        fetch(config.BASE_URL + '/api/updateproject', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            
            this.setState({projects : data});
            
        })
        .catch(error => console.log(error));  
    }

    addproject(data) {

        const customer = this.state.customer

        var customer_obj = customer;
        var Token = customer_obj.token;
        
        //console.log('customer', Token);
			
        const params = {customerId: customer.CustomerId, name: data.name, clientName: customer.Name, contactNo: customer.MobileNumber, email: customer.Email};

        fetch(config.BASE_URL + '/api/addproject', {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-Type": "application/json",
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {

            window.location = `/dashboard/edit-order/${data.jobId}`

            //this.setState({projects : data.jobs});

        })
        .catch(error => console.log(error));

    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const jcustomer = this.getCustomer();
        
        if (this.isJson(jcustomer)) {

            const customer = JSON.parse(jcustomer);

            if (customer.CustomerId > 0) {

                fetch(config.BASE_URL + '/api/getcustomerjob/' + customer.CustomerId, {
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': 'application/json'
                    }
                })
                .then(data => data.json())
                .then(data => {                    

                    const hasProject = data.length === 0 ? false : true;

                    this.setState( { customer: customer, isLogin:true, projects: data, hasProject : hasProject } );
                })
                .catch(error => console.log(error));

                this.forceUpdate();

            }
             else {
                this.setState({isLogin: false});
            }
        } else {
            this.setState({isLogin: false});
            localStorage.removeItem("customer");
            window.location = '/'
        }
    }  

    getProducts(products,productSave) {
        this.setState({
            products: products,
            productSave:productSave
        })
    }

    /*
    doLogout() {
        localStorage.setItem("customer", [])
        this.props.history.push(`/`)
    }

    doLogin() {
        
        
        this.setState({isLogin:true, customer : customer });

    }
    */
    
    render() {
		const defaultJobId = this.props.match.params.id;
        const customer = this.getCustomer();

        return (
            <div className="customer-dashboard">
                <Header customer={customer} doLogout={DoLogout} addproject={this.addproject} projects={this.state.hasProject} />
                <Sidebar />
                <Orders
                     defaultJobId = {defaultJobId} 
                     customer={customer} 
                     addproject={this.addproject}
                     projects={this.state.projects} 
                     updateProject={this.updateProject} 
                     reorder={this.reorder} 
                     delete={this.delete} />
                <Footer />
            </div>
        );
    }
}

export default Dashboard;