import React, { Component } from 'react';
import config from "../../config";
import { strings } from '../../components/strings';
class Comments extends Component {

    constructor(props) {
        super(props);
		var customer = JSON.parse(localStorage.getItem('customer'))
        this.state = {
			customer: (customer) ? customer : [],
			comments: [],
			NewComment: '',
			Job: this.props.Job
        }
		//this.commentText = React.createRef();
		this.submitComment = this.submitComment.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
    }

	componentDidMount() {
		this.getComments(this.state.Job.JobId);
	}

	handleInputChange = (jobId, value, name) => {
		this.setState({
			NewComment: value
		}, () => {

		})
	};

    renderComments = (jobId) => {
		var commentHTML = '';
		var comments = this.getCommentFromState(jobId);
		//console.log(comments);
		if (comments.found){
			commentHTML = comments.data.map( item => {
				return (
					<dl key={item.commentId} className="job-comments-wrapper">
					  <dt><b className="comment_name">{item.senderName} &nbsp;</b> <span className="comment_date">{item.createdDate}</span> &nbsp; : &nbsp; &nbsp; </dt>
					  <dd><div className="comment_text">{item.commentText}</div></dd>
					</dl>
				)
			});
		}
		return commentHTML;

	}

    submitComment = (row) => {
		var customer = this.state.customer;
		var commentdata = {
			jobId: row.JobId,
			projectId: (row.ProjectId) ?  row.ProjectId : 0,
			commentText: this.state.NewComment,
			senderId: customer.CustomerId,
			senderName: customer.Name,
			receiverId: 1,
			receiverName: 'Admin',
			sender: 'customer',
      logo: config.UPLOAD_PATH + config.TOP_LOGO,
      appurl: config.UPLOAD_URL
		}

		fetch(config.BASE_URL + '/api/addcomment', {
			method: 'POST',
			body: JSON.stringify(commentdata),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((customer.token) ? customer.token : '' )
			}
		})
		.then(data => data.json())
		.then(data => {
			//console.log(this.state);
			var comments = this.state.comments;
			comments.push(data[0]);
			this.setState({
				comments: comments,
				NewComment: ''
			}, () => {
				this.commentField.value = '';
			})

		})
		.catch(error => console.log(error));

	}

	getCommentFromState = (JobId) => {
		var comments = this.state.comments;
		//console.log(comments);
		//return comments;
		if (comments.length > 0) {
			var data = comments.filter(comment => comment.jobId == JobId);
			if (data.length > 0) {
				return {found: true, data: data}
			}else{
				return {found: false, data: []}
			}
		}else{
			return {found: false, data: []}
		}
	}

	async getComments (JobId) {
		var customer = this.state.customer;
            await fetch(config.BASE_URL + '/api/getcomments/'+JobId, {
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
					"Authorization": 'Bearer ' + ((customer.token) ? customer.token : '' )
                }
            })
            .then(data => data.json())
            .then(data => {
				var comments = this.state.comments;
				data.map(function(item) {
					var data = comments.filter(comment => comment.commentId == item.commentId);
					if (data.length == 0) {
						comments.push(item);
					}
				});

				this.setState({ comments: comments });
				return comments;
            })
            .catch(error => console.log(error));
			return [];
	}

    render() {
		return (
			<>
				<div className="chat-box">{this.renderComments(this.state.Job.JobId)}</div>
					<div className="chat-box-input">
						<div className="d-flex">
							<div className="form-group mr-2">
							<input
								name={'mycomment'+this.state.Job.JobId}
								type="text"
								className="form-control mr-2"
								spellCheck="true"
								placeholder={strings.Enteryourcomment}
								ref={(el) => this.commentField = el}
								onChange={event => this.handleInputChange(this.state.Job.JobId, event.target.value, event.target.name)} />
						</div>
						<div>
							<button className="btn purchasebtn cartbtn" onClick={() => this.submitComment(this.state.Job)}>Submit</button>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default Comments;
