import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter } from 'react-router';
import config from "../config";
import EditUser from './EditUser';
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

class Users extends Component {
    constructor(props) {
        super(props);
		var authuser = JSON.parse(localStorage.getItem('authuser'));
        this.state = {
			AuthUser: (authuser) ? authuser[0] : [],
            User: {},
            userList: [],
            userModal: false,
            columnWidth: [0,0, 20, 20, 20, 20, 20]
        };
        this.getUsersList = this.getUsersList.bind(this)
		this.onSortChange = this.onSortChange.bind(this);
    }

    componentDidMount() {
        this.getUsersList();
    }

    getUsersList() {
		var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/getusers', {
            method: "GET",
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}			
        })
            .then(data => data.json())
            .then(data => {
                this.setState({
                    userList: data,
                }, () => {
                    for (let index = 0; index < 15; index += 1) {
                      if ($(`.react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns
                      
                      $(`.react-bs-container-header thead th:eq(${index})`).resizable({
                        minWidth: 50,
                        handles: 'e',
                        resize: (__event, ui) => {						
                        const newColumnWidths = [...this.state.columnWidth];
                        const container = $('.react-bs-container-header').width();
                        newColumnWidths[index] = ui.size.width / container * 100;						
                        this.setState({ columnWidth: newColumnWidths });
                        },
                      });
              
                    }
                  })
            })
            .catch(error => console.log(error));
    }

    tdClassName(fieldValue, row, rowIdx, colIdx) {
        return "td-product";
    }
	
    editUser = (User) => {
        let UserId = User.Id

        this.setState({
            UserId,
            User,
            userModal: true
        });
    }

    addUser = () => {
		let UserId = '';
        let User = [];
        this.setState({
            UserId,
            User,
            userModal: true
        });
    }
	

	roleFormatter(cell,row) {
		return  row.UserRole == 1 ? 'Admin' : row.UserRole == 2 ? 'Manufacturing' : 'Financial'
	}
	statusFormatter(cell,row) {
		return  row.IsActive == 1 ? 'Active' : 'Inactive'
	}	
	editFormatter(cell,row) {
		return  <a href="#" onClick={() => this.editUser(row)}>
					<i className="fa fa-pencil" aria-hidden="true"></i>
				</a>;
	}
	idFormatter(cell,row) {
		return  <a href="#" onClick={() => this.editUser(row)}>
			{row.Id}
				</a>;
	}
	
	onSortChange(sortName, sortOrder) {
		localStorage.setItem("UserSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
		}));
	}
	
    render() {

		var UserSort = localStorage.getItem('UserSort');
		
		if (UserSort) {
			UserSort = JSON.parse(UserSort)
		}
		
        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
            onRowClick: this.onRowClick,
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 5,
			defaultSortName: (UserSort) ? UserSort.sortName : null,
			defaultSortOrder: (UserSort) ? UserSort.sortOrder : null,
			onSortChange: this.onSortChange, 			
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        this.state.userList.length > 0
                            ? this.state.userList.length
                            : 5
                }
            ]
        };
        const { userList } = this.state
		
        return (
		
            <div className="admin-wrapper">
				<HeaderAdmin />
                <div className="dashboard-wrapper partscontent">
                    <div className="dashboardhome">
                        <div className="container-fluid dashboard-content jobspagecnt">
                            {/* ============================================================== */}
                            {/* pagecontent  */}
                            {/* ============================================================== */}
							<div className="row">
                            <div className="col-md-12">
                                <ol className="breadcrumb breadm">	
                                    <li><a href="/admin">Admin</a></li>
                                    <li ><i className="fa fa-angle-right midarrow" /><a style={{ cursor: "pointer" }}>User List</a></li>
                                </ol>
                            </div>
							</div>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    {/* top heading section*/}
                                    <div className="inner-pagehead">
										<a style={{ float: 'right', marginBottom:'5px'}} className="btn btn-primary" href="#" onClick={() => this.addUser(null)}> <i className="fa fa-user" aria-hidden="true"></i> &nbsp; Add New</a>
                                        <h1>Users</h1>
										
                                    </div>
                                    {/* top heading section*/}
                                    {/* table content section strat*/}
										<BootstrapTable
                                        ref='table'
                                        data={userList}
                                        pagination={true}
                                        options={options}
                                        search={true} trClassName={this.trClassFormat}>

                                        <TableHeaderColumn dataField='Id' isKey={true}
											dataFormat={ this.idFormatter.bind(this)}
											columnClassName={this.tdClassName}
                                            tdStyle={{ cursor: "pointer", width: "5%", textAlign:"center" }}
                                            thStyle={{ width: "5%", textAlign:"center" }}>
                                            ID
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataSort={true} width={this.state.columnWidth[1] + '%'} dataField='Name'>Name</TableHeaderColumn>
                                        <TableHeaderColumn dataSort={true} width={this.state.columnWidth[2] + '%'} dataField='Email'>Email</TableHeaderColumn>
                                      
                                        <TableHeaderColumn dataSort={true} width={this.state.columnWidth[3] + '%'} dataField='IsActive' dataFormat={ this.statusFormatter.bind(this) }>Status</TableHeaderColumn>
                                        <TableHeaderColumn dataSort={true} width={this.state.columnWidth[4] + '%'} dataField='UserRole' dataFormat={ this.roleFormatter.bind(this) }>Role</TableHeaderColumn>
										
                                    </BootstrapTable>
									<EditUser Token={this.state.AuthUser.token}  onSuccess={this.getUsersList} showModal={this.state.userModal} UserId={this.state.UserId} User={this.state.User} /> 
                                    {/* table content section end*/}
                         
                                </div>
                            </div>
                            {/* ============================================================== */}
                            {/* end pagecontent  */}
                            {/* ============================================================== */}
                        </div>
                    </div>
                    <FooterAdmin />
                </div>
            </div>					
        )
    }
}
export default withRouter(Users);