import React, { Component, Fragment } from 'react';
import Header from '../components/Header';
import config from '../config';
import ProductHome from '../components/ProductHome';
import ProductHomePanel from '../components/ProductHomePanel';
import Footer from '../components/Footer';
import Modal from 'react-bootstrap-modal';
import swal from "sweetalert";
import { Redirect } from 'react-router';
import { strings } from '../components/strings';
import Accessories from '../components/Accessories';
import Cart from '../components/Cart';
import Loader from '../components/Loader';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import Room from '../components/Room'
import AccessoriesRoom from '../components/AccessoriesRoom';
import TotalCost from '../components/TotalCost';
import OrderConfirm from '../components/OrderConfirm';
import LastOrder from '../components/LastOrder';

import { saveCartToDB, clearCartFromDB } from '../components/AuthService';
import Axios from 'axios';

class EditOrder extends Component {
    constructor() {
        super();
        this.state = {
            products: {},
            product: [],
            productSave:"addToCart",
            materialArray: [],
            productsArray: [],
            jobProduct: [],
            jobId: 0,
            RoomId: 1,
            roomData: 'Room 1',
            project: {ProjectName: '', ProjectId: 0, materials: [], doors: [], hinges: [], shelves: [], hardwares: []},
            isLogin: false,
            customer: {Name: '', Email: '', MobileNumber: '', token: ''},
            Status: 'Pending',
            gst: 0,
            min_order: 100,
            material: [],
            materialType: [],
            brand: [],
            substrate: [],
            hinge: [],
            hardware: [],
            door: [],
            layouts: [],
            materialDefault: [],
            maxShelve: 0,
            minShelve: 0,
            minFixedShelve: 0,
            maxFixedShelve: 0,
            minAdjustableShelve: 0,
            maxAdjustableShelve: 0,
            pickupOption: '',

            minDrawerEqual: 0,
            maxDrawerEqual: 0,

            minDrawerSmall: 0,
            maxDrawerSmall: 0,

            minDrawerInner: 0,
            maxDrawerInner: 0,

            ProductType: '',

            stockCodes: [],

            redirect : false,

            loading: true,

            updateKey: '',

            roomsList: [{ id: 1, name: 'Room 1'}],
            lastOrder: [],            
            savingStatus: 'success',
            roomMaterials: [],

            cartLimit : 25,

            cartId:0,

            Token: '',
            

        }

        this.cartRef = React.createRef();

        this.getProducts = this.getProducts.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.getJobProducts = this.getJobProducts.bind(this);

        //this.getJobProject = this.getJobProject.bind(this);

        this.goToProducts = this.goToProducts.bind(this);

        this.addToCart = this.addToCart.bind(this);
        this.updateCart = this.updateCart.bind(this);
        this.copyProduct = this.copyProduct.bind(this);



        this.saveProject = this.saveProject.bind(this);
        this.saveDashboard = this.saveDashboard.bind(this);
        this.getEditProducts = this.getEditProducts.bind(this);

        this.deleteCart = this.deleteCart.bind(this);
        this.redirect = this.redirect.bind(this);

        this.copyRoom = this.copyRoom.bind(this);
        this.setRoom  = this.setRoom.bind(this);
        this.renameRoom = this.renameRoom.bind(this);
        this.addRoom = this.addRoom.bind(this);
        this.deleteRoom = this.deleteRoom.bind(this);

        this.renameSticker = this.renameSticker.bind(this);
        this.updateQty = this.updateQty.bind(this);
        this.completePayment = this.completePayment.bind(this);
        this.locateParent = this.locateParent.bind(this);
        this.addJob = this.addJob.bind(this);

        this.updateCabinetQty = this.updateCabinetQty.bind(this);

        this.preview = this.preview.bind(this);
    }

    preview() {

        const address1 = ""; //data.address1;
        const address2 = ""; //data.address2;
        const address3 = ""; //data.address3;
        const customer = this.state.customer;
        const project = this.state.project;
        const products = this.state.jobProduct;
        const jobId = this.state.jobId;
        const pickupOption = this.state.pickupOption;
        
		var customer_obj = JSON.parse(this.state.customer);
        var Token = customer_obj.token;

        const status = 'Pending'

        fetch(config.BASE_URL + '/api/updateJob', {
            method: "POST",
            body: JSON.stringify({jobId, products, project, customer, address1, address2, address3, status, pickupOption}),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(() => {

            window.open(`/dashboard/view-order/${this.state.jobId}`, 'view-order', 'width=900 height=900 scrolls resizable');
            
        })
        .catch(error => console.log(error));

    }

    deleteRoom(id) {

        const room = this.state.roomsList.filter(room => {
            return room.id === id
        });

        if (room.length > 0 && room[0].products.length > 0) {
            swal({
                title: "There are products in the room",
                text: "Are you sure you want to delete this room, you will not be able to recover this record",
                icon: "warning",
                buttons: true,
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {

                    const productToRemove = room[0].products;

                    let keyToRemove = [];
                    let productsArray = this.state.jobProduct;

                    productToRemove.map(product => {
                        keyToRemove.push(product.key);
                        return product;
                    });

                    const products = productsArray.filter((product, key) => {
                        //console.log('keys', key, keyToRemove, keyToRemove.indexOf(key));
                        return keyToRemove.indexOf(key) === -1
                    });

                    const rooms = this.state.roomsList.filter(room => {
                        return room.id !== id;
                    });

                    const postData = products.map(p => {
                        return p.jobProductData;
                    })

                    this.setState( {
                                productsArray: postData,
                                roomsList: rooms ,
                                RoomId: this.state.roomsList[0].id,
                                roomData: this.state.roomsList[0].name,
                                productSave: "addToCart",
                                jobProduct : products
                        }, () => {

                            localStorage.setItem('roomsList', JSON.stringify(rooms));
                            localStorage.setItem("jobProduct", JSON.stringify(products));
                            localStorage.setItem("postData", JSON.stringify(postData));

                            this.goToProducts(1);
                        } );



                } else {

                    return false;

                }
            });
        } else {
            const rooms = this.state.roomsList.filter(room => {
                return room.id !== id;
            });

            localStorage.setItem('roomsList', JSON.stringify(rooms));

            this.setState( { roomsList: rooms , RoomId: this.state.roomsList[0].id, roomData: this.state.roomsList[0].name, productSave: "addToCart"} );

            // this.setRoom( {id: this.state.roomsList[0].id, name: this.state.roomsList[0].name} )

        }

    }

    addRoom() {

        const rooms = this.state.roomsList;

        let last = rooms[rooms.length  - 1];

        if (last.id === 99) {
            last = rooms[rooms.length - 2];
        }

        const newRoomId = last.id + 1;

        const newRoomName = `Room ${newRoomId}`;

        const newRoom = {id: newRoomId, name: newRoomName, products: [] }

        rooms.push(newRoom);

        localStorage.setItem("roomsList", JSON.stringify(rooms) );

        this.setState( { roomsList: rooms, RoomId: newRoomId, roomData: newRoomName } );

    }

    updateCabinetQty(action, item) {

        let qty = item.jobProductData.ProductQty;

        if (action === 'minus') {

            if (qty > 1) {
                qty -= 1;
            }

        }

        if (action === 'plus') {

            qty += 1;
        }

        const jobProduct = this.state.jobProduct; //JSON.parse(localStorage.getItem("jobProduct"));

        jobProduct.map( (job, key) => {

            if (key === item.key) {

                job.jobProductData.ProductQty = qty;

            }

            return job;

        });

        //localStorage.setItem("jobProduct", JSON.stringify(jobProduct));

        this.setState( { jobProduct: jobProduct } );

    }

    addJob(products, project, customer, address1, address2, address3, Token) {

        fetch(config.BASE_URL + '/api/addJob', {
            method: "POST",
            body: JSON.stringify({products, project, customer, address1, address2, address3}),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + Token
            }
        })
        .then(data => data.json())
        .then(data => {

              this.setState({
                  jobId: data.insertId
              })
        })
        .catch(error => console.log(error));
    }


    completePayment() {

        const address1 = ""; //data.address1;
        const address2 = ""; //data.address2;
        const address3 = ""; //data.address3;
        const customer = this.state.customer;
        const project = this.state.project;
        const products = this.state.jobProduct;
        const jobId = this.state.jobId;
        const pickupOption = this.state.pickupOption;
        //console.log('save project');
        //console.log(this.state);

		var customer_obj = JSON.parse(this.state.customer);
        var Token = customer_obj.token;

        const status = 'In Progress'

        fetch(config.BASE_URL + '/api/updateJob', {
            method: "POST",
            body: JSON.stringify({jobId, products, project, customer, address1, address2, address3, status, pickupOption}),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(() => {

            localStorage.removeItem('JobId');
			localStorage.removeItem('postData');
			localStorage.removeItem('jobProducts');
            localStorage.removeItem('roomsList');

            clearCartFromDB();

            setTimeout(() => this.setState({ redirect: true }), 1000)

        })
        .catch(error => console.log(error));

    }

    setRoom(room) {
        this.setState( { RoomId: room.id, roomData: room.name } )
    }

    renameRoom(room) {
        let jobProduct = this.state.jobProduct.map(job => {
            if (job.jobProductData.RoomId === room.id) {
                job.jobProductData.roomData = room.name
            }

            return job
        });

        this.setState( {jobProduct: jobProduct} )


    }

    renameSticker(sticker) {
      //console.log('sticker', sticker)
        
      let jobProduct = this.state.jobProduct.map(job => {

        console.log('sticker', sticker, job);
            
        if (job.cartId === sticker.cartId) {

                job.jobProductData.StickerName = sticker.stickername
            }

            return job
        });
        
        //console.log('jobProduct', jobProduct)
        
        this.setState( {jobProduct: jobProduct} )

        localStorage.setItem('jobProduct', JSON.stringify( jobProduct ) )
    }

    updateQty(item) {
      //console.log(job.jobProductData.HardwareCost)
        let jobProduct = this.state.jobProduct.map(job => {
            if (job.key === item.id) {
                job.jobProductData.fixedQty = item.ProductQty
                job.jobProductData.Price = parseFloat(job.jobProductData.HardwareCost) * parseInt(item.ProductQty)
            }

            return job
        });
        //console.log('jobProduct', jobProduct)
        this.setState( {jobProduct: jobProduct} )
    }

    redirect(target) {
        this.setState(
            {
                product: [],
                products: [],
                postData: [],
                jobProduct: []
            }
        );
        return <Redirect to={target}  />
    }

    deleteCart(productsArray, array, productKey) {

        const item = this.state.jobProduct.filter( ( j, key) => { return key === productKey } );

        console.log('items', item[0].cart_id);

        this.setState(
            {
                jobProduct: productsArray,
            }, () => {



                //localStorage.setItem("jobProduct", JSON.stringify(productsArray))
                //localStorage.setItem("postData", JSON.stringify(array));

                //this.saveCart();

            }
        )
    }

    getEditProducts(product, edit, key) {

        const allProducts = JSON.parse(localStorage.getItem("relatedProducts")) || [];

        const relatedProducts = this.locateParent(allProducts, product.jobProductData.ProductId)

        const { jobProductData,
                doorData,
                shelveData,
                hardwareData,
                hingeData,
                materialData,
                maxFixedShelve,
                maxAdjustableShelve,
                stockCodes } = product;

        jobProductData.mshelves = shelveData ? shelveData.shelveId : 0;

        let pro = jobProductData;

        pro.details = jobProductData.details;
		pro.detailImage = jobProductData.detailImage;

        pro.doorData = doorData;
        pro.hardwareData = hardwareData;
        pro.shelveData = shelveData;
        pro.MaterialData = materialData;
        pro.hingeData = hingeData;


        this.setState(
            {
                product: pro,
                productSave: 'edit',
                updateKey: key,
                maxFixedShelve: maxFixedShelve,
                maxAdjustableShelve: maxAdjustableShelve,
                stockCodes: stockCodes,
                relatedProducts: relatedProducts
            }
        )

        window.scrollTo( {top: 0, behavior: 'smooth' });

    }

    saveDashboard() {

        const address1 = ""; //data.address1;
        const address2 = ""; //data.address2;
        const address3 = ""; //data.address3;
        const customer = this.state.customer;
        const project = this.state.project;
        const products = this.state.jobProduct;
        const jobId = this.state.jobId;

        var customer_obj = JSON.parse(this.state.customer);
		//console.log(customer_obj);
        var Token = customer_obj.token;

        if (this.state.Status === 'In Progress' || this.state.Status === 'Completed') {

            window.location = '/dashboard'

        } else {

            fetch(config.BASE_URL + '/api/updateJob', {
                method: "POST",
                body: JSON.stringify({jobId, products, project, customer, address1, address2, address3}),
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                    "Authorization": 'Bearer ' + ((Token) ? Token : '' )
                }
            })
            .then(data => data.json())
            .then(data => {

                setTimeout(() => {
                    window.location = '/dashboard'
                }, 1000);
            })
            .catch(error => console.log(error));

        }

    }

    saveProject() {

        this.setState( {
            
            savingStatus: 'saving'

        }, () => {

            const address1 = ""; //data.address1;
            const address2 = ""; //data.address2;
            const address3 = ""; //data.address3;
            const customer = this.state.customer;
            const project = this.state.project;
            const products = this.state.jobProduct;
            const jobId = this.state.jobId;

            //console.log('save project');
            //console.log(this.state);

            var customer_obj = JSON.parse(this.state.customer);
            var Token = customer_obj.token;

            fetch(config.BASE_URL + '/api/updateJob', {
                method: "POST",
                body: JSON.stringify({jobId, products, project, customer, address1, address2, address3}),
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                    "Authorization": 'Bearer ' + ((Token) ? Token : '' )
                }
            })
            .then(data => data.json())
            .then(data => {
                this.setState( { savingStatus: 'success'}, () => {
                    swal("", "Saving", "success");
                })
                
            })
            .catch(error => console.log(error));

        });    

    }

    delete(id) {
        swal({
            title: `${strings.Areyousure}`,
            text: "Once deleted, you will not be able to recover this record",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then(willDelete => {
            if (willDelete) {

			var customer_obj = JSON.parse(this.state.customer);
			var Token = customer_obj.token;

                fetch(config.BASE_URL + '/api/deleteJobProduct/', {
                    method: "POST",
                    body: JSON.stringify({id: id}),
                    headers: {
                        "Content-Type": "application/json",
						"Authorization": 'Bearer ' + ((Token) ? Token : '' )
                    }
                })
                .then(data => data.json())
                .then(data => {
                    this.getJobProducts();
                })
                .catch(error => console.log(error));

            } else {
                return false;
            }
        });
    }

    addToCart(data) {

        //const customer_obj = JSON.parse(this.state.customer);
		//const Token = customer_obj.token;    

        if (this.state.jobProduct.length < this.state.cartLimit) {

            const {
                material,
                stockCodes,
                stockCode,
                product,
                Width,
                Height,
                ToeHeight,
                ToeRecess,
                Depth,
                Elevation,
                SoffitHeight,
                hardware,
                Gst,
                DoorId,
                gapTop,
                gapBottom,
                gapLeft,
                gapRight,
                drawerGaps,
                fixedQty,
                equalQty,
                adjustableQty,
                detailImage,
                details,
                HardwareLayout,
                lineTop,
                lineLeft,
                lineBottom,
                lineRight,
                ProductType,
                ProductStockCode,
                ProductDescription,
                ProductPrice,
                ProductQty,
                ProductLineTotal,
                ProductStockImage,
                FixedShelve,
                AdjustableShelve,
                NoOfMitre
            } = data

            const cart = Cart(material,lineTop, lineBottom, lineLeft, lineRight, data, DoorId, hardware, Width, Height, Depth, product, stockCodes, stockCode, gapTop, gapBottom, gapLeft, gapRight, Gst, ProductType, ProductLineTotal, ProductStockCode, ProductPrice, ProductStockImage, Elevation, SoffitHeight, ToeHeight, ToeRecess, drawerGaps, detailImage, details, fixedQty, equalQty, adjustableQty, HardwareLayout, ProductDescription, ProductQty, this.state.roomData, this.state.RoomId, FixedShelve, AdjustableShelve, NoOfMitre, this.state.cartId);

            let materialArray = this.state.materialArray;

            if (cart.materialObj) {
                materialArray = cart.materialObj
            }

            let productsArray = this.state.productsArray;

            const key = this.state.jobProduct.length;

            productsArray.push(cart.jobProductData);

            localStorage.setItem("postData", JSON.stringify(productsArray));

            const materials = this.state.roomMaterials.filter( r => r.RoomId === this.state.RoomId );        

            if (materials.length === 0) {

                const roomMaterials = this.state.roomMaterials;
                
                roomMaterials.push( { RoomId: this.state.RoomId, materials: materialArray } );

                this.setState( { roomMaterials: roomMaterials } );

            } 

            data = {
                jobProductData: cart.jobProductData,
                materialData: materialArray,
                doorData: cart.doorObj,
                hingeData: cart.hingeData,
                shelveData: cart.shelveData,
                hardwareData: cart.hardwareData,
                stockCodes: this.state.stockCodes,
                maxFixedShelve: this.state.maxFixedShelve,
                maxAdjustableShelve: this.state.maxAdjustableShelve,
                key: key
            }

            let job = this.state.jobProduct;

            job.unshift(data);

            //console.log('save');

            //saveCartToDB( 'add', data, Token )

            localStorage.setItem("jobProduct", JSON.stringify(job))

            this.cartRef.current.toggled( this.state.RoomId );

            this.setState({
                productprice: cart.productprice,
                doorscost: cart.totalDoorCost,
                shelvescost: cart.TotalShelveCost,
                hardwarecost: cart.hardwarecost,
                carcasecost: cart.totalCarcaseCost,
                DoorHingeCost: cart.DoorHingeCost,
                cartId: this.state.cartId + 1
            });

            if (
                (Width >= product.WidthMin && Width <= product.WidthMax) &&
                (Height >= product.HeightMin && Height <= product.HeightMax) &&
                (Depth >= product.DepthMin && Depth <= product.DepthMax) &&
                (
                    (ToeRecess >= product.ToeRecessMin && ToeRecess <= product.ToeRecessMax) ||
                    (Elevation >= product.ElevationMin && Elevation <= product.ElevationMax)
                ) &&
                (
                    (ToeHeight >= product.ToeHeightMin && ToeHeight <= product.ToeHeightMax) ||
                    (SoffitHeight >= product.SoffitHeightMin && SoffitHeight <= product.SoffitHeightMax)
                )
            ) {

                this.setState({
                    postData: data,
                    getRooms: false,
                    //product : jobProductData,
                    productSave: "addToCart",
                    productsArray: productsArray,
                    jobProduct: job,
                    materialArray: []
                }, () => {
                    //this.saveCart();
                    ToastsStore.success(`Success!!! ${cart.jobProductData.ProductName} added to the cart.`);
                    //swal("", "Item added to cart", "success");
                })
            }

        } else {

            swal("", "You reached the maximum limit of your cart. Please save and create another project to add more item", "error");

        }

    }

    saveCart() {

        const address1 = ""; //data.address1;
        const address2 = ""; //data.address2;
        const address3 = ""; //data.address3;
        const customer = this.state.customer;
        const project = this.state.project;
        const products = this.state.jobProduct;
        const jobId = this.state.jobId;

        var customer_obj = JSON.parse(this.state.customer);
		//console.log(customer_obj);
        var Token = customer_obj.token;        

        fetch(config.BASE_URL + '/api/updateJob', {
            method: "POST",
            body: JSON.stringify({jobId, products, project, customer, address1, address2, address3}),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
        
        })
        .catch(error => console.log(error));        

    }

    copyRoom() {

        const RoomId = this.state.RoomId;

        if (RoomId === 99) return;

        const jobProducts = localStorage.getItem("jobProduct") ? JSON.parse(localStorage.getItem("jobProduct")) : [];

        let productsArray = this.state.productsArray;

        let job = this.state.jobProduct;

        let data = [];

        const rooms = this.state.roomsList;

        //console.log('rooms', rooms);

        let last = rooms[rooms.length - 1];

        if (last.id === 99) {
            last = rooms[rooms.length - 2];
        }

        const newRoomId = last.id + 1;

        const newRoomName = `Copy of ${this.state.roomData}`;

        let newKey = jobProducts.length;

        jobProducts.map( p => {

            const product = p.jobProductData;

            //console.log(product.RoomId, RoomId);

            if (product.RoomId === RoomId) {

                console.log('p', p);

                const doorData = p.doorData;
                const harwareData = p.hardwareData;
                const hingeData = p.hingeData;
                const shelveData = p.shelveData;
                const materialData = p.materialData;
                const stockCodes = p.stockCodes;

                product.RoomId = newRoomId;
                product.roomData = newRoomName;

                const data = {
                    doorData        : doorData,
                    hardwareData    : harwareData,
                    hingeData       : hingeData,
                    shelveData      : shelveData,
                    MaterialData    : materialData,
                    stockCodes      : stockCodes,
                    jobProductData  : product,
                    key             : newKey++
                }

                productsArray.push(product);

                job.push(data);

                localStorage.setItem("postData", JSON.stringify(productsArray));

                localStorage.setItem("jobProduct", JSON.stringify(job));

            }

            return

        });

        rooms.push( { id: newRoomId, name: newRoomName } );

        this.setState({
                postData: data,
                getRooms: false,
                roomsList: rooms,
                productSave: "addToCart",
                productsArray: productsArray,
                jobProduct: job,
                materialArray: []
        })

    }

    copyProduct(product, key) {

         //console.log('product', product);

         const productsArray = this.state.productsArray;

         const jobProducts = localStorage.getItem("jobProduct") ? JSON.parse(localStorage.getItem("jobProduct")) : [];

         const jobProductData = product.jobProductData;

         const doorData = product.doorData;
         const hardwareData = product.hardwareData;
         const hingeData = product.hingeData;
         const shelveData = product.shelveData;
         const MaterialData = product.materialData;
         const stockCodes = product.stockCodes;

         const newKey = key + jobProducts.length;         

         jobProductData.key = newKey;

         const data = {
             jobProductData : jobProductData,
             doorData : doorData,
             hardwareData : hardwareData,
             hingeData : hingeData,
             shelveData : shelveData,
             MaterialData : MaterialData,
             stockCodes: stockCodes,
             key: newKey
         }

         productsArray.push(jobProductData);

         localStorage.setItem("postData", JSON.stringify(productsArray));

         const job = this.state.jobProduct;

         console.log('jobs', job, product , key);

         job.push(data);

         

         localStorage.setItem("jobProduct", JSON.stringify(job));

         this.setState({
             postData: data,
             getRooms: false,
             //product : jobProductData,
             productSave: "addToCart",
             productsArray: productsArray,
             jobProduct: job,
             materialArray: []
         })
     }

    updateCart(data) {

        var postDataList = JSON.parse(localStorage.getItem("postData"))
        var jobProductList = JSON.parse(localStorage.getItem("jobProduct"))

        const {
            material,
            stockCodes,
            stockCode,
            product,
            Width,
            Height,
            ToeHeight,
            ToeRecess,
            Depth,
            Elevation,
            SoffitHeight,
            hardware,
            Gst,
            DoorId,
            gapTop,
            gapBottom,
            gapLeft,
            gapRight,
            drawerGaps,
            fixedQty,
            equalQty,
            adjustableQty,
            detailImage,
            details,
            HardwareLayout,
            lineTop,
            lineLeft,
            lineBottom,
            lineRight,
            ProductType,
            ProductStockCode,
            ProductDescription,
            ProductPrice,
            ProductQty,
            ProductLineTotal,
            ProductStockImage,
            FixedShelve,
            AdjustableShelve,
            NoOfMitre,
            RoomId,
            roomData
        } = data

        const cart = Cart(material,lineTop, lineBottom, lineLeft, lineRight, data, DoorId, hardware, Width, Height, Depth, product, stockCodes, stockCode, gapTop, gapBottom, gapLeft, gapRight, Gst, ProductType, ProductLineTotal, ProductStockCode, ProductPrice, ProductStockImage, Elevation, SoffitHeight, ToeHeight, ToeRecess, drawerGaps, detailImage, details, fixedQty, equalQty, adjustableQty, HardwareLayout, ProductDescription, ProductQty, roomData, RoomId, FixedShelve, AdjustableShelve, NoOfMitre);



        //console.log('update cart', cart);

        if (cart.materialObj) {
            this.state.materialArray = cart.materialObj;
        }

        postDataList.splice(this.state.updateKey, 1, cart.jobProductData);

        localStorage.setItem("postData", JSON.stringify(postDataList));

        //console.log('this state', this.state);

        var data = {
            jobProductData: cart.jobProductData,
            materialData: this.state.materialArray,
            doorData: cart.doorObj,
            hingeData: cart.hingeData,
            shelveData: cart.shelveData,
            hardwareData: cart.hardwareData,

            stockCodes: this.state.stockCodes,
            maxAdjustableShelve: this.state.maxAdjustableShelve,
            maxFixedShelve: this.state.maxFixedShelve
        }
        // this.state.jobProduct.push(data)

        jobProductList.splice(this.state.updateKey, 1, data);

        localStorage.setItem("jobProduct", JSON.stringify(jobProductList))

        if ((Width >= product.WidthMin && Width <= product.WidthMax) && (Height >= product.HeightMin && Height <= product.HeightMax) && (Depth >= product.DepthMin && Depth <= product.DepthMax) && (ToeRecess >= product.ToeRecessMin && ToeRecess <= product.ToeRecessMax) || (Elevation >= product.ElevationMin && Elevation <= product.ElevationMax) && (ToeHeight >= product.ToeHeightMin && ToeHeight <= product.ToeHeightMax) || (SoffitHeight >= product.SoffitHeightMin && SoffitHeight <= product.SoffitHeightMax)) {

            //console.log('jobprduct', jobProductList);

            this.setState( { productSave: 'addToCart'}, () => {
                this.setState({
                    postData: data,
                    getRooms: false,                  
                    jobProduct: jobProductList,                  
                    materialArray: []
                }, () => {
                    this.saveCart();
                    ToastsStore.success(`Success!!! ${cart.jobProductData.ProductName} updated in the cart.`);
                })
            } )

            
        }


    }


    update(data) {

		var customer_obj = JSON.parse(this.state.customer);
		var Token = customer_obj.token;

        /* const materials = [
            {
                productSectionId: 1,
                JobProductId: data.id,
                materialId : data.MaterialType1,
                brandId : data.MaterialBrand1,
                finishId : data.MaterialFinish1,
                substrateId : data.MaterialSubstrate1,
                colourId : data.MaterialColour1
            },
            {
                productSectionId: 2,
                JobProductId: data.id,
                materialId : data.MaterialType2,
                brandId : data.MaterialBrand2,
                finishId : data.MaterialFinish2,
                substrateId : data.MaterialSubstrate2,
                colourId : data.MaterialColour2
            },
            {
                productSectionId: 3,
                JobProductId: data.id,
                materialId : data.MaterialType3,
                brandId : data.MaterialBrand3,
                finishId : data.MaterialFinish3,
                substrateId : data.MaterialSubstrate3,
                colourId : data.MaterialColour3
            },
            {
                productSectionId: 4,
                JobProductId: data.id,
                materialId : data.MaterialType4,
                brandId : data.MaterialBrand4,
                finishId : data.MaterialFinish4,
                substrateId : data.MaterialSubstrate4,
                colourId : data.MaterialColour4
            },
            {
                productSectionId: 5,
                JobProductId: data.id,
                materialId : data.MaterialType5,
                brandId : data.MaterialBrand5,
                finishId : data.MaterialFinish5,
                substrateId : data.MaterialSubstrate5,
                colourId : data.MaterialColour5
            },
        ];

        const doors = {
            JobProductId : data.id,
            doorId : data.DoorId,
            gapTop: data.gapTop,
            gapBottom : data.gapBottom,
            gapLeft : data.gapLeft,
            gapRight : data.gapRight,
            doorsCost : data.DoorsCost
        }

        const hinges = {
            JobProductId : data.id,
            hingeId : data.HingeId
        }

        const shelves = {
            JobProductId: data.id,
            shelveId: data.mshelves,
            noOfShelves: data.noOfShelve,
            shelvesCost: data.ShelvesCost
        }

        const hardwares = {
            JobProductId: data.id,
            hardwareId : data.HardwareId
        }

        data.materials = materials;
        data.doors = doors;
        data.hinges = hinges;
        data.shelves = shelves;
        data.hardwares = hardwares;

        */

        fetch(config.BASE_URL + '/api/updateJobProduct/', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.getJobProducts();
        })
        .catch(error => console.log(error));
    }

    doLogout() {

        this.setState({isLogin:false});

        localStorage.removeItem("products");
        localStorage.removeItem("customer");
        localStorage.removeItem("project");
        localStorage.removeItem("relatedProducts");

        swal({
            title: "",
            text: "You have been logout!!",
            type: "success",
            timer: 3000
            }).then( () => {
                window.location = '/'
            })

    }

    doLogin() {

        const customer = localStorage.getItem("customer");
        this.setState({isLogin:true, customer : customer});

    }

    getProducts(products,productSave) {
        /*console.log('xxxxx');
        console.log(products);
        console.log('xxxxx')
        this.setState({
            products: products,
            productSave:productSave
        })*/
    }

    getJobProducts() {

        this.setState( { loading: true } );

        const orderId = this.props.match.params.id;

        const customer = JSON.parse(localStorage.getItem("customer"));

        const Token = customer.token;

        this.setState({
            jobId: orderId,
            Token: Token
        });

        const jobId = localStorage.getItem("JobId");

        localStorage.removeItem("jobProduct");
        localStorage.removeItem("postData");

        localStorage.setItem("JobId", orderId);

        //clearCartFromDB( Token );

        fetch(config.BASE_URL + '/api/getJobProducts/' + orderId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {

            const jobproducts = (data.jobproducts) ? data.jobproducts: [];
            const project = data.project;
            const job = data.job;

            const roomids = [ 1 ];

            let rooms = [ {id: 1, name: 'Room 1'} ];

            let cartId = 0;

            if (jobproducts.length > 0) {

                let jobProduct = [];

                const allProducts = []; //JSON.parse(localStorage.getItem("relatedProducts")) || [];

                const productId = jobproducts[0].ProductId;

                const relatedProducts = this.locateParent(allProducts, productId);

                

                jobproducts.map( jobData => {

                    const StockCode = jobData.StockCode;

                    let displayname  = jobData.ProductName;

                    const stockcodes = data.stockcodes.filter( s => {
                        return StockCode === s.StockCode
                    });

                    if (stockcodes.length > 0 && stockcodes[0].displayname.length > 0) {
                        displayname = stockcodes[0].displayname
                    }

                    jobData.displayname = displayname;

                    jobData.cartId = cartId
                   
                    let obj = {

                        cartId: cartId,
                        jobProductData: jobData,
                        materialData: jobData.MaterialData,
                        doorData: jobData.doorData,
                        hingeData: jobData.hingeData,
                        shelveData: jobData.shelveData,
                        hardwareData: jobData.hardwareData,

                        minFixedShelve: data.minFixedShelve,
                        maxFixedShelve: data.maxFixedShelve,
                        minAdjustableShelve: data.minAdjustableShelve,
                        maxAdjustableShelve: data.maxAdjustableShelve,

                        maxDrawerEqual : data.maxDrawerEqual,
                        minDrawerEqual : data.minDrawerEqual,
                        minDrawerSmall : data.minDrawerSmall,
                        maxDrawerSmall : data.maxDrawerSmall,
                        minDrawerInner : data.minDrawerInner,
                        maxDrawerInner : data.maxDrawerInner,

                        stockCodes: data.stockcodes

                    }

                    cartId++;

                    jobProduct.push(obj);

                    if ( roomids.indexOf(jobData.RoomId) === -1) {

                        roomids.push(jobData.RoomId);
                        rooms.push( { id: jobData.RoomId, name: jobData.roomData ? jobData.roomData : `Room ${jobData.RoomId}`} );

                    } else {

                        rooms = rooms.map( r => {

                            if (r.id === jobData.RoomId) {
                                r.name = jobData.roomData ? jobData.roomData : `Room ${jobData.RoomId}`
                            }

                            return r;

                        })

                    }

                    //console.log('rooms', rooms);

                    //obj.cart_id = saveCartToDB( 'action', obj, this.state.Token )

                    //saveCartToDB( 'action', obj, this.state.Token )

                });

                //console.log(' i am here now ');

                //console.log('jobProduct', jobProduct);

                this.setState( {
                    relatedProducts: relatedProducts,
                    jobProduct: jobProduct,
                    postData: data,
                    products: jobproducts,
                    product: jobproducts[0],
                    project: project,
                    Status: job.Status,
                    gst: data.gst,
                    min_order: data.min_order,
                    default: data.default,
                    material: data.material,
                    materialType: data.materialType,
                    brand: data.brand,
                    hinge: data.hinge,
                    door: data.door,
                    hardware: data.hardware,
                    layouts : data.layouts,
                    shelves: data.shelve,
                    stockCodes: data.stockcodes,
                    substrate: data.substrate,
                    materialDefault : data.materialDefault,
                    maxShelve: data.maxShelve,
                    minShelve: data.minShelve,
                    minDrawer: data.minDrawer,
                    maxDrawer: data.maxDrawer,

                    minFixedShelve: data.minFixedShelve,
                    maxFixedShelve: data.maxFixedShelve,
                    minAdjustableShelve: data.minAdjustableShelve,
                    maxAdjustableShelve: data.maxAdjustableShelve,

                    maxDrawerEqual : data.maxDrawerEqual,
                    minDrawerEqual : data.minDrawerEqual,
                    minDrawerSmall : data.minDrawerSmall,
                    maxDrawerSmall : data.maxDrawerSmall,
                    minDrawerInner : data.minDrawerInner,
                    maxDrawerInner : data.maxDrawerInner,

                    roomsList: rooms,

                    lastOrder : data.lastOrder,   
                    
                    cartId: cartId,

                    loading: false

                }, () => {


                    try {
                        
                        localStorage.setItem("postData", JSON.stringify(jobproducts));
                        localStorage.setItem("jobProduct", JSON.stringify(jobProduct));
                        localStorage.setItem("project", JSON.stringify(project));
                        localStorage.setItem("roomsList", JSON.stringify(rooms));
                      
                    } catch(e) {
                        
                        if (e.code == 22) {
                         
                        }
                      }

                    
                });



            } else {

                if (project) {
                    this.setState( {project: project} );
                }

                this.goToProducts(1, "addToCart");
            }

        })
        .catch(error => console.log(error));
    }

   

    locateParent(data, ProductId){
      var relatedproducts = []
      for(var i = 0; i < data.length; i++) {
        //console.log(data[i].children);
        relatedproducts = locateSiblings(data[i].children || [], ProductId);
        //console.log('relatedproducts', relatedproducts);
        if (relatedproducts && relatedproducts.products) {
          //console.log('relatedproducts', relatedproducts)
          relatedproducts['category'] = {id: data[i].id, text: data[i].text};
          return relatedproducts;
        }
      }

      function locateProduct(products, ProductId) {
        for(var i = 0; i < products.length; i++) {
          //console.log()
          if (products[i].ProductId && products[i].ProductId == ProductId) {
            //console.log('products', products);
            return products;
          }
        }
      }

      function locateSiblings(children, ProductId) {
        var products = [];
        for(var i = 0; i < children.length; i++) {
          //console.log(children[i].products)
          products = locateProduct(children[i].products || [], ProductId)
          if (products && products.length > 0) {
            return {
              subcategory: {id: children[i].id, text: children[i].text},
              products: products
            }
          }
        }
      }
    }

    goToProducts(productId, productSave) {

        this.setState( { loading: true } );

        let customerId = 0;

        const customer = JSON.parse(localStorage.getItem("customer"));        
        
        const allProducts = []; //JSON.parse(localStorage.getItem("relatedProducts")) || []

        //console.log('allproducts', allProducts);

        const relatedProducts = this.locateParent(allProducts, productId)

        if (customer && customer.CustomerId > 0) {
            customerId = customer.CustomerId;
        }

        fetch(config.BASE_URL + '/api/getproductbyId/' + productId + '/' + customerId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState({
                relatedProducts: relatedProducts,
                gst         : data.gst,
                min_order   : data.min_order,
                default     : data.default,
                products    : data.products,
                product     : data.product,
                material    : data.material,
                materialType: data.materialType,
                brand       : data.brand,
                hinge       : data.hinge,
                door        : data.door,
                hardware    : data.hardware,
                shelves     : data.shelve,
                stockCodes  : data.stockcodes,
                substrate   : data.substrate,
                materialDefault : data.materialDefault,
                productSave: productSave,
                maxShelve: data.maxShelve,
                minShelve: data.minShelve,
                minDrawer: data.minDrawer,
                maxDrawer: data.maxDrawer,
                minFixedShelve: data.minFixedShelve,
                maxFixedShelve: data.maxFixedShelve,
                minAdjustableShelve: data.minAdjustableShelve,
                maxAdjustableShelve: data.maxAdjustableShelve,
                loading: false
            })
        })
        .catch(error => console.log(error)); 
    }

    componentDidMount() {

        //console.log('did mount');

        localStorage.removeItem("postData");
        localStorage.removeItem('jobProduct');
        localStorage.removeItem('relatedProducts');

        this.getJobProducts();
        //this.getJobProject();
    }

    

    render() {

        console.log('this', this.state.jobProduct);

        const status = this.state.Status;

        if (this.state.redirect || status === 'In Progress' || status === 'Completed') {
            return (
                <Redirect to="/dashboard" />
            )

        } else {

          const product = this.state.product

          const {relatedProducts} = this.state

          //console.log('relatedProducts', relatedProducts);
          //console.log('this.state.product', this.state.product)

          var AccessoriesProd = product.ProductType && product.ProductType == 'accessories' ? true : relatedProducts && relatedProducts.category.text == 'Accessories' ? true : false
          var PanelsProd = product.ProductType && product.ProductType == 'panels' ? true : relatedProducts && relatedProducts.category.text.indexOf('Panels')> -1 ? true : false

          //console.log('PanelsProd', PanelsProd);

          var ProductSection =    (<ProductHome
                                  page='edit'
                                  getEditProducts={this.getEditProducts}
                                  addToCart={this.addToCart}

                                  renameSticker={this.renameSticker}
                                  updateCabinetQty={this.updateCabinetQty}

                                  updateQty={this.updateQty}
                                  updateCart={this.updateCart}
                                  deleteCart={this.deleteCart}
                                  copyProduct={this.copyProduct}

                                  products={this.state.jobProduct}
                                  product={product}
                                  project={this.state.project}
                                  productSave={this.state.productSave}
                                  isLogin={this.state.isLogin}
                                  customer={this.state.customer}

                                  JobId={this.state.jobId}
                                  addJob={this.addJob}

                                  doLogout={this.doLogout}
                                  maxShelve={this.state.maxShelve}
                                  minShelve={this.state.minShelve}
                                  minDrawer={this.state.minDrawer}
                                  maxDrawer={this.state.maxDrawer}

                                  minFixedShelve={this.state.minFixedShelve}
                                  maxFixedShelve={this.state.maxFixedShelve}
                                  minAdjustableShelve={this.state.minAdjustableShelve}
                                  maxAdjustableShelve={this.state.maxAdjustableShelve}

                                minDrawerEqual={this.state.minDrawerEqual}
                                maxDrawerEqual={this.state.maxDrawerEqual}
                                maxDrawerSmall={this.state.maxDrawerSmall}
                                minDrawerSmall={this.state.minDrawerSmall}
                                maxDrawerInner={this.state.maxDrawerInner}
                                minDrawerInner={this.state.minDrawerInner}

                                  gst={this.state.gst}
                                  min_order={this.state.min_order}
                                  material={this.state.material}
                                  materialType={this.state.materialType}
                                  brand={this.state.brand}
                                  door={this.state.door}
                                  hinge={this.state.hinge}
                                  hardware={this.state.hardware}
                                  shelve={this.state.shelves}
                                  substrate={this.state.substrate}
                                  stockcodes={this.state.stockCodes}
                                  layouts={this.state.layouts}
                                  materialDefault={this.state.materialDefault}
                                  completePayment={this.completePayment}
                                  goToProducts={this.goToProducts}
                                  relatedProducts ={relatedProducts}
                                  updateKey={this.state.updateKey}

                                  roomId={this.state.RoomId}
                                  roomMaterials={this.state.roomMaterials}
                                  />)

          if (AccessoriesProd){
            ProductSection = (<Accessories
                page='edit'
                getEditProducts={this.getEditProducts}
                addToCart={this.addToCart}

                setRoom={this.setRoom}
                renameRoom={this.renameRoom}
                renameSticker={this.renameSticker}

                updateQty={this.updateQty}
                updateCart={this.updateCart}
                deleteCart={this.deleteCart}
                copyProduct={this.copyProduct}
                updateCabinetQty={this.updateCabinetQty}
                JobId={this.state.jobId}
                addJob={this.addJob}

                products={this.state.jobProduct}
                product={this.state.product}
                project={this.state.project}
                productSave={this.state.productSave}
                isLogin={this.state.isLogin}
                customer={this.state.customer}
                doLogout={this.doLogout}
                maxShelve={this.state.maxShelve}
                minShelve={this.state.minShelve}
                minDrawer={this.state.minDrawer}
                maxDrawer={this.state.maxDrawer}
                minFixedShelve={this.state.minFixedShelve}
                maxFixedShelve={this.state.maxFixedShelve}
                minAdjustableShelve={this.state.minAdjustableShelve}
                maxAdjustableShelve={this.state.maxAdjustableShelve}
                minDrawerEqual={this.state.minDrawerEqual}
                maxDrawerEqual={this.state.maxDrawerEqual}
                maxDrawerSmall={this.state.maxDrawerSmall}
                minDrawerSmall={this.state.minDrawerSmall}
                maxDrawerInner={this.state.maxDrawerInner}
                minDrawerInner={this.state.minDrawerInner}
                
                gst={this.state.gst}
                min_order={this.state.min_order}
                material={this.state.material}
                materialType={this.state.materialType}
                brand={this.state.brand}
                door={this.state.door}
                hinge={this.state.hinge}
                hardware={this.state.hardware}
                shelve={this.state.shelves}
                substrate={this.state.substrate}
                stockcodes={this.state.stockCodes}
                layouts={this.state.layouts}
                materialDefault={this.state.materialDefault}
                completePayment={this.completePayment}
                goToProducts={this.goToProducts}
                relatedProducts ={relatedProducts}
                updateKey={this.state.updateKey}
                />)
          }

          if (PanelsProd){

            //console.log('panel');

            ProductSection = (<ProductHomePanel
                page='edit'
                getEditProducts={this.getEditProducts}
                addToCart={this.addToCart}
                setRoom={this.setRoom}
                renameRoom={this.renameRoom}
                renameSticker={this.renameSticker}
                updateQty={this.updateQty}
                updateCart={this.updateCart}
                deleteCart={this.deleteCart}
                products={this.state.jobProduct}
                product={this.state.product}
                project={this.state.project}
                productSave={this.state.productSave}
                isLogin={this.state.isLogin}
                customer={this.state.customer}
                updateCabinetQty={this.updateCabinetQty}
                JobId={this.state.jobId}
                addJob={this.addJob}

                doLogout={this.doLogout}
                maxShelve={this.state.maxShelve}
                minShelve={this.state.minShelve}
                minDrawer={this.state.minDrawer}
                maxDrawer={this.state.maxDrawer}
                minFixedShelve={this.state.minFixedShelve}
                maxFixedShelve={this.state.maxFixedShelve}
                minAdjustableShelve={this.state.minAdjustableShelve}
                maxAdjustableShelve={this.state.maxAdjustableShelve}
                minDrawerEqual={this.state.minDrawerEqual}
                maxDrawerEqual={this.state.maxDrawerEqual}
                maxDrawerSmall={this.state.maxDrawerSmall}
                minDrawerSmall={this.state.minDrawerSmall}
                maxDrawerInner={this.state.maxDrawerInner}
                minDrawerInner={this.state.minDrawerInner}
                gst={this.state.gst}
                min_order={this.state.min_order}
                material={this.state.material}
                materialType={this.state.materialType}
                brand={this.state.brand}
                door={this.state.door}
                hinge={this.state.hinge}
                hardware={this.state.hardware}
                shelve={this.state.shelves}
                substrate={this.state.substrate}
                stockcodes={this.state.stockCodes}
                layouts={this.state.layouts}
                materialDefault={this.state.materialDefault}
                completePayment={this.completePayment}
                goToProducts={this.goToProducts}
                relatedProducts ={relatedProducts}
                updateKey={this.state.updateKey}

                roomMaterials={this.state.roomMaterials}
                roomId={this.state.RoomId}

                />)
          }

        return (
            <div>
                <Header
                    page="edit-order"
                    saveDashboard={this.saveDashboard}
                    saveProject={this.saveProject}
                    sendProducts={this.goToProducts}
                    doLogin={this.doLogin}
                    doLogout={this.doLogout}
                    jobProduct={this.state.jobProduct}
                    project={this.state.project}
                    savingStatus={this.state.savingStatus}
                    isLogin={this.state.isLogin} />
                   

                    { this.state.loading ? <Loader /> :


                        <Fragment>

                            <div>
                                <div className="clr" />
                                <div className="wrapper" id="product">
                                    <div className="container">
                                        <div className="row">

                                            <div className="col-md-12">
                                                <ol className="breadcrumb breadm">
                                                    <li><a href="/">Home</a></li>
                                                    <li className="active"><i className="fa fa-angle-right midarrow" /><a >Product Detail</a></li>
                                                </ol>
                                            </div>

                                            <div className="col-md-12 maincontent">

                                                { ProductSection   }

                                                <div key={"roomContainer" + 1} className="col-xs-12 col-sm-12 col-md-3 rightaccordian">
                                                    <Room

                                                        ref={this.cartRef}
                                                        
                                                        activeRoomId={this.state.RoomId}

                                                        key={`room1`}
                                                        page="edit"

                                                        products={this.state.jobProduct}

                                                        setRoom={this.setRoom}
                                                        sendProducts={this.getEditProducts}

                                                        setRoom={this.setRoom}
                                                        renameRoom={this.renameRoom}
                                                        addRoom={this.addRoom}
                                                        deleteRoom={this.deleteRoom}
                                                        preview={this.preview}

                                                        renameSticker={this.renameSticker}

                                                        roomsList={this.state.roomsList}

                                                        updateQty={this.updateQty}
                                                        deleteCart={this.deleteCart}
                                                        copyProduct={this.copyProduct}
                                                        copyRoom={this.copyRoom}
                                                        updateCabinetQty={this.updateCabinetQty}
                                                        />

                                                    <AccessoriesRoom

                                                        key={`accessories1`}

                                                        page="edit"
                                                        products={this.state.jobProduct}

                                                        sendProducts={this.getEditProducts}

                                                        setRoom={this.setRoom}
                                                        renameRoom={this.renameRoom}
                                                        addRoom={this.addRoom}
                                                        deleteRoom={this.deleteRoom}

                                                        renameSticker={this.renameSticker}

                                                        roomsList={this.state.roomsList}

                                                        updateQty={this.updateQty}
                                                        deleteCart={this.deleteCart}
                                                        copyProduct={this.copyProduct}
                                                        copyRoom={this.copyRoom}
                                                        updateCabinetQty={this.updateCabinetQty}
                                                        />

                                                    <TotalCost products={this.state.jobProduct} />

                                                        {

                                                            this.state.jobProduct.length > 0 ?
                                                                <OrderConfirm
                                                                    project={this.state.project}
                                                                    products={this.state.jobProduct}
                                                                    isLogin={this.state.isLogin}
                                                                    doLogout={this.doLogout}
                                                                    redirect={this.redirect}
                                                                    customer={this.state.customer}
                                                                    GST={this.state.gst}
                                                                    JobId={this.state.jobId}
                                                                    addJob={this.addJob}
                                                                    min_order={this.state.min_order}
                                                                    stockCodesDB={this.state.stockCodes}
                                                                    completePayment={this.completePayment}
                                                                    default={this.state.default}
                                                                    />
                                                            : ""

                                                        }
                                                </div>

                                                


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </Fragment>                  


                    }

                    
                <Footer />
                <div className={this.state.blockerClass} />
                <CreateNewProjectModal addproject={this.addproject} open={this.state.noProject} />
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_RIGHT} />
                {/* <LastOrder products={this.state.lastOrder} /> */}
                

            </div>
        );

        }
    }
}

class CreateNewProjectModal extends Component {

    constructor(props) {
      super(props);

      this.state = {
        nameClass : 'form-control',
        errorName: 'hidden',
        name: ''
      }

      this.handleName = this.handleName.bind(this);
      this.submit = this.submit.bind(this);
    }

    handleName(e) {
      this.setState(
        {
          name: e.target.value
        }
      )
    }

    submit() {
        let valid = true;

        const name = this.state.name;

        if (name === '') {

            valid = false;

            this.setState(
                {
                    nameClass : 'form-control error border-danger',
                    errorName: 'd-block',
                }
            )
        }

        if (valid) {
            this.props.addproject(this.state)
        }
    }

    render() {
      return (
        <Modal
            show={this.props.open}
            onHide={this.props.close}
            className="create-new-project"
          >
            <Modal.Header closeButton>
              Project
            </Modal.Header>
            <Modal.Body>

                <div className="form-group">
                    <label>Enter Project Name</label>
                    <input type="text" className={this.state.nameClass} onChange={this.handleName} />
                    <span className={this.state.errorName}>Enter name</span>
                </div>

                <div className="form-group text-right">
                    <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn mr-2">Submit</button>
                    <button onClick={this.reset} type="button" className="btn">Reset</button>
                </div>

            </Modal.Body>
          </Modal>
      )
    }
  }

export default EditOrder;
