import React, { Component } from 'react';
import { withRouter } from 'react-router';

class FooterAdmin extends Component {
    render() {
	
        return (

                    <div className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    COPYRIGHT © 2018 FLAT PACKS WA | <button className="link-button">TERMS &amp; CONDITIONS</button>
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}
export default withRouter(FooterAdmin);