import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Form, Button } from 'react-bootstrap';
import config from "../config";

import Dropzone from 'react-dropzone'
import axios from 'axios';
import swal from "sweetalert";
import { strings } from '../components/strings';
import Modal from 'react-bootstrap-modal';

class Receipt extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);    
    }

    open() {
        this.setState( {open: true} );
    }

    close() {
        this.setState( {open: false} ) ;
    }

    render() {

        const data = this.props.data.Receipts;

        return (
            <>
                <button data-tip="Receipt" onClick={this.open} className="link-btn"><i className="fa fa-receipt" /></button>
                <ReceiptModal 
                    save={this.props.save} 
                    delete={this.props.delete}
                    data={data} 
                    open={this.state.open} 
                    close={this.close} 
                    id={this.props.data.id} 
                    CustomerId={this.props.data.CustomerId}
                    authuser={this.props.authuser}
                />
            </>
        )
    }
}

class ReceiptModal extends Component {

    constructor(props) {

        super(props);

        this.state = {
            data: props.data,
            open: false,
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.buttons = this.buttons.bind(this);
        this.openReceipt = this.openReceipt.bind(this);
    }

    openReceipt(data) {

        const file = data.File;

        if (file.length > 0) {
            window.open(`${config.UPLOAD_URL}uploads/invoices/${file}`);
        }
        
        /* const authuser = this.props.authuser;

        const data = {CustomerId : row.CustomerId, id: row.id, path: `${config.UPLOAD_URL}`};

        fetch(config.BASE_URL + '/api/openinvoice', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            window.open(`${config.UPLOAD_URL}uploads/invoices/${data.invoice.File}`);
        })
        .catch(error => console.log(error));*/
    }

    delete(data) {        
        this.props.delete( {CustomerId: this.props.CustomerId, id: data.id} );
    }

    buttons(cell, row) {
        return (
            <>
                <button onClick={ () => this.delete(row) } className="link-btn"><i className="fa fa-trash" /></button>
                <button onClick={ () => this.openReceipt(row) }  className="link-btn"><i className="fa fa-file-pdf" /></button>
            </>
        )
    }

    save(data) {
        this.setState( { open: false } );
        this.props.save(data);
    }

    open() {
        this.setState( { open:true } );
    }

    close() {
        this.setState( { open:false } );
    }

    AmountFormat(cell) {

        const amount = cell;

        return (
            <>
                {amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </>
        )
    }

    render() {

        const data = this.props.data;

        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text            
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 25,          
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value: data.length > 0 ? data.length : 5
                }
            ]
        };

        const paymentOptions = ['Cash', 'Credit Card'];

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.update
        }

        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="ReceiptModal">
                    <Modal.Header closeButton>
                        Receipt
                    </Modal.Header>
                    <Modal.Body>

                        <BootstrapTable
                            ref='table'
                            data={data}
                            pagination={true}
                            options={options}
                            cellEdit={cellEditProp}
                            search={true} 
                            hover={true}
                            >
                            <TableHeaderColumn 
                                hidden={true}
                                dataField='id'
                                dataSort={true}                                                    
                                isKey={true}>
                                Receipt Id
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='CreatedDate' 
                                dataSort={true}
                                editable={false}                                
                                >Created Date
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='ReceiptNo' 
                                dataSort={true}
                                editable={ {type: 'text'} }
                                >Receipt No
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='PaymentMode' 
                                dataSort={true}
                                editable={ {type: 'select', options: { values: paymentOptions } } }
                                >Payment Mode
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='Amount' 
                                dataSort={true}
                                editable={ {type: 'number'} }      
                                headerAlign="right"                          
                                dataAlign="right"
                                dataFormat={this.AmountFormat}
                                >Amount
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField='Notes' 
                                dataSort={true}
                                editable={ {type: 'textarea'} }
                                >Notes
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataAlign='center'
                                headerAlign='center'
                                editable={false}
                                dataFormat={this.buttons}
                                >
                                <button onClick={this.open} className="btn add-btn purchasebtn"><i className="fa fa-plus" /></button>
                            </TableHeaderColumn>
                                      
                        </BootstrapTable>                       

                        <AddReceiptModal 
                            close={this.close} 
                            open={this.state.open} 
                            save={this.save}       
                            id={this.props.id}      
                            CustomerId={this.props.CustomerId}                              
                            authuser={this.props.authuser} />
                        
                    </Modal.Body>                
                </Modal>
            </>
        )
    }
}

class AddReceiptModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            CustomerId: props.CustomerId,
            ReceiptNo: '',
            InvoiceId: props.id,
            File: '',
            Amount: 0,
            Notes: '',
            PaymentMode: 'Cash',
            uploaded: '',
            InvoiceNoClass : 'form-control',
            AmountClass: 'form-control',
        }

        this.drop = this.drop.bind(this);
        this.handleChange = this.handleChange.bind(this);       
        this.handleSubmit = this.handleSubmit.bind(this);   
    
    }

    

    showProjectList() {

        const projectClass = this.state.projectClass;

        if (projectClass == 'show') {
            this.setState( { projectClass: ''} );
        } else {
            this.setState( {projectClass: 'show'} );
        }
    }

    handleSubmit(e) {
        e.preventDefault();        
        this.props.save(this.state);
    }  

    handleChange(e) {
        this.setState( { [e.target.name] : e.target.value, InvoiceNoClass: 'form-control', AmountClass: 'form-control'} );
    }

    drop(files) {
        
        const data = new FormData();
        
        var authuser = this.props.authuser;
    
        files.forEach((file) => {
            data.append(`file[]`, file, file.name);
        });

        data.append('path', `${config.UPLOAD_PATH}/uploads/invoices/`);        
    
        axios.post(
            config.BASE_URL + "/api/uploadinvoice", 
            data , 
            { 
                headers: { 
                    "Content-Type": "application/json",
		            'Accept': 'application/json',
		            "Authorization" : 'Bearer ' + authuser.token
                 }
            } 
        )
        .then(res => {

            const data = res.data;
            
            this.setState( { File: data.file, uploaded: data.uploaded} );
        });    
    }

    render() {

        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className='addInvoiceModal'
                    >
                    <Modal.Header closeButton>
                        Upload Receipt
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            
                            <Form.Group>
                                <Form.Label>Receipt No.</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="ReceiptNo"
                                    onChange={this.handleChange}
                                    required
                                />                                
                            </Form.Group>                            
                            
                            <Form.Group>

                                <Form.Label>Upload Receipt</Form.Label>

                                <Dropzone
                                    onDrop={this.drop}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="drag-n-drop text-center">
                                                Drag 'n' drop the invoice file here, or click to select the invoice file
                                                <div className="uploaded-invoice">{this.state.uploaded}</div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                            </Form.Group>                           

                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control 
                                    type="number" 
                                    name="Amount"                                 
                                    required
                                    onChange={this.handleChange}
                                />                                
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    name="Notes"
                                    onChange={this.handleChange}
                                    rows="2" 
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Payment Mode</Form.Label>
                                <Form.Control 
                                    as="select"
                                    name="PaymentMode"
                                    onChange={this.handleChange}
                                >
                                    <option value="Cash">Cash</option>
                                    <option value="Credit Card">Credit Card</option>
                                </Form.Control>
                            </Form.Group>
                            <Button className="btn purchasebtn" type="submit">Save</Button>
                        </Form>
                    </Modal.Body>
                    
                </Modal>
            </>
        )
    }
}

export default Receipt;