import React, { Component } from 'react';
import config from "../config";
import Dropzone from 'react-dropzone'
import axios from 'axios';
import Loader from '../components/Loader';

/* const styles = {
	position: "relative",
	padding: "4px 15px",
	fontSize: "16px",
	border: "1px solid rgb(249, 250, 250)",
	background: "#EEE",
	cursor: "pointer",
	borderRadius: "5px"
};*/

class UploadImage extends Component {

	constructor(props) {
      super(props);

      this.state = {
				uploading: false
			}
	}

	onDrop = (acceptedFiles, rejectedFiles) =>{
		const data = new FormData();
		acceptedFiles.forEach((file) => {
			const reader = new FileReader()

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onload = (e) => {

				const binaryStr = e.target.result
				this.setState({
					uploading: true,
					fileData: binaryStr
				});
			}
			reader.readAsDataURL(file);

			//upload image
			data.append('folder', config.UPLOAD_PATH);
			data.append(`myimages[]`, file, file.name);
			axios.post(config.BASE_URL + "/api/uploadfrontimage", data, { } )
			.then(res => {
				this.setState({
					uploading: false
				});
				this.props.onDropImage(res.data.filename || '')
			})
		})
	}

  render() {

	/* const previewStyle = {
		display: 'inline',
		width: 100,
		height: 100,
    } */

    return (
		<>
          <Dropzone
            onDrop={this.onDrop}
						multiple={true}
						accept={'image/jpeg, image/png, application/msword, text/*, application/vnd.ms-excel, application/pdf, text/plain, image/bmp, application/postscript, application/dxf, drawing/x-dxf, application/vnd.openxmlformats-officedocument.wordprocessing'}
          >
            {({getRootProps, getInputProps}) => (
            <div className="menus-dropdown-dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="drag-n-drop text-center">
			  { this.state.uploading ? <Loader /> : <i className="fa fa-image" style={{fontSize: "70px"}} />}
			  Click to select files<br />
				<span style={{fontSize: '10px', display: 'block', lineHeight: '12px'}}> Limited to the following file types .doc, .docx , .xls, .pdf, .txt, .png, .bmp, . jpeg, .DXF, .AI</span>
				</div>
            </div>
            )}
          </Dropzone>

		</>
	)
  }

}
export default UploadImage
