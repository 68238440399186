import React, { Component, Fragment } from 'react';
// import AddRoom from './RequestQuote';
import config from "../config";
import Swal from "sweetalert2";
import ContentEditable from 'react-contenteditable';
import { strings } from './strings';

import $ from 'jquery';

class Room extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roomsList: [{ id: 1, name: "Room 1", products: [], total: 0 }],
            productsArray: [],
            getRooms: props.getRooms,
            roomName: 'Room 1',
            activeRoom: 1,
            productKey : 0,
            cartLimit : 0
        }

        this.cartRef = React.createRef();
       
        this.formatCurrency = this.formatCurrency.bind(this);
        this.truncateText = this.truncateText.bind(this)
        this.noAction = this.noAction.bind(this);
        this.copyProduct = this.copyProduct.bind(this);

      

        this.getroomProduct = this.getroomProduct.bind(this);
        this.setRoom = this.setRoom.bind(this);

        this.toggled = this.toggled.bind(this);
    }

    toggled( roomid ) {
        
        const cart = this.cartRef.current.children;

        const id = `roomlist${roomid}`

        for(let i = 0; i < cart.length; i++ ) {

           if (cart[i].id === id) {

               const panel = cart[i].children[0];

               const header  = panel.children[0];
               const body = panel.children[1];

               header.className = 'panel-heading accordion-toggle';
               body.className = 'panel-collapse collapse in';
               body.style = { height: 'auto' } 

               const cartPanel = body.children[0];

               const products = cartPanel.children[0];  
               
               

           } else {

            const panel = cart[i].children[0];

            const header  = panel.children[0];
            const body = panel.children[1];

            header.className = 'panel-heading accordion-toggle collapsed';
            body.className = 'panel-collapse collapse';

           }

        }

    }

    setRoom(room) {
        this.setState( { activeRoom: room.id, roomName: room.name}, () => {
            this.props.setRoom(room);
        } )
    }

    copyProduct(product, key) {

        this.props.copyProduct(product, key);
    }   


    componentDidMount() {

        this.setState({
            roomsList: this.props.roomsList
        });
    }


    getProducts(roomId) {
        var room = "room" + roomId
        fetch(config.BASE_URL + '/api/getproductsinroom/' + roomId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState({
                [room]: data,
            })

        })
        .catch(error => console.log(error));
    }

    getroomProduct(product, key) {

        this.setState( { productKey: key } );        
        this.props.sendProducts(product, "edit", key);
    }

    deleteProduct(data, productkey) {

        Swal.fire({
            
            title: `${strings.Areyousure}`,
            text: `${strings.Oncedeleted}`,
            icon: "warning",

            focusConfirm: false,
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'

        }).then(willDelete => {
            
            if (willDelete.value) {
            
                var idToRemove = productkey;
                var data = this.props.products;
                var myArr = data.filter((el, key) => {
                    return key !== idToRemove;
                });

                this.setState({
                    productsArray: myArr
                }, () => {
                    let array = [];
                    myArr.map((obj, key) => {
                        array.push(obj.jobProductData);
                        return obj;
                    })

                    this.props.deleteCart(myArr, array, productkey)

                })
            
            } else {
                return false;
            }

        });
    }

    formatCurrency(money){
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(money);
    }

    truncateText (text, limit){
      if (text.length > limit){
          for (let i = limit; i > 0; i--){
              if(text.charAt(i) === ' ' && (text.charAt(i-1) !== ','||text.charAt(i-1) !== '.'||text.charAt(i-1) !== ';')) {
                  return text.substring(0, i) + '...';
              }
          }
           return text.substring(0, limit) + '...';
      }
      else
          return text;
    }

    noAction(){

    }

    render() {

        const productsArray  = this.props.products;

        // console.log('props room', this.props.products);

        let status = 'Pending';

        let roomsList = this.props.roomsList.filter( r => {
            return r.id !== 99;
        })

        roomsList = roomsList.map(room => {

            const products = productsArray.filter((product, key) => {
                product.key = key;

                //console.log('room product', product.jobProductData.RoomId, room.id);

                return product.jobProductData.RoomId === room.id || (room.id === 1 && parseInt(product.jobProductData.RoomId) === 0)
            });

            const total = products && products.length > 0 ? products.reduce(function (tot, arr) {

                return tot + (arr.jobProductData.Price * arr.jobProductData.ProductQty);
            }, 0) : 0;

            room.products = products;
            room.total = total;

            room.products.map( p => {
                if (p.jobProductData.Status === 'Completed' || p.jobProductData.Status === 'In Progress') {
                    status = p.jobProductData.Status
                }

                return p;
            });

            return room;
        });

        var result = productsArray && productsArray.length > 0 ? productsArray.reduce(function (tot, arr) {

            return tot + parseFloat(arr.jobProductData.Price);

        }, 0) : 0;

        localStorage.setItem("TotalPrice", result)

        const productStatus = this.props.productSave;
        const productKey = this.state.productKey;

        //console.log('status', productStatus, productKey);


        return (
            <React.Fragment>
                <div className="add-room">

                    { this.props.page === 'edit' ?
                    
                        <Fragment>
                            <button data-tip="Order Preview" onClick={this.props.preview} style={ { marginRight: "5px" } }><i className="fa fa-eye" /></button>
                        </Fragment>

                        : ''
                    
                    }

                    {
                        <Fragment>  
                            <button data-tip="Copy Room" onClick={this.props.copyRoom} style={ { marginRight: "5px" } }><i className="fa fa-clone" /></button>
                            <button data-tip="Add Room" onClick={this.props.addRoom}><i className="fa fa-plus" /></button>

                        </Fragment>



                    }
                </div>

                
                <div className="panel-group" id="accordion1"  ref={ this.cartRef }>
                    {
                        true  ? (
                            roomsList.map((roomlist, key) => {

                                const isSetRoom = (roomlist.name === 'Accessories') ? false : true;

                                return (
                                    <div id={`roomlist${roomlist.id}`} key={roomlist.id} >
                                        <div id={roomlist.id} className="panel panel-default room-panel" >

                                            <div className={this.props.activeRoomId === roomlist.id ? `panel-heading accordion-toggle` :`panel-heading accordion-toggle collapsed`}
                                                data-toggle="collapse"
                                                data-parent="#accordion1"
                                                onClick={() => isSetRoom ? this.setRoom(roomlist) : this.noAction() }
                                                data-target={"#collapse" + key} >

                                                {key > 0 ? <button className="delete-button" onClick={() => this.props.deleteRoom(roomlist.id) }><i className="fa fa-minus" /></button> : "" }
                                                <h4 className="panel-title">

                                                    <RoomTitle key={key} room={roomlist} rename={this.props.renameRoom} />
                                                    <span>
                                                        {roomlist.products ? roomlist.products.length > 1 ? `${roomlist.products.length} items` : `${roomlist.products.length} item`   : ""}
                                                        <i className="fa fa-angle-down" />
                                                    </span>
                                                </h4>
                                            </div>

                                           

                                            <div id={"collapse" + key} className={ this.props.activeRoomId === roomlist.id ? `panel-collapse collapse in`:`panel-collapse collapse` }>

                                            
                                                <div className="cart-panel-body panel-body">
                                                <ul ref={this.cartProductList} className="productlist" key={"ul" + roomlist.id}>
                                                    {
                                                        //console.log('roomlist.products room', roomlist.products),
                                                        roomlist.products && roomlist.products.length ? roomlist.products.map((roomproduct, key) => {

                                                            return (
                                                                <li key={ "li" + key } className={ productStatus === 'edit' && productKey === roomproduct.key ? 'roomClass edit' : 'roomClass' } id={`room${roomproduct.key}`}>
                                                                    <div className="hding">
                                                                    <h4 style={{marginBottom: '0px' }}>{roomproduct.jobProductData.ProductName}
                                                                    {
                                                                        roomproduct.jobProductData.displayname ? 
                                                                            <span className="display-name">{roomproduct.jobProductData.displayname}</span>
                                                                        : ''
                                                                    }
                                                                    
                                                                    {
                                                                        roomproduct.jobProductData.isCustomized === 1 ?

                                                                            <span className="text-danger small">(Customized Product)</span>

                                                                        : ''
                                                                    }                                                                    
                                                                    </h4>
                                                                        <span>

                                                                                <button className="link-button"
                                                                                            title="Copy"
                                                                                            onClick={() => this.copyProduct(roomproduct, roomproduct.key)}
                                                                                            >
                                                                                            <i className="fa fa-clone"></i>
                                                                                </button>

                                                                               

                                                                                <button
                                                                                    className="link-button"
                                                                                    title="Edit"
                                                                                    onClick={this.getroomProduct.bind(this, roomproduct, roomproduct.key)}
                                                                                    hidden={roomproduct.jobProductData.ProductType && roomproduct.jobProductData.ProductType === 'accessories'}>
                                                                                        <i className="fa fa-edit" />
                                                                                </button>


                                                                            <button className="link-button"
                                                                                    onClick={this.deleteProduct.bind(this, roomproduct.jobProductData, roomproduct.key)}><i className="fa fa-trash" />
                                                                            </button>
                                                                        </span>

                                                                    </div>

                                                                    <StickerName 
                                                                        data={roomproduct.jobProductData} 
                                                                        renameSticker={this.props.renameSticker} 
                                                                        key={roomproduct.key} 
                                                                    />

                                                                      {roomproduct.jobProductData.ProductType && roomproduct.jobProductData.ProductType === 'accessories' ? (
                                                                        <Fragment>
                                                                        <div className="col-md-12" style={{padding: '0px', margin: '0px auto 15px'}}> <img alt={roomproduct.jobProductData.ProductName} style={{float: 'left', width: '75px', marginRight: '10px'}} src={roomproduct.jobProductData.image} />
                                                                        <p style={{fontSize: '14px'}}>{this.truncateText(roomproduct.jobProductData.ProductDescription, 150)}</p>
                                                                        </div>
                                                                        <div className="pdtl col-md-12" style={{margintop: '10px'}}>
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Stock Code: <span>{roomproduct.jobProductData.StockCode}</span></label>
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Price: <span>{this.formatCurrency(roomproduct.jobProductData.HardwareCost)}</span></label>
                                                                            {status === 'In Progress' || status === 'Completed' ?
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Quantity:
                                                                                <span style={{width: '60px', textAlign: 'right', padding:'2px 10px'}}>{roomproduct.jobProductData.fixedQty}</span>
                                                                            </label>
                                                                            :
                                                                            <EditableQuantity data={roomproduct.jobProductData} updateQty={this.props.updateQty} id={roomproduct.key} />

                                                                            }
                                                                            <label style={{ fontSize: "16px", fontWeight: "900" }}>Line Total: <span>{this.formatCurrency(roomproduct.jobProductData.Price)}</span></label>
                                                                        </div>
                                                                        </Fragment>
                                                                      ) : (
                                                                        <Fragment>
                                                                        <div className="row mr-0 ml-0">
                                                                            <div className="pdtl col-md-12">
                                                                                <label>Height:  {roomproduct.jobProductData.Height}</label>
                                                                                <label>Width:  {roomproduct.jobProductData.Width}</label>                                                                                
                                                                                { Number(roomproduct.jobProductData.Depth)  > 0 ?
                                                                                <label>Depth:  {roomproduct.jobProductData.Depth}</label>
                                                                                : ''
                                                                               }
                                                                               <label>Stock Code:  {roomproduct.jobProductData.productStockCode}</label>
                                                                            
                                                                            
                                                                                <div className="pdt-img col-md-5">

                                                                                        { roomproduct.jobProductData.isCustomized == 1 ?
                                                                                            <div className="not-available">
                                                                                                <h2>Customized Product</h2>
                                                                                            </div>
                                                                                            :
                                                                                            <img src={roomproduct.jobProductData.image} />
                                                                                        }

                                                                                </div>
                                                                            
                                                                            </div>

                                                                            
                                                                        </div>

                                                                        <div className="row mr-0 ml-0">

                                                                            <div className="pdtl col-md-8">

                                                                                <label style={{ fontSize: "16px", fontWeight: "900" }}>Price: {"$ "+parseFloat(roomproduct.jobProductData.Price).toFixed(2)}</label>



                                                                            </div>

                                                                            <div className="col-md-4 pl-0 pr-0">

                                                                                        <label className="mb-0 font-weight-normal">Qty:</label>

                                                                                        <div className="d-flex space-between qty-buttons">
                                                                                            <button onClick={ () => this.props.updateCabinetQty('minus', roomproduct) } className="minus-button"><i className="fa fa-chevron-down" /></button>

                                                                                            <div className="quantity-counter">{roomproduct.jobProductData.ProductQty ? roomproduct.jobProductData.ProductQty : 1}</div>

                                                                                            <button onClick={ () => this.props.updateCabinetQty('plus', roomproduct) } className="plus-button"><i className="fa fa-chevron-up" /></button>

                                                                                        </div>




                                                                            </div>

                                                                            {
                                                                                roomproduct.jobProductData.isCustomized === 1 ?

                                                                                    <span className="text-small font-weight-bold text-white bg-warning p-2 mt-2">* this is a customized product, we will contact you with the final price</span>

                                                                                : ''
                                                                            }

                                                                        </div>
                                                                        </Fragment>
                                                                      )}
                                                                </li>
                                                            )
                                                        }) : ""
                                                    }
                                                </ul>
                                                    <div className="room-total-section">Total: <span>{this.formatCurrency(roomlist.total) }</span></div>
                                                </div>
                                            
                                            
                                            
                                            </div>




                                        </div>

                                    </div>
                                )
                            })
                        ) : (
                              <Fragment>
                                <div className="panel panel-default" >
                                    <div className="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion1" data-target={"#collapse1"} style={{ cursor: "pointer" }}>
                                        <h4 className="panel-title">
                                            <label>
                                                <input className="edittitle" type="text" />
                                                <button class="button-link" >Room1 </button></label>
                                            <span >{"0 items"}<i className="fa fa-angle-down" /></span></h4>
                                    </div>
                                    <div id={"collapse1"} className="panel-collapse collapse">
                                        <div className="panel-body">

                                        </div>
                                    </div>
                                </div>

                                </Fragment>
                            )
                    }

                </div>

               
              



            </React.Fragment>
        )
    }
}

class RoomTitle extends Component {

    constructor(props) {

        super(props);

        this.state = {
            id: props.room.id,
            name: props.room.name
        }

        this.rename = this.rename.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState( { name: e.target.value } );
    }

    rename() {
        this.props.rename(this.state);
    }


    componentDidMount() {
        this.setState( {
            id: this.props.room.id,
            name: this.props.room.name ? this.props.room.name : `Room ${this.props.room.id}`
        } )
    }

    render() {

        const isEditable = (this.props.room.name === 'Accessories') ? true : false

        //console.log('room render', this.state.name);
        //console.log('render state', this.state);

        const roomName = this.state.name;

        return (
            <ContentEditable
                id={this.props.room.id}
                html={roomName}
                disabled={isEditable}
                onChange={this.handleChange}
                onBlur={this.rename}
                tagName="label"
            />
        )
    }

}

class StickerName extends Component {

    constructor(props) {
        super(props);

        //console.log('props data', props.data.StickerName, props.id);
        
        this.state = {
            id: props.id,
            cartId: props.data.cartId,
            stickername: props.data && props.data.StickerName.length > 0 ? props.data.StickerName : 'ADD STICKER NAME',
            changing: false
        }

        this.rename = this.rename.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);
    }

    blur() {
        //console.log('blur', this.state);
        if (this.state.stickername === '') {
            this.setState( { stickername : 'ADD STICKER NAME' }, () => {
                this.rename()
            } );
        }  else {
            this.rename();
        }
    }

    focus() {
        if (this.state.stickername === 'ADD STICKER NAME') {
            this.setState( { stickername : '' } );
        }
    }

    handleChange(e) {
        this.setState( { stickername: e.target.value, changing: true } );
    }

    rename() {    
        this.setState( { changing: false }, () => {
            this.props.renameSticker(this.state);
        } )
        
    }

    componentDidUpdate() {
        if ( this.state.cartId !== this.props.data.cartId ) {
            this.setState( { stickername: this.props.data.StickerName.length > 0 ? this.props.data.StickerName : 'ADD STICKER NAME', cartId: this.props.data.cartId } );
        }
    }

    render() {

        return (

            <div key={this.props.data.key}>
              <label style={{fontWeight: '500', color: '#595959'}}>Sticker name: &nbsp;</label>
                <ContentEditable
                    onBlur={this.blur}
                    onFocus={this.focus}
                    data-tip="add new sticker"
                    className="sticker-name tooltip-button"
                    id={this.props.data.key}
                    html={this.state.stickername}
                    onChange={this.handleChange}
                    onBlur={this.blur}
                    tagName="label"
                />
            </div>
        )
    }

}

class EditableQuantity extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            ProductQty: props.data.fixedQty
        }

        this.updateQty = this.updateQty.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeQty = this.changeQty.bind(this);
    }

    handleChange(e) {
        this.setState( {
          ProductQty: e.target.value
        }, () => {
          this.props.updateQty(this.state);
        });
    }

    updateQty() {
        //console.log('this.props', this.props);
        this.props.updateQty(this.state);
    }

    changeQty(qty, name, ev){
      this.setState(function(previousState, currentProps) {
        return {
           ProductQty:  (parseInt(previousState.ProductQty) + qty > 0) ? parseInt(previousState.ProductQty) + qty : 1
        }
      }, () => {
        this.props.updateQty(this.state);
      });
    }

    render() {

        //console.log('this.props', this.props);

        return (
            <label style={{ fontSize: "16px", fontWeight: "900" }}>Quantity:
            <div className="room order_info d-flex flex-row">
            <div className="ProductAddCart product_quantity clearfix">
                <input id="quantity_input" type="text" pattern="[0-9]*" step="1"
                       min="1"
                       max="99" value={this.state.ProductQty.toString()} onChange={this.handleChange}  />
                <div className="quantity_buttons">
                    <div id="quantity_inc_button" onClick={this.changeQty.bind(null, 1)} className="quantity_inc quantity_control"><i
                                className="fa fa-chevron-up"></i></div>
                    <div id="quantity_dec_button" onClick={this.changeQty.bind(null, -1)} className="quantity_dec quantity_control"><i
                                className="fa fa-chevron-down"></i></div>
                </div>
            </div>
            </div>{/*
              <ContentEditable
                id={this.props.data.key}
                html={this.state.ProductQty.toString()}
                onChange={this.handleChange}
                onBlur={this.updateQty}
                tagName="span"
                style={{width: '60px', textAlign: 'right', padding:'2px 10px', border:'1px solid #ddd'}}
            />*/}
            </label>

        )
    }

}

export default Room;
