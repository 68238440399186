import React, { Component } from 'react';
import Header from  '../components/Header';
import Footer from '../components/Footer';
import swal from "sweetalert";
import config from '../config';
import Newsletter from '../components/Newsletter';

class News extends Component {

  constructor(props) {
    super(props);

        this.state = {
          products: {},
          product: {},
          productSave:"save",
          materialArray: [],
          productsArray: [],
          jobProduct: [],
          project: {ProjectName: '', ProjectId: 0},
          isLogin: false,
          noProject: false,
          customerId: 0,
          jcustomer: [],
          jobId: 0,
          RoomId: 1,
          roomData: 'Room 1',
          defaultProduct: [],
          stockCodes: [],
          customer: {Name: '', Email: '', MobileNumber: '', CustomerId: 0}
        }

        this.doLogin = this.doLogin.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.addproject = this.addproject.bind(this);
        this.getActiveProject = this.getActiveProject.bind(this);
        this.goToProducts = this.goToProducts.bind(this);        
        
  }

  doLogout() {
    this.setState({isLogin:false, project: [], customer: [], customerId: 0})
    swal("", "You have been logout!", "success");
}

doLogin() {

    const customer = localStorage.getItem("customer");

    const project = localStorage.getItem("project");

    const jProject = JSON.parse(project);

    const jcustomer = JSON.parse(customer);

    let noProject = true;

    if (jcustomer.projects > 0 || (jProject && jProject.ProjectId > 0)) {
        noProject = false;
    }        

    this.setState(
        {
            isLogin:true, 
            customer : customer,
            jcustomer : jcustomer,
            customerId: jcustomer.CustomerId,
            project: jProject,
            noProject: noProject
        }
    );

    if (noProject) {
        this.getActiveProject(jcustomer.CustomerId);
    }    
    
}

addproject(data) {

  const customer = this.state.jcustomer;
  
  const params = {name: data.name, customerId: this.state.customerId, clientName: customer.Name, contactNo: customer.MobileNumber, email:customer.Email}

  fetch(config.BASE_URL + '/api/addnewproject', {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json'
      }
    })
    .then(data => data.json())
    .then(data => {

      let customer = JSON.parse(this.state.customer);

      customer.projects = 1;

      localStorage.setItem("customer", JSON.stringify(customer));
      localStorage.setItem("project", JSON.stringify(data));
      
      this.setState({noProject: false, project: data})
      
    })
    .catch(error => console.log(error)); 
  
}

getActiveProject(customerId) {
  fetch(config.BASE_URL + '/api/get-active-project/' + customerId , {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {
      if (data.length > 0) {
          this.setState({
              noProject: false,
              project: data[0]
          });
      }
  })
  .catch(error => console.log(error));
}

goToProducts(productId, productSave) {
        
  fetch(config.BASE_URL + '/api/getproductbyId/' + productId, {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {         
     
      //console.log('got product'); console.log(data[0]);

     this.setState({ products: data, product: data[0], productSave: productSave })
  })
  .catch(error => console.log(error));
}

  render() {
    return (
      <>
        <Header 
            page="news"
            saveDashboard={this.saveDashboard}
            saveProject={this.saveProject}
            sendProducts={this.goToProducts} 
            doLogin={this.doLogin} 
            doLogout={this.doLogout}
            project={this.state.project}
            saveLogin={this.saveLogin}
            isLogin={this.state.isLogin} />

        <Content />    
        
        <Footer />   
      </>
    )
  }
}

class Content extends Component {

  render() {

    return (

    <>

      <div className="top-background main-banner">
        <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>News</h1>

              </div>
            </div>
        </div>
      </div>

      <div className="main-content">

        <div className="container">

            <div className="row news-article">


              <div className="col-md-8">

                  <h1>Why 2020 is the year to persist with change</h1>

                  <p>As sponsors of the KBDi (Kitchen and Bathroom Designers Institute), we recently had an article published for their readership. Check out the link here or read our transcript below.</p>

                  <p>With every New Year comes new resolutions and promises, both personal and professional. Unfortunately, it’s typically only a few months, weeks or even days later that the old routine simply becomes the usual routine again. Well now it’s not just a New Year, it’s a new DECADE and certainly time to persist with change. Now presents the best opportunity to enhance your design business’ efficiency, capabilities and overall performance.</p>

                  <h3>Switch wasted time for valuable time</h3>

                  <p>“Too busy”, “don’t have enough time”. These are the common excuses that prevent people and businesses from persisting with or even starting with a beneficial change. However, often our time is wasted by existing activities that could be minimised or even erased by embracing that change or a new advancement.</p>

                  <p>In the world of design most of our time is spent doing just that … designing. Drawing or sketching takes up so much time that we can’t focus on other factors like marketing ourselves or taking on new clients. Design software like KD Max greatly reduces time spent drawing (or worse yet, redrawing) designs. This time being saved counters any initial learning curve and frees you up to focus your attention to other goals like marketing, sales or training.</p>

                  <h3>Increasing your offering | Start with the minimum</h3>

                  <p>It can be hard to fully embrace a change from the get-go, no matter how much it can improve your life. The solution is simple. Start with the minimum. Experts recommend starting with the minimum you can do. Eg. A person who doesn’t floss can be encouraged to start by doing something as simple as flossing one tooth a night for a few seconds.</p>

                  <p>Many designers have utilised the goCabinets ordering system to upgrade their offering and supply the cabinetry from their designs to their client. You’ve already created the design, it’s just the simple step of inputting those cabinetry details into the goCabinets system.</p>

                  <p>You may be hesitant to order an entire kitchen for a client without dipping a toe in the water first. goCabinets encourages first time users to start with an order of a smaller nature. It’s not time consuming to place the order and allows you to recognise the quality of cabinetry which you can provide your clients. Maybe you’re designing the whole kitchen and bedroom for a client. An opportunity to initially test the goCabinets system may be to simply supply the bedroom cupboards or wardrobe. Your client’s custom cabinetry can be set for production and delivery straight to your chosen location.</p>

                  <p>Use the beginning of our new decade to test something new and see how you can create a healthier, stronger business case. Utilise that new service or invest in that new software you’ve been considering. You may find it easier than you ever thought.</p>


                  
              </div>

              <div className="col-md-4 sidebar">

                  <h4>LATEST NEWS ARTICLES</h4>

                  <ul>
                    <li><a href="/news/">Why 2020 is the year to persist with change<br />Posted on: Feb 7th, 2020</a></li>
                    <li><a href="/news/">Why 2020 is the year to persist with change<br />Posted on: Feb 7th, 2020</a></li>
                    <li><a href="/news/">Why 2020 is the year to persist with change<br />Posted on: Feb 7th, 2020</a></li>
                    <li><a href="/news/">Why 2020 is the year to persist with change<br />Posted on: Feb 7th, 2020</a></li>
                    <li><a href="/news/">Why 2020 is the year to persist with change<br />Posted on: Feb 7th, 2020</a></li>
                  </ul> 

                  <h4>SIGNUP FOR NEWSLETTER</h4>

                  <Newsletter />
                  
              </div>

            </div>

        </div>

      </div>

    </>

    )

  }

}

export default News
