import React, { Component } from 'react';
import Header from  '../components/Header';
import Footer from '../components/Footer';
import swal from "sweetalert";
import config from '../config';

class About extends Component {

  constructor(props) {
    super(props);

        this.state = {
          products: {},
          product: {},
          productSave:"save",
          materialArray: [],
          productsArray: [],
          jobProduct: [],
          project: {ProjectName: '', ProjectId: 0},
          isLogin: false,
          noProject: false,
          customerId: 0,
          jcustomer: [],
          jobId: 0,
          RoomId: 1,
          roomData: 'Room 1',
          defaultProduct: [],
          stockCodes: [],
          customer: {Name: '', Email: '', MobileNumber: '', CustomerId: 0}
        }

        this.doLogin = this.doLogin.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.addproject = this.addproject.bind(this);
        this.getActiveProject = this.getActiveProject.bind(this);
        this.goToProducts = this.goToProducts.bind(this);        

        this.saveDashboard = this.saveDashboard.bind(this);
        
  }

  saveDashboard() {

    window.location = '/dashboard'

  }

  doLogout() {
    this.setState({isLogin:false, project: [], customer: [], customerId: 0})
    swal("", "You have been logout!", "success");
}

doLogin() {

    const customer = localStorage.getItem("customer");

    const project = localStorage.getItem("project");

    const jProject = JSON.parse(project);

    const jcustomer = JSON.parse(customer);

    let noProject = true;

    if (jcustomer.projects > 0 || (jProject && jProject.ProjectId > 0)) {
        noProject = false;
    }        

    this.setState(
        {
            isLogin:true, 
            customer : customer,
            jcustomer : jcustomer,
            customerId: jcustomer.CustomerId,
            project: jProject,
            noProject: noProject
        }
    );

    if (noProject) {
        this.getActiveProject(jcustomer.CustomerId);
    }    
    
}

addproject(data) {

  const customer = this.state.jcustomer;
  
  const params = {name: data.name, customerId: this.state.customerId, clientName: customer.Name, contactNo: customer.MobileNumber, email:customer.Email}

  fetch(config.BASE_URL + '/api/addnewproject', {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json'
      }
    })
    .then(data => data.json())
    .then(data => {

      let customer = JSON.parse(this.state.customer);

      customer.projects = 1;

      localStorage.setItem("customer", JSON.stringify(customer));
      localStorage.setItem("project", JSON.stringify(data));
      
      this.setState({noProject: false, project: data})
      
    })
    .catch(error => console.log(error)); 
  
}

getActiveProject(customerId) {
  fetch(config.BASE_URL + '/api/get-active-project/' + customerId , {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {
      if (data.length > 0) {
          this.setState({
              noProject: false,
              project: data[0]
          });
      }
  })
  .catch(error => console.log(error));
}

goToProducts(productId, productSave) {
        
  fetch(config.BASE_URL + '/api/getproductbyId/' + productId, {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {         
     
      //console.log('got product'); console.log(data[0]);

     this.setState({ products: data, product: data[0], productSave: productSave })
  })
  .catch(error => console.log(error));
}

 

  render() {
    return (
      <>
        <Header 
            page="about-us"
            saveDashboard={this.saveDashboard}
            saveProject={this.saveProject}
            sendProducts={this.goToProducts} 
            doLogin={this.doLogin} 
            doLogout={this.doLogout}
            project={this.state.project}
            saveLogin={this.saveLogin}
            isLogin={this.state.isLogin} />

        <Content />    
        
        <Footer />   
      </>
    )
  }
}

class Content extends Component {

  render() {

    return (

    <>

      <div className="top-background about-us main-banner" style={ {backgroundImage: "url(/images/about-us-banner.jpg)" } }>
        <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>About Flat Packs Australia</h1>

              </div>
            </div>
        </div>
      </div>

      <div className="main-content">

        <div className="container">

            <div className="roww">

              <div className="col-md-12">             

                <p>Flat Packs Australia, owned by Brendan Menner, is a manufacturer of custom order flat pack cabinetry suitable for all domestic and commercial applications. Based in Bibra Lake, WA, we’re committed to crafting the highest quality custom cabinetry.</p>
                <p>Everything that we do is designed to fit our customers’ space and requirements and we use the latest technology to design and craft cabinets. No two orders are ever alike and we pride ourselves on our customer focus. We look forward to working with you to create your ideal cabinet solution.</p>

                <div className="row">

                  <div className="col-md-5">
                    <img alt="cmawa" src="/images/cmawa-color.png" />
                    <br /><br />
                  </div>
                </div>

                <div className="row">

                  <div className="col-md-6">

                    <img alt="about us" src="/images/about-us-2020.png" />

                  </div>

                  <div className="col-md-6">

                    <img alt="about us" src="/images/about-us-image-2.png" />

                  </div>

                </div>

                <br />
                <br />


                <h3>Products and Services</h3>

                <div className="row">

                  <div className="col-md-6">

                    <h4>Order cabinets online</h4>

                    <p>Tradespeople and builders can become members of Flat Packs Australia. It’s free to join and easy to set up. Save time by sending your specifications and design choices straight through to our CNC machinery the cabinets will be cut to size, packaged and ready for pick-up.</p>  

                    <h4>In-house cabinetry design services</h4>

                    <p>Need a complete design service? Upload your sketches and specifications and our designers will give you a free quote and 3D drawings, or come in and see us to discuss your options. </p>
                    
                    <h4>Upload your designs in our members portal</h4>
                    
                    <p>Flat Packs Australia is not just a manufacturer. We’re designers as well, and can take your idea or drawing from inception to completion. Already have a design for your space, let Flatpacks Australia complete the process…</p>

                    <p>Simply send us over what you have created “Chook Scratching” then give us a call.</p>

                    


                  </div>

                  <div className="col-md-6">

                    

                    <h4>Book our Showroom</h4>

                    <p>Flatpacks Australia offers exclusive showroom tours and appointments with our expert consultants. Alongside our experts, you will explore all the different cabinet styles, finishes, and materials we have to offer as well as top-brand hardware and appliances which are on display and available for purchase.</p>

                    <h4>Factory Hire</h4>

                    <p>Flat Pack Australia clients are able to hire bench space in our factory to assemble their cabinets. Tradespeople are able to use some of our machinery such as saws or the hinge-boring machine. Air is supplied for air-pin guns, but tradespeople must supply their own hand tools.</p>

                  </div>


                </div>

              </div>



            </div>

        </div>

      </div>

    </>

    )

  }

}

export default About
