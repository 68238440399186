import React, { Component } from 'react';
import config from "../config";
import Login from './Login';
/* import $ from 'jquery'; */

import TopMenu from './TopMenu';
import GlobalMessage from './GlobalMessage';
import { strings } from './strings';

class Header extends Component {
    constructor(props) {

        super(props);

        this.state = {
            products: {},
            categories: [],
            subCategories: [],
            navProducts: [],
            getProducts: [],
            project: props.project,
            subMenu: false,
            responsiveButtonStatus: "navbar-toggle collapsed",
            subMenuStatus: "collapse navbar-collapse"
        }

        this.getCategory = this.getCategory.bind(this);
        this.getSubCategories = this.getSubCategories.bind(this);
        this.getAllProducts = this.getAllProducts.bind(this);
        this.subMenu = this.subMenu.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.goToProducts = this.goToProducts.bind(this);
        this.welcome = this.welcome.bind(this);
        this.setSubMenuStatus = this.setSubMenuStatus.bind(this);
    }


    setSubMenuStatus() {
        if (this.state.subMenu) {
            this.setState( { subMenu:false, subMenuStatus: "navbar-collapse collapse"} )
        } else {
            this.setState( {subMenu: true, subMenuStatus: "navbar-collapse collapse in"} );
        }
    }

    welcome() {

        const customer = JSON.parse(localStorage.getItem('customer'));
        const customername = customer && customer.DisplayName ? customer.DisplayName : customer && customer.Name  ? customer.Name : ''
        //console.log('customer', customer)
        if (customer && customer.CustomerId > 0) {
            return (
                <div className="welcome">
                   {strings.WelcomeBack.replace('[name]', customername)}
                </div>
            )
        } else {
            return (
                <div className="welcome" />
            )

        }
    }

    getProducts(categoryId, subCategoryId, cs) {
        var products = this.state.navProducts.filter(product => product.ProductCategoryId === categoryId && product.ProductSubCategoryId === subCategoryId);

        const nav = products.map(product =>
            <li key={product.ProductId} className="top-link-item">
                <Product product={product} gotoProduct={this.goToProducts}  />
            </li>
            )

        if (products.length > 0) {
            return (
                <ul className={cs}>
                    {nav}
                </ul>
            )
        }
        else
            return "";
    }

    subMenu(category, subcategories, products) {
        var subs = subcategories.filter(subcategory => subcategory.ProductCategoryId === category.ProductCategoryId);


        if (subs.length > 0) {

            const Subs = subs.map(sub =>

                <div key={sub.ProductSubCategoryId} className="col-sm-12 col-xs-12 col-md-12 bhoechie-tab-container">
                    <div className="col-sm-4 col-xs-4 col-md-4 bhoechie-tab-menu">
                        <div className="list-group">
                            <button className="link-button" className="list-group-item active text-center" style={{color:"#fff"}}>{sub.SubCategory}</button>
                        </div>
                    </div>
                    <div className="col-sm-7 col-xs-7 col-md-8 bhoechie-tab">
                        <div className="bhoechie-tab-content active">
                            <center>
                                {this.getProducts(category.ProductCategoryId, sub.ProductSubCategoryId, 'tabsubsubmenu col-sm-12 col-xs-12 col-md-6')}
                                <div className="col-sm-12 col-xs-12 col-md-6 menu-imgbox">
                                    <img className="subm1" src="/images/menuimg.jpg" />
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
                );

            return (
                <div className="tabsubmenu dropdown-menu" id={category.slug}>
                    {Subs}
                </div>
            )
        }

        return this.getProducts(category.ProductCategoryId, null, 'dropdown-menu');
    }

    getAllProducts() {

        const customer = localStorage.getItem("customer");

        const jcustomer = JSON.parse(customer);

        const Token = jcustomer.token || '';

        fetch(config.BASE_URL + '/api/getallproducts', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",

            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState({
                navProducts: data,
            });
        })
        .catch(error => console.log(error));
    }

    getSubCategories() {
        fetch(config.BASE_URL + '/api/getallsubcategories', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState(
                {
                    subCategories: data
                }
            )
        })
        .catch(error => console.log(error));
    }

    componentDidMount() {

        //this.getCategory();
        //this.getSubCategories();
        //this.getAllProducts();
        //this.goToProducts(1,"none");

        if (this.props.project) {
            this.setState({project: this.props.project})
        }
    }


    getCategory() {
        fetch(config.BASE_URL + '/api/getcategory', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(data => data.json())
            .then(data => {
                this.setState({
                    categories: data,
                });
            })
            .catch(error => console.log(error));
    }


    goToProducts(productId) {
        this.props.sendProducts(productId, "addToCart");
        this.setState( {subMenuStatus: "collapse navbar-collapse", subMenu: false } );
    }

    render() {

        const { categories, subCategories, navProducts, getProducts } = this.state

        //console.log('header', this.props);

        const ProjectName = this.props.project ? this.props.project.ProjectName : '';

        const welcome = this.welcome();

        return (
            <header>
                <div className="secondary-header">
                    <div className="container">
							<GlobalMessage />
                            {welcome}



                            <Login
                                page={this.props.page}
                                saveDashboard={this.props.saveDashboard}
                                saveProject={this.props.saveProject}
                                doLogin={this.props.doLogin}
                                doLogout={this.props.doLogout}
                                isLogin={this.props.isLogin}
                                savingStatus={this.props.savingStatus} />


                    </div>
                </div>

                <div className="container headertop">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3 logobox">
                            <a href="/"><img src={config.TOP_LOGO} alt="" /></a>
                            {config.VERSION}
                        </div>

                        <div className="col-xs-5 col-sm-3 col-md-3">
                            <div className="project-title">{ProjectName}</div>
                        </div>

                        <div className="col-xs-7 col-sm-6 col-md-6 header-right">
                            Design Your Own Flatpack | Customer Support { config.PHONE }
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 nav-main">
                    <div className="container">
                        <div className="row">
                            <nav className="col-xs-12 col-sm-12 col-md-12 navbar navbar-default pull-right">
                                <div className="navbar-header responsivenav">
                                    <span className="for-res-menu responmenu">Menu</span>
                                    <button type="button" className={this.state.responsiveButtonStatus} onClick={this.setSubMenuStatus}>
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                    </button>
                                </div>

                                <div className={this.state.subMenuStatus}>
                                    <TopMenu gotoProduct={this.goToProducts} page={this.props.page} />
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: this.props.product
        }

        this.gotoProduct = this.gotoProduct.bind(this);
    }

    gotoProduct() {
        this.props.gotoProduct(this.state.product.ProductId);
    }

    render() {

        return(
            <button className="top-product-link" onClick={this.gotoProduct}>
                {this.props.product.ProductName}
            </button>
        )
    }
}
export default Header;
