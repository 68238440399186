import React, { Component } from 'react';
import { strings } from './strings';

class TotalCost extends Component {

    constructor(props) {

        super(props);

        this.formatCurrency = this.formatCurrency.bind(this);

    }

    formatCurrency(money){
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(money);
    }

    render() {

        var result = this.props.products && this.props.products.length > 0 ? this.props.products.reduce(function (tot, arr) {
            return tot + (parseFloat(arr.jobProductData.Price) * parseFloat(arr.jobProductData.ProductQty));
        }, 0) : 0;

        return (

            <>
                <div className="total-cost-section">
                    <div className="row">
                        <div className="col-md-7" style={{fontSize: '17px'}}>
                            {strings.TotalCost}:
                        </div>
                        <div className="col-md-5">
                            <div className="text-right d-block">{this.formatCurrency(result)}</div>
                            <div className="text-small d-block text-right text-warning font-weight-normal">GST Included</div>
                        </div>
                    </div>
                </div>
            </>

        )
    }

}

export default TotalCost