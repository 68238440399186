import React, { Component } from 'react';
import { withRouter } from 'react-router';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import swal from "sweetalert";

class Messages extends Component {
    constructor(props) {
        super(props);
		var authuser = JSON.parse(localStorage.getItem('authuser'));
        this.state = {
			AuthUser: (authuser) ? authuser[0] : [],
            Message: '',
			Checked: true
        };
        this.getMessage = this.getMessage.bind(this)
		this.handleChecked = this.handleChecked.bind(this)
    }

    componentWillMount() {
        this.getMessage();
    }

	handleChange = (Message) => {
		this.setState({
			Message: Message
		}, () => {
			//console.log(this.state);
		})		
	};
	handleChecked = () =>{
		this.setState({
			Checked: !this.state.Checked
		}, () => {
			//console.log(this.state);
		})
	}
    getMessage() {
		var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/getmessage', {
            method: "GET",
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}			
        })
		.then(data => data.json())
		.then(data => {
			this.setState({
				Message: data.Content,
				Checked: (data.Status === 1) ? true : false  
			})
		})
		.catch(error => console.log(error));
    }

	handleFormSubmit = () =>{
		var authuser = this.state.AuthUser;
		var data = {
			Message: this.state.Message,
			Checked: this.state.Checked
		};
        fetch(config.BASE_URL + '/api/updatemessage', {
            method: "POST",
			body: JSON.stringify(data),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}			
        })
		.then(data => data.json())
		.then(data => {
			swal('', 'Update successful', 'success');
		})
		.catch(error => console.log(error));		
	}
	
	modules = {
		toolbar: [
		  [{ 'header': [1, 2, false] }],
		  ['bold', 'italic', 'underline','strike', 'blockquote'],
		  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
		  ['link', 'image'],
		  ['clean']
		]
	};

	formats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link', 'image'
	];
		
    render() {

        const { Checked } = this.state	
        return (
		
            <div className="admin-wrapper">
				<HeaderAdmin />
                <div className="dashboard-wrapper partscontent">
                    <div className="dashboardhome">
                        <div className="container-fluid dashboard-content jobspagecnt">
                            {/* ============================================================== */}
                            {/* pagecontent  */}
                            {/* ============================================================== */}
							<div className="row">
                            <div className="col-md-12">
                                <ol className="breadcrumb breadm">	
                                    <li><a href="/admin">Admin</a></li>
                                    <li ><i className="fa fa-angle-right midarrow" /><a href="/admin/messages" style={{ cursor: "pointer" }}>Global Message</a></li>
                                </ol>
                            </div>
							</div>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">							
										<form autocomplete="false">
											<div className="form-group">
												<label htmlFor="name-data" className="col-form-label">Message Content</label>
												<ReactQuill value={this.state.Message}
												onChange={this.handleChange}
												theme="snow"
												modules={this.modules}
												formats={this.formats}												
												/>
											</div>
											<div className="form-group">
												<p  className="greenCheckBox" style={{marginBottom: "0px", marginTop: "0px"}}>
													<label>
														<input type="checkbox" checked={Checked} value="1" onClick={this.handleChecked} /> 				
														<span className="checkmark1"></span>
														Active / Inactive
													</label>
												</p>											
											</div>
											<div className="form-group">
											<button type="button" className="btn btn-primary" onClick={this.handleFormSubmit.bind(this)}>
												Save
											</button>
											</div>
										</form>                                    
                                </div>
                            </div>
                            {/* ============================================================== */}
                            {/* end pagecontent  */}
                            {/* ============================================================== */}
                        </div>
                    </div>
                    <FooterAdmin />
                </div>
            </div>					
        )
    }
}
export default withRouter(Messages);