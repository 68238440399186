import React, { Component, Fragment } from 'react';
import Header from '../components/Header';
import ProductHome from '../components/ProductHome';
import ProductHomePanel from '../components/ProductHomePanel';
import Accessories from '../components/Accessories';
import Footer from '../components/Footer';
import Modal from 'react-bootstrap-modal';
import swal from "sweetalert";
import config from '../config';
import { strings } from '../components/strings';
import Cart from '../components/Cart';
import activate from '../components/Activate';
import Loader from '../components/Loader';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import Room from '../components/Room'
import AccessoriesRoom from '../components/AccessoriesRoom';
import TotalCost from '../components/TotalCost';
import OrderConfirm from '../components/OrderConfirm';
import { saveCartToDB } from '../components/AuthService';

//import $ from 'jquery';

class ProductsPage extends Component {
    constructor() {
        super();

        this.state = {
            products: {},
            product: {},
            productSave:"save",
            materialArray: [],
            productsArray: [],

            jobProduct: [],

            updateKey: '',

            project: {ProjectName: '', ProjectId: 0},
            isLogin: false,
            noProject: false,
            customerId: 0,
            jcustomer: [],
            jobId: 0,
            RoomId: 1,
            roomData: 'Room 1',
            defaultProduct: [],
            stockCodes: [],
            blockerClass: 'blocker d-none',

            gst: 0,
            min_order: 100,
            material: [],
            materialType: [],
            brand: [],
            substrate: [],
            hinge: [],
            hardware: [],
            door: [],
            layouts: [],
            materialDefault: [],
            maxShelve: 0,
            minShelve: 0,
            minDrawer: 0,
            maxDrawer: 0,
            minFixedShelve: 0,
            maxFixedShelve: 0,
            minAdjustableShelve: 0,
            maxAdjustableShelve: 0,

            minDrawerEqual: 0,
            maxDrawerEqual: 0,

            minDrawerSmall: 0,
            maxDrawerSmall: 0,

            minDrawerInner: 0,
            maxDrawerInner: 0,

            ProductType: '',

            customer: { Name: '', Email: '', MobileNumber: '', CustomerId: 0 },

            pickupOption: '',

            isCustomized: 0,

            loading: true,

            productSave: 'addToCart',

            roomsList: [{ id: 1, name: 'Room 1'}],

            roomMaterials: [],

            cartLimit: 25,

            cartId : 0
   
        }

        this.cartRef = React.createRef();

        //this.getProducts = this.getProducts.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.addproject = this.addproject.bind(this);
        this.getActiveProject = this.getActiveProject.bind(this);
        this.goToProducts = this.goToProducts.bind(this);

        this.addToCart = this.addToCart.bind(this);
        this.updateCart = this.updateCart.bind(this);
        this.deleteCart = this.deleteCart.bind(this);

        this.getEditProducts = this.getEditProducts.bind(this);
        this.saveProject = this.saveProject.bind(this);
        this.saveDashboard = this.saveDashboard.bind(this);

        this.saveLogin = this.saveLogin.bind(this);

        this.setRoom = this.setRoom.bind(this);
        this.renameRoom = this.renameRoom.bind(this);
        this.completePayment = this.completePayment.bind(this);
        this.renameSticker = this.renameSticker.bind(this);
        this.updateQty = this.updateQty.bind(this);

        this.copyProduct = this.copyProduct.bind(this);
        this.copyRoom = this.copyRoom.bind(this);
        this.addRoom = this.addRoom.bind(this);
        this.deleteRoom = this.deleteRoom.bind(this);

        this.addJob = this.addJob.bind(this);
        this.locateParent = this.locateParent.bind(this);

        this.updateCabinetQty = this.updateCabinetQty.bind(this);
    
    }

    

    updateCabinetQty(action, item) {

        let qty = item.jobProductData.ProductQty;

        if (action === 'minus') {

            if (qty > 1) {
                qty -= 1;
            }

        }

        if (action === 'plus') {

            qty += 1;
        }

        const jobProduct = this.state.jobProduct; //JSON.parse(localStorage.getItem("jobProduct"));

        jobProduct.map( (job, key) => {

            if (key === item.key) {

                job.jobProductData.ProductQty = qty;

            }

            return job;

        });

        //localStorage.setItem("jobProduct", JSON.stringify(jobProduct));

        this.setState( { jobProduct: jobProduct } );

    }

    addJob(products, project, customer, address1, address2, address3, Token) {

        fetch(config.BASE_URL + '/api/addJob', {
            method: "POST",
            body: JSON.stringify({products, project, customer, address1, address2, address3}),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + Token
            }
        })
        .then(data => data.json())
        .then(data => {

              this.setState({
                  jobId: data.insertId
              })
        })
        .catch(error => console.log(error));
    }


    completePayment() {

        const address1 = ""; //data.address1;
        const address2 = ""; //data.address2;
        const address3 = ""; //data.address3;
        const customer = this.state.customer;
        const project = this.state.project;
        const products = this.state.jobProduct;
        const jobId = this.state.jobId;
        const pickupOption = this.state.pickupOption
        var customer_obj = JSON.parse(this.state.customer);

        var Token = customer_obj.token;

        const status = 'In Progress'

        fetch(config.BASE_URL + '/api/updateJob', {
            method: "POST",
            body: JSON.stringify({jobId, products, project, customer, address1, address2, address3, status, pickupOption}),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {

            localStorage.removeItem('JobId');
            localStorage.removeItem('postData');
            localStorage.removeItem('jobProduct');

            this.setState( { products: [], jobProduct: [], jobId: 0 }, () => {
                window.location = '/dashboard';
            } );

            //setTimeout(() => this.setState({ redirect: true }), 1000)


        })
        .catch(error => console.log(error));

    }

    renameRoom(room) {

        let jobProduct = this.state.jobProduct.map(job => {

            if (job.jobProductData.RoomId === room.id) {
                job.jobProductData.roomData = room.name
            }

            return job

        });

        localStorage.setItem("jobProduct", JSON.stringify(jobProduct));

        this.setState( {jobProduct: jobProduct} )


    }

    renameSticker( data ) {

        //console.log('data', data);

        const jobProduct = this.state.jobProduct.map( j => {

            if (j.cartId === data.cartId) {

                j.jobProductData.StickerName = data.stickername

            }

            return j;

        });

        this.setState( { jobProduct } );

        localStorage.setItem('jobProduct', JSON.stringify( jobProduct ) )

        /* const jobProduct = this.state.jobProduct;

        const j = jobProduct.slice(sticker.id, sticker.id + 1);

        j[0].jobProductData.StickerName = sticker.stickername;

        jobProduct.splice(sticker.id, 1, j[0]);

        //console.log('sticker');

        let jobs = JSON.parse(localStorage.getItem("jobProduct"));

        jobs.splice(sticker.id, 1, j[0]);

        this.setState( { jobProduct: jobProduct } );

        localStorage.setItem('jobProduct', JSON.stringify(jobs)); */

    }

    updateQty(item) {


      let jobProduct = this.state.jobProduct.map(job => {
            if (job.key === item.id) {
                job.jobProductData.fixedQty = item.ProductQty
                job.jobProductData.Price = parseFloat(job.jobProductData.HardwareCost) * parseInt(item.ProductQty)
            }

            return job
        });



        this.setState( {jobProduct: jobProduct} )
    }

    setRoom(room) {


        this.setState( { RoomId: room.id, roomData: room.name, productSave: "addToCart" } )


    }

    addRoom() {

        const rooms = this.state.roomsList;

        let last = rooms[rooms.length  - 1];

        if (last.id === 99) {
            last = rooms[rooms.length - 2];
        }

        const newRoomId = last.id + 1;

        const newRoomName = `Room ${newRoomId}`;

        const newRoom = {id: newRoomId, name: newRoomName, products: [] }

        rooms.push(newRoom);

        localStorage.setItem("roomsList", JSON.stringify(rooms) );

        this.setState( { roomsList: rooms, RoomId: newRoomId, roomData: newRoomName } );

    }

    deleteRoom(id) {

        const room = this.state.roomsList.filter(room => {
            return room.id === id
        });

        if (room.length > 0 && room[0].products.length > 0) {
            swal({
                title: "There are products in the room",
                text: "Are you sure you want to delete this room, you will not be able to recover this record",
                icon: "warning",
                buttons: true,
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {

                    const productToRemove = room[0].products;

                    let keyToRemove = [];
                    let productsArray = this.state.jobProduct;

                    productToRemove.map(product => {
                        keyToRemove.push(product.key);
                        return product;
                    });

                    const products = productsArray.filter((product, key) => {
                        console.log('keys', key, keyToRemove, keyToRemove.indexOf(key));
                        return keyToRemove.indexOf(key) === -1
                    });

                    const rooms = this.state.roomsList.filter(room => {
                        return room.id !== id;
                    });

                    const postData = products.map(p => {
                        return p.jobProductData;
                    })



                    this.setState( {
                                productsArray: postData,
                                roomsList: rooms ,
                                RoomId: this.state.roomsList[0].id,
                                roomData: this.state.roomsList[0].name,
                                productSave: "addToCart",
                                jobProduct : products
                        }, () => {

                            localStorage.setItem('roomsList', JSON.stringify(rooms));
                            localStorage.setItem("jobProduct", JSON.stringify(products));
                            localStorage.setItem("postData", JSON.stringify(postData));

                            this.goToProducts(1);
                        } );

                } else {
                    return false;
                }
            });
        } else {
            const rooms = this.state.roomsList.filter(room => {
                return room.id !== id;
            });

            localStorage.setItem('roomsList', JSON.stringify(rooms));

            this.setState( { roomsList: rooms , RoomId: this.state.roomsList[0].id, roomData: this.state.roomsList[0].name, productSave: "addToCart"} );

        }

    }

    saveLogin(data) {
        this.setState(
            {
                isLogin: true,
                jcustomer: data.customer,
                project: data.project,
                customerId: data.customer.CustomerId
            }
        )
    }

    saveDashboard() {

        const address1 = ""; //data.address1;
        const address2 = ""; //data.address2;
        const address3 = ""; //data.address3;
        const customer = this.state.customer;
        const project = this.state.project;
        const products = this.state.jobProduct;
        const jobId = this.state.jobId;

        this.setState( {blockerClass: 'blocker d-block'} );

        if (products.length > 0) {

			var customer_obj = JSON.parse(this.state.customer);
			var Token = customer_obj.token;

            fetch(config.BASE_URL + '/api/saveJob', {
            method: "POST",
            body: JSON.stringify({jobId, products, project, customer, address1, address2, address3}),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
            })
            .then(data => data.json())
            .then(data => {

                window.location = '/dashboard'

                this.setState( {blockerClass: 'blocker d-none'} );

            })
            .catch(error => console.log(error));
        } else {

            window.location = '/dashboard'

            this.setState( {blockerClass: 'blocker d-none'} );
        }

    }


    saveProject() {

        const address1 = ""; //data.address1;
        const address2 = ""; //data.address2;
        const address3 = ""; //data.address3;
        const customer = this.state.customer;
        const project = this.state.project;
        const products = this.state.jobProduct;

        var customer_obj = JSON.parse(this.state.customer);

        //console.log('customer_obj', customer_obj);

        var Token = customer_obj.token;

        this.setState( {blockerClass: 'blocker d-block'} );

        fetch(config.BASE_URL + '/api/saveJob', {
            method: "POST",
            body: JSON.stringify({products, project, customer, address1, address2, address3}),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState({
                jobId: data
            }, () => {

                swal("", `${strings.Saving}`, "success").then(
                    () => {
                        this.setState( {blockerClass: 'blocker d-none'} );
                        // window.location = `/dashboard/edit-order/${data}`
                    }
                );

            })

        })
        .catch(error => console.log(error));

    }


    getEditProducts(product, edit, key) {

        //console.log('edit', product, key);

        const allProducts = JSON.parse(localStorage.getItem("relatedProducts")) || [];

        const relatedProducts = this.locateParent(allProducts, product.jobProductData.ProductId)

        const { jobProductData,
                doorData,
                shelveData,
                hardwareData,
                hingeData,
                materialData,
                maxFixedShelve,
                maxAdjustableShelve,
                stockCodes } = product;

        jobProductData.mshelves = shelveData ? shelveData.shelveId : 0;

        let pro = jobProductData;

        pro.details = jobProductData.details;
		pro.detailImage = jobProductData.detailImage;

        pro.doorData = doorData;
        pro.hardwareData = hardwareData;
        pro.shelveData = shelveData;
        pro.MaterialData = materialData;
        pro.hingeData = hingeData;


        this.setState(
            {
                product: pro,
                productSave: 'edit',
                updateKey: key,
                maxFixedShelve: maxFixedShelve,
                maxAdjustableShelve: maxAdjustableShelve,
                stockCodes: stockCodes,
                relatedProducts: relatedProducts
            }, () => {
                //console.log('product save', this.state.productSave);
            }
        )

        window.scrollTo( {top: 0, behavior: 'smooth' });

    }

    deleteCart(productsArray, array) {

        this.setState(
            {
                jobProduct: productsArray,
                productSave: "addToCart"
            }, () => {

                localStorage.setItem("jobProduct", JSON.stringify(productsArray));
                localStorage.setItem("postData", JSON.stringify(array));
                this.goToProducts(1);

            }
        )


    }

    addToCart(data) {

        //const customer_obj = JSON.parse(this.state.customer);
		
        //const Token = customer_obj.token;    
       
       if (this.state.jobProduct.length < this.state.cartLimit) {

        const {  material,
                    stockCodes,
                    stockCode,
                    product,
                    Width,
                    Height,
                    ToeHeight,
                    ToeRecess,
                    Depth,
                    Elevation,
                    SoffitHeight,
                    hardware,
                    Gst,
                    DoorId,
                    gapTop,
                    gapBottom,
                    gapLeft,
                    gapRight,
                    drawerGaps,
                    fixedQty,
                    equalQty,
                    adjustableQty,
                    detailImage,
                    details,
                    HardwareLayout,
                    lineTop,
                    lineLeft,
                    lineBottom,
                    lineRight,
                    ProductType,
                    ProductStockCode,
                    ProductDescription,
                    ProductPrice,
                    ProductQty,
                    ProductLineTotal,
                    ProductStockImage,
                    FixedShelve,
                    AdjustableShelve,
                    NoOfMitre
                } = data

            const cart = Cart(material,lineTop, lineBottom, lineLeft, lineRight, data, DoorId, hardware, Width, Height, Depth, product, stockCodes, stockCode, gapTop, gapBottom, gapLeft, gapRight, Gst, ProductType, ProductLineTotal, ProductStockCode, ProductPrice, ProductStockImage, Elevation, SoffitHeight, ToeHeight, ToeRecess, drawerGaps, detailImage, details, fixedQty, equalQty, adjustableQty, HardwareLayout, ProductDescription, ProductQty, this.state.roomData, this.state.RoomId, FixedShelve, AdjustableShelve, NoOfMitre, this.state.cartId);        

            try {

                //console.log('cart', cart);

                let materialArray = this.state.materialArray;

                if (cart.materialObj) {
                    //this.state.materialArray.push(cart.materialObj)
                    materialArray = cart.materialObj
                }

                let productsArray = this.state.productsArray;

                productsArray.push(cart.jobProductData);        

                data = {
                    cartId: this.state.cartId,
                    jobProductData: cart.jobProductData,
                    materialData: materialArray,
                    doorData: cart.doorObj,
                    hingeData: cart.hingeData,
                    shelveData: cart.shelveData,
                    hardwareData: cart.hardwareData,
                    stockCodes: this.state.stockCodes,
                    maxFixedShelve: this.state.maxFixedShelve,
                    maxAdjustableShelve: this.state.maxAdjustableShelve
                }

                let job = this.state.jobProduct;

                job.unshift(data);
                
                localStorage.setItem("postData", JSON.stringify(productsArray));
                localStorage.setItem("jobProduct", JSON.stringify(job))

                this.setState({
                    productprice: cart.productprice,
                    doorscost: cart.totalDoorCost,
                    shelvescost: cart.TotalShelveCost,
                    hardwarecost: cart.hardwarecost,
                    carcasecost: cart.totalCarcaseCost,
                    DoorHingeCost: cart.DoorHingeCost,
                    cartHeadingClass: 'panel-heading accordion-toggle',
                    isCartOpen: true,
                    cartId: this.state.cartId + 1
                });

                //saveCartToDB( 'add',  data, Token );
        
                const materials = this.state.roomMaterials.filter( r => r.RoomId === this.state.RoomId );        
        
                if (materials.length === 0) {
        
                    const roomMaterials = this.state.roomMaterials;
                    
                    roomMaterials.push( { RoomId: this.state.RoomId, materials: materialArray } );
        
                    this.setState( { roomMaterials: roomMaterials } );
        
                } 
        
                this.cartRef.current.toggled( this.state.RoomId );
        
                if (
                    (Width >= product.WidthMin && Width <= product.WidthMax) &&
                    (Height >= product.HeightMin && Height <= product.HeightMax) &&
                    (Depth >= product.DepthMin && Depth <= product.DepthMax) &&
                    (
                        (ToeRecess >= product.ToeRecessMin && ToeRecess <= product.ToeRecessMax) ||
                        (Elevation >= product.ElevationMin && Elevation <= product.ElevationMax)
                    ) &&
                    (
                        (ToeHeight >= product.ToeHeightMin && ToeHeight <= product.ToeHeightMax) ||
                        (SoffitHeight >= product.SoffitHeightMin && SoffitHeight <= product.SoffitHeightMax)
                    )
                ) {
        
                    this.setState({
                        postData: data,
                        getRooms: false,
                        product : cart.jobProductData,
                        productSave: "addToCart",
                        productsArray: productsArray,
                        jobProduct: job,
                        materialArray: []
                    })
        
                    ToastsStore.success(`Success!!! ${cart.jobProductData.ProductName} added to the cart.`);
                }
            
            } catch (e) {
                
                if (e.code == 22) {
                    swal("", "You reached the maximum limit. Please save and create another project to add more item", "error")
                }
            }
       } else {

            swal("", "You reached the maximum limit of your cart. Please save and create another project to add more item", "error");

       }
        
    }

    copyProduct(product, key) {

        //console.log('product', product);

        const productsArray = this.state.productsArray;

        const jobProducts = localStorage.getItem("jobProduct") ? JSON.parse(localStorage.getItem("jobProduct")) : [];

        const jobProductData = product.jobProductData;

        const doorData = product.doorData;
        const hardwareData = product.hardwareData;
        const hingeData = product.hingeData;
        const shelveData = product.shelveData;
        const MaterialData = product.materialData;
        const stockCodes = product.stockCodes;

        const newKey = key + jobProducts.length;

        //console.log('keys', key, newKey);

        jobProductData.key = newKey;

        const data = {
            jobProductData : jobProductData,
            doorData : doorData,
            hardwareData : hardwareData,
            hingeData : hingeData,
            shelveData : shelveData,
            materialData : MaterialData,
            stockCodes: stockCodes,
            maxFixedShelve: this.state.maxFixedShelve,
            maxAdjustableShelve: this.state.maxAdjustableShelve,
            key: newKey
        }

        productsArray.push(jobProductData);

        //localStorage.setItem("postData", JSON.stringify(productsArray));

        const job = this.state.jobProduct;

        job.unshift(data);        

        //localStorage.setItem("jobProduct", JSON.stringify(job));



        this.setState({
            postData: data,
            getRooms: false,
            //product : jobProductData,
            productSave: "addToCart",
            productsArray: productsArray,
            jobProduct: job,
            materialArray: []
        })
    }

    copyRoom() {

        const RoomId = this.state.RoomId;

        const jobProducts = localStorage.getItem("jobProduct") ? JSON.parse(localStorage.getItem("jobProduct")) : [];

        let productsArray = this.state.productsArray;

        let job = this.state.jobProduct;

        let data = [];

        const rooms = this.state.roomsList;

        const last = rooms[rooms.length - 1];

        const newRoomId = last.id + 1;

        const newRoomName = `Copy of ${last.name}`;

        jobProducts.map( p => {

            const product = p.jobProductData;

            //console.log(product.RoomId, RoomId);

            if (product.RoomId === RoomId) {

                //console.log('p', p);

                let doorData = p.doorData;
                let harwareData = p.hardwareData;
                let hingeData = p.hingeData;
                let shelveData = p.shelveData;
                let materialData = p.MaterialData;

                product.RoomId = newRoomId;
                product.roomData = newRoomName;

                data = {
                    doorData : doorData,
                    hardwareData : harwareData,
                    hingeData : hingeData,
                    shelveData : shelveData,
                    MaterialData : materialData,
                    jobProductData : product
                }

                //console.log(data);

                productsArray.push(product);

                job.push(data);

                localStorage.setItem("postData", JSON.stringify(productsArray));

                localStorage.setItem("jobProduct", JSON.stringify(job));

            }

        });

        rooms.push( { id: newRoomId, name: newRoomName } );

        this.setState({
                postData: data,
                getRooms: false,
                roomsList: rooms,
                productSave: "addToCart",
                productsArray: productsArray,
                jobProduct: job,
                materialArray: []
        })

    }

    updateCart(data) {

        var postDataList = JSON.parse(localStorage.getItem("postData"))
        var jobProductList = JSON.parse(localStorage.getItem("jobProduct"))

        //console.log('update', this.state.jobProduct);

        const {
            material,
            stockCodes,
            stockCode,
            product,
            Width,
            Height,
            ToeHeight,
            ToeRecess,
            Depth,
            Elevation,
            SoffitHeight,
            hardware,
            Gst,
            DoorId,
            gapTop,
            gapBottom,
            gapLeft,
            gapRight,
            drawerGaps,
            fixedQty,
            equalQty,
            adjustableQty,
            detailImage,
            details,
            HardwareLayout,
            lineTop,
            lineLeft,
            lineBottom,
            lineRight,
            ProductType,
            ProductStockCode,
            ProductDescription,
            ProductPrice,
            ProductQty,
            ProductLineTotal,
            ProductStockImage,
            FixedShelve,
            AdjustableShelve,
            NoOfMitre,
            RoomId,
            roomData
        } = data

        const cart = Cart(material, lineTop, lineBottom, lineLeft, lineRight,  data, DoorId, hardware, Width, Height, Depth, product, stockCodes, stockCode, gapTop, gapBottom, gapLeft, gapRight, Gst, ProductType, ProductLineTotal, ProductStockCode, ProductPrice, ProductStockImage, Elevation, SoffitHeight, ToeHeight, ToeRecess, drawerGaps, detailImage, details, fixedQty, equalQty, adjustableQty, HardwareLayout, ProductDescription, ProductQty, roomData, RoomId,  FixedShelve, AdjustableShelve, NoOfMitre, this.state.jobProduct.cartId );

        if (cart.materialObj) {
            this.state.materialArray = cart.materialObj
        }

        postDataList.splice(this.state.updateKey, 1, cart.jobProductData);

        localStorage.setItem("postData", JSON.stringify(postDataList));

        var data = {
            jobProductData: cart.jobProductData,
            materialData: this.state.materialArray,
            doorData: cart.doorObj,
            hingeData: cart.hingeData,
            shelveData: cart.shelveData,
            hardwareData: cart.hardwareData,

            stockCodes: this.state.stockCodes,
            maxAdjustableShelve: this.state.maxAdjustableShelve,
            maxFixedShelve: this.state.maxFixedShelve
        }
        // this.state.jobProduct.push(data)

        jobProductList.splice(this.state.updateKey, 1, data);

        localStorage.setItem("jobProduct", JSON.stringify(jobProductList))

        if ((Width >= product.WidthMin && Width <= product.WidthMax) && (Height >= product.HeightMin && Height <= product.HeightMax) && (Depth >= product.DepthMin && Depth <= product.DepthMax) && (ToeRecess >= product.ToeRecessMin && ToeRecess <= product.ToeRecessMax) || (Elevation >= product.ElevationMin && Elevation <= product.ElevationMax) && (ToeHeight >= product.ToeHeightMin && ToeHeight <= product.ToeHeightMax) || (SoffitHeight >= product.SoffitHeightMin && SoffitHeight <= product.SoffitHeightMax)) {

            this.setState( { productSave: 'addToCart'}, () => {

                this.setState({
                    postData: data,
                    getRooms: false,
                    jobProduct: jobProductList,                  
                    materialArray: []
                });

                ToastsStore.success(`Success!!! ${cart.jobProductData.ProductName} updated in the cart.`);

            } ) 
            
            
        }


    }

    getActiveProject(customerId) {
        fetch(config.BASE_URL + '/api/get-active-project/' + customerId , {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {
            if (data.length > 0) {
                this.setState({
                    noProject: false,
                    project: data[0]
                });
            }
        })
        .catch(error => console.log(error));
    }

    addproject(data) {

        const customer = this.state.jcustomer;

        const params = {name: data.name, customerId: this.state.customerId, clientName: customer.Name, contactNo: customer.MobileNumber, email:customer.Email}

        var customer_obj = JSON.parse(this.state.customer);

        //console.log(customer_obj);

        var Token = customer_obj.token;

        fetch(config.BASE_URL + '/api/addnewproject', {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((Token) ? Token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {

            let customer = JSON.parse(this.state.customer);

            customer.projects = 1;

            localStorage.setItem("customer", JSON.stringify(customer));
            localStorage.setItem("project", JSON.stringify(data.project));

            this.setState({noProject: false, project: data.project, jobId: data.JobId});

          })
          .catch(error => console.log(error));

    }

    doLogout() {
        this.setState({isLogin:false, project: [], customer: [], customerId: 0})

        const logout = strings.YouLogout;

        swal("", `${logout}`, "success");
    }

    doLogin() {

        const customer = localStorage.getItem("customer");

        const project = localStorage.getItem("project");

        const jProject = JSON.parse(project);

        const jcustomer = JSON.parse(customer);

        let noProject = true;

        if (jcustomer.projects > 0 || (jProject && jProject.ProjectId > 0)) {
            noProject = false;
        }

        //console.log('do login', customer);

        this.setState(
            {
                isLogin:true,
                customer : customer,
                jcustomer : jcustomer,
                customerId: jcustomer.CustomerId,
                project: jProject,
                noProject: noProject
            }, () => {
                const data = {customerId: jcustomer.CustomerId, customerName: jcustomer.Name, activity: 'login'}
                const Token = jcustomer.token;

                fetch(config.BASE_URL + '/api/activity-log', {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + ((Token) ? Token : '' )
                    }
                })
                .then()
                .then()
                .catch(error => console.log(error));
            }
        );

        if (noProject) {
            this.getActiveProject(jcustomer.CustomerId);
        }

    }

    goToProducts(productId, productSave) {

        this.setState( { loading: true } );

        const allProducts = []; //JSON.parse(localStorage.getItem("relatedProducts")) || []
        const relatedProducts = this.locateParent(allProducts, productId)

        const customer = JSON.parse(localStorage.getItem("customer"));

        let customerId = 0;

        if (customer) {
            customerId = customer.CustomerId
        }

        fetch(config.BASE_URL + '/api/getproductbyId/' + productId + '/' + customerId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {

            //console.log('got product', data);

            this.setState({
                relatedProducts: relatedProducts,
                Gst: data.gst,
                min_order: data.min_order,
                products: data.products,
                product: data.product,
                material: data.material,
                materialType: data.materialType,
                brand: data.brand,
                hinge: data.hinge,
                door: data.door,
                hardware: data.hardware,
                shelves: data.shelve,
                stockCodes: data.stockcodes,
                substrate: data.substrate,
                layouts: data.layouts,
                materialDefault : data.materialDefault,
                productSave: 'addToCart',
                maxShelve: data.maxShelve,
                minShelve: data.minShelve,
                minDrawer: data.minDrawer,
                maxDrawer: data.maxDrawer,
                minFixedShelve: data.minFixedShelve,
                maxFixedShelve: data.maxFixedShelve,
                minAdjustableShelve: data.minAdjustableShelve,
                maxAdjustableShelve: data.maxAdjustableShelve,
                ProductType : data.product.ProductId == 30 ? 'accessories' : '',
                maxDrawerEqual : data.maxDrawerEqual,
                minDrawerEqual : data.minDrawerEqual,
                minDrawerSmall : data.minDrawerSmall,
                maxDrawerSmall : data.maxDrawerSmall,
                minDrawerInner : data.minDrawerInner,
                maxDrawerInner : data.maxDrawerInner,
                loading: false,
                updateKey: ''
            })
        })
        .catch(error => console.log(error));
    }

    isJson(str) {


            try {

                JSON.parse(str);

            } catch (e) {

                return false;

            }



            return true;

    }

    locateParent(data, ProductId){
      var relatedproducts = []
      for(var i = 0; i < data.length; i++) {
        //console.log(data[i].children);
        relatedproducts = locateSiblings(data[i].children || [], ProductId);
        //console.log('relatedproducts', relatedproducts);
        if (relatedproducts && relatedproducts.products) {
          //console.log('relatedproducts', relatedproducts)
          relatedproducts['category'] = {id: data[i].id, text: data[i].text};
          return relatedproducts;
        }
      }

      function locateProduct(products, ProductId) {
        for(var i = 0; i < products.length; i++) {
          //console.log()
          if (products[i].ProductId && products[i].ProductId == ProductId) {
            //console.log('products', products);
            return products;
          }
        }
      }

      function locateSiblings(children, ProductId) {
        var products = [];
        for(var i = 0; i < children.length; i++) {
          //console.log(children[i].products)
          products = locateProduct(children[i].products || [], ProductId)
          if (products && products.length > 0) {
            return {
              subcategory: {id: children[i].id, text: children[i].text},
              products: products
            }
          }
        }
      }
    }

    componentDidMount() {

        this.setState( { loading: true } );

        const customer = localStorage.getItem("customer");

        const { params } = this.props.match

        var productId = 1;
        var productSku = '';

        if (Object.keys(params).length > 0) {
			      productId = params.prodid
            productSku = params.prodsku

            if (params.activationcode && params.customerid){ // there is an activation code
              activate.ActivateCustomer(params);
            }
        }

        const allProducts = []; //JSON.parse(localStorage.getItem("relatedProducts")) || []
        const relatedProducts = this.locateParent(allProducts, productId)

        if (customer) {

            //this.goToProducts(productId, "none");
            window.location = '/dashboard'

        } else {

            localStorage.removeItem('customer');
            localStorage.removeItem('project');
            localStorage.removeItem("jobProduct");
            localStorage.removeItem("postData");
            localStorage.removeItem("roomsList");

            this.goToProducts(1, "addToCart");

            // window.location = '/'
        }

        const jobProduct = JSON.parse(localStorage.getItem("jobProduct"));
        const postData = JSON.parse(localStorage.getItem("postData"));
        const project = JSON.parse(localStorage.getItem("project"));
        const roomsList = JSON.parse(localStorage.getItem("roomsList"));

        //console.log(project);

        this.setState({
          relatedProducts: relatedProducts
        });

        if (jobProduct) {

            this.setState(
                {
                    jobProduct  : jobProduct,
                    products    : jobProduct,
                    project     : project,
                    roomId      : roomsList ? roomsList[0].id : 0,
                    roomData    : roomsList ? roomsList[0].name : 'Room 1',
                    roomsList   : roomsList ? roomsList : [ {id: 0, name: 'Room 1'} ]
                }
            )
        }

        if (postData) {
            this.setState(
                {
                    productsArray: postData
                }
            )
        }

    }


    render() {

        if (this.state.jobId > 0) {
            window.location = '/dashboard/edit-order/' +  this.state.jobId;
        }

        const product = this.state.product

        const { relatedProducts } = this.state;

        //console.log('product', product);

        var AccessoriesProd = product.ProductType && product.ProductType == 'accessories' ? true : relatedProducts && relatedProducts.category.text == 'Accessories' ? true : false
        var PanelsProd = product.ProductType && product.ProductType == 'panels' ? true : relatedProducts && relatedProducts.category.text.indexOf('Panels') > -1 ? true : false


        var ProductSection =    (<ProductHome
                              page='add'
                              getEditProducts={this.getEditProducts}
                              addToCart={this.addToCart}

                              renameSticker={this.renameSticker}
                              updateCabinetQty={this.updateCabinetQty}

                              updateQty={this.updateQty}
                              updateCart={this.updateCart}
                              deleteCart={this.deleteCart}
                              copyProduct={this.copyProduct}

                              products={this.state.jobProduct}
                              
                              product={ product }

                              project={this.state.project}
                              productSave={this.state.productSave}
                              isLogin={this.state.isLogin}
                              customer={this.state.customer}

                              JobId={this.state.jobId}
                              addJob={this.addJob}

                              doLogout={this.doLogout}
                              maxShelve={this.state.maxShelve}
                              minShelve={this.state.minShelve}
                              minDrawer={this.state.minDrawer}
                              maxDrawer={this.state.maxDrawer}

                              minFixedShelve={this.state.minFixedShelve}
                              maxFixedShelve={this.state.maxFixedShelve}
                              minAdjustableShelve={this.state.minAdjustableShelve}
                              maxAdjustableShelve={this.state.maxAdjustableShelve}

                              minDrawerEqual={this.state.minDrawerEqual}
                              maxDrawerEqual={this.state.maxDrawerEqual}
                              maxDrawerSmall={this.state.maxDrawerSmall}
                              minDrawerSmall={this.state.minDrawerSmall}
                              maxDrawerInner={this.state.maxDrawerInner}
                              minDrawerInner={this.state.minDrawerInner}

                              updateKey={this.state.updateKey}
                              gst={this.state.gst}
                              min_order={this.state.min_order}
                              material={this.state.material}
                              materialType={this.state.materialType}
                              brand={this.state.brand}
                              door={this.state.door}
                              hinge={this.state.hinge}
                              hardware={this.state.hardware}
                              shelve={this.state.shelves}
                              substrate={this.state.substrate}
                              stockcodes={this.state.stockCodes}
                              layouts={this.state.layouts}
                              materialDefault={this.state.materialDefault}
                              completePayment={this.completePayment}
                              goToProducts={this.goToProducts}
                              relatedProducts ={relatedProducts}

                              roomId={this.state.RoomId}
                              roomMaterials={this.state.roomMaterials}
                              />)

      if (AccessoriesProd){
        ProductSection = (<Accessories
            page='add'
            getEditProducts={this.getEditProducts}
                addToCart={this.addToCart}

                setRoom={this.setRoom}
                renameRoom={this.renameRoom}
                renameSticker={this.renameSticker}

                updateQty={this.updateQty}
                updateCart={this.updateCart}
                deleteCart={this.deleteCart}
                copyProduct={this.copyProduct}
                updateCabinetQty={this.updateCabinetQty}
                JobId={this.state.jobId}
                addJob={this.addJob}

                products={this.state.jobProduct}
                product={this.state.product}
                project={this.state.project}
                productSave={this.state.productSave}
                isLogin={this.state.isLogin}
                customer={this.state.customer}
                doLogout={this.doLogout}
                maxShelve={this.state.maxShelve}
                minShelve={this.state.minShelve}
                minDrawer={this.state.minDrawer}
                maxDrawer={this.state.maxDrawer}
                minFixedShelve={this.state.minFixedShelve}
                maxFixedShelve={this.state.maxFixedShelve}
                minAdjustableShelve={this.state.minAdjustableShelve}
                maxAdjustableShelve={this.state.maxAdjustableShelve}
                minDrawerEqual={this.state.minDrawerEqual}
                maxDrawerEqual={this.state.maxDrawerEqual}
                maxDrawerSmall={this.state.maxDrawerSmall}
                minDrawerSmall={this.state.minDrawerSmall}
                maxDrawerInner={this.state.maxDrawerInner}
                minDrawerInner={this.state.minDrawerInner}

                gst={this.state.gst}
                min_order={this.state.min_order}
                material={this.state.material}
                materialType={this.state.materialType}
                brand={this.state.brand}
                door={this.state.door}
                hinge={this.state.hinge}
                hardware={this.state.hardware}
                shelve={this.state.shelves}
                substrate={this.state.substrate}
                stockcodes={this.state.stockCodes}
                layouts={this.state.layouts}
                materialDefault={this.state.materialDefault}
                completePayment={this.completePayment}
                goToProducts={this.goToProducts}
                relatedProducts ={relatedProducts}
                updateKey={this.state.updateKey}
            />)
      }

      if (PanelsProd){
        ProductSection = (<ProductHomePanel
            page='add'
            getEditProducts={this.getEditProducts}
                addToCart={this.addToCart}
                setRoom={this.setRoom}
                renameRoom={this.renameRoom}
                renameSticker={this.renameSticker}
                updateQty={this.updateQty}
                updateCart={this.updateCart}
                deleteCart={this.deleteCart}
                products={this.state.jobProduct}
                product={this.state.product}
                project={this.state.project}
                productSave={this.state.productSave}
                isLogin={this.state.isLogin}
                customer={this.state.customer}
                updateCabinetQty={this.updateCabinetQty}
                JobId={this.state.jobId}
                addJob={this.addJob}

                doLogout={this.doLogout}
                maxShelve={this.state.maxShelve}
                minShelve={this.state.minShelve}
                minDrawer={this.state.minDrawer}
                maxDrawer={this.state.maxDrawer}
                minFixedShelve={this.state.minFixedShelve}
                maxFixedShelve={this.state.maxFixedShelve}
                minAdjustableShelve={this.state.minAdjustableShelve}
                maxAdjustableShelve={this.state.maxAdjustableShelve}
                minDrawerEqual={this.state.minDrawerEqual}
                maxDrawerEqual={this.state.maxDrawerEqual}
                maxDrawerSmall={this.state.maxDrawerSmall}
                minDrawerSmall={this.state.minDrawerSmall}
                maxDrawerInner={this.state.maxDrawerInner}
                minDrawerInner={this.state.minDrawerInner}
                gst={this.state.gst}
                min_order={this.state.min_order}
                material={this.state.material}
                materialType={this.state.materialType}
                brand={this.state.brand}
                door={this.state.door}
                hinge={this.state.hinge}
                hardware={this.state.hardware}
                shelve={this.state.shelves}
                substrate={this.state.substrate}
                stockcodes={this.state.stockCodes}
                layouts={this.state.layouts}
                materialDefault={this.state.materialDefault}
                completePayment={this.completePayment}
                goToProducts={this.goToProducts}
                relatedProducts ={relatedProducts}
                updateKey={this.state.updateKey}
            />)
      }

        //console.log('rooms', this.state.roomsList);

        return (
            <div key="ProductPage">
                <Header
                    page="home"
                    saveDashboard={this.saveDashboard}
                    saveProject={this.saveProject}
                    sendProducts={this.goToProducts}
                    doLogin={this.doLogin}
                    doLogout={this.doLogout}
                    project={this.state.project}
                    saveLogin={this.saveLogin}
                    isLogin={this.state.isLogin} />

                    { this.state.loading ? <Loader /> :

                        <Fragment>

                            <div>
                                <div className="clr" />
                                <div className="wrapper" id="product">
                                    <div className="container">
                                        <div className="row">

                                            <div className="col-md-12">
                                                <ol className="breadcrumb breadm">
                                                    <li><a href="/">Home</a></li>
                                                    <li className="active"><i className="fa fa-angle-right midarrow" /><a >Product Detail</a></li>
                                                </ol>
                                            </div>

                                            <div className="col-md-12 maincontent">

                                                { ProductSection }

                                                <div key={"roomContainer" + 1} className="col-xs-12 col-sm-12 col-md-3 rightaccordian">
                                                    <Room

                                                        ref={this.cartRef}

                                                        activeRoomId={this.state.RoomId}

                                                        key={"Room" + 1}
                                                        page="add"

                                                        products={ this.state.jobProduct }
                                                        productsArray={this.state.productsArray}

                                                        setRoom={this.setRoom}
                                                        addRoom={this.addRoom}
                                                        deleteRoom={this.deleteRoom}

                                                        sendProducts={this.getEditProducts}
                                                        renameRoom={this.renameRoom}
                                                        renameSticker={this.renameSticker}

                                                        roomsList={this.state.roomsList}

                                                        updateQty={this.updateQty}
                                                        deleteCart={this.deleteCart}
                                                        copyProduct={this.copyProduct}
                                                        copyRoom={this.copyRoom}
                                                        updateCabinetQty={this.updateCabinetQty}
                                                        productSave={this.state.productSave}

                                                        
                                                        />

                                                    <AccessoriesRoom

                                                        page="add"
                                                        products={this.state.jobProduct}
                                                        setRoom={this.setRoom}
                                                        addRoom={this.addRoom}
                                                        sendProducts={this.getEditProducts}
                                                        renameRoom={this.renameRoom}
                                                        renameSticker={this.renameSticker}

                                                        roomsList={this.state.roomsList}

                                                        updateQty={this.updateQty}
                                                        deleteCart={this.deleteCart}
                                                        copyProduct={this.copyProduct}
                                                        copyRoom={this.copyRoom}
                                                        updateCabinetQty={this.updateCabinetQty}
                                                        />

                                                    <TotalCost products={this.state.jobProduct} />

                                                        {

                                                            this.state.jobProduct.length > 0 ?
                                                                <OrderConfirm
                                                                    project={this.state.project}
                                                                    products={this.state.jobProduct}
                                                                    isLogin={this.state.isLogin}
                                                                    doLogout={this.doLogout}
                                                                    redirect={this.redirect}
                                                                    customer={this.state.customer}
                                                                    GST={this.state.Gst}
                                                                    JobId={this.state.jobId}
                                                                    addJob={this.addJob}
                                                                    min_order={this.state.min_order}
                                                                    stockCodesDB={this.state.stockCodes}
                                                                    completePayment={this.completePayment}
                                                                    />
                                                            : ""

                                                        }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Fragment>
                    }


                <Footer />
                <div className={this.state.blockerClass} />
                <CreateNewProjectModal addproject={this.addproject} open={this.state.noProject} />
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_RIGHT} />
            </div>
        );
    }
}

class CreateNewProjectModal extends Component {

    constructor(props) {
      super(props);

      this.state = {
        nameClass : 'form-control',
        errorName: 'hidden',
        name: ''
      }

      this.handleName = this.handleName.bind(this);
      this.submit = this.submit.bind(this);
      this.keyPressed = this.keyPressed.bind(this);
    }

    keyPressed(e) {
        if (e.keyCode == 13) {
            this.submit();
        }
    }

    handleName(e) {
      this.setState(
        {
          name: e.target.value
        }
      )
    }

    submit() {
        let valid = true;

        const name = this.state.name;

        if (name == '') {

            valid = false;

            this.setState(
                {
                    nameClass : 'form-control error border-danger',
                    errorName: 'd-block',
                }
            )
        }

        if (valid) {
            this.props.addproject(this.state)
        }
    }

    render() {
      return (
        <Modal
            show={this.props.open}
            onHide={this.props.close}
            className="create-new-project"
          >
            <Modal.Header closeButton>
              Project
            </Modal.Header>
            <Modal.Body>

                <div className="form-group">
                    <label>{strings.Pleaseenterprojectname}</label>
                    <input type="text" className={this.state.nameClass} onChange={this.handleName} onKeyDown={this.keyPressed}  />
                    <span className={this.state.errorName}>Enter name</span>
                </div>

                <div className="form-group text-right">
                    <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn mr-2">Submit</button>
                    <button onClick={this.reset} type="button" className="btn">Reset</button>
                </div>

            </Modal.Body>
          </Modal>
      )
    }
  }
export default ProductsPage;
