import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import Modal from 'react-bootstrap-modal';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import swal from 'sweetalert';
import { strings } from '../components/strings';

class Zipcodes extends Component {

	constructor(props) {
		super(props);
		var authuser = JSON.parse(localStorage.getItem('authuser'));
		this.state = {
			AuthUser: (authuser) ? authuser[0] : [],
			data: [],
			menus: [],
			columnWidth: [40, 40, 10],
			open: false
		}

		this.get = this.get.bind(this)
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.submit = this.submit.bind(this);
		this.delete = this.delete.bind(this);
		this.onSortChange = this.onSortChange.bind(this);
		this.editFormatter = this.editFormatter.bind(this);
		this.headerFormatter = this.headerFormatter.bind(this);
		this.renderlist = this.renderlist.bind(this);
	}

	renderlist(data){
		this.setState({data: data.items}, () => {
			for (let index = 0; index < 15; index += 1) {
				if ($(`.react-bootstrap-table thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

				$(`.react-bootstrap-table thead th:eq(${index})`).resizable({
				minWidth: 50,
				handles: 'e',
				resize: (__event, ui) => {
				const newColumnWidths = [...this.state.columnWidth];
				const container = $('.react-bootstrap-table').width();
				newColumnWidths[index] = ui.size.width / container * 100;
				this.setState({ columnWidth: newColumnWidths });
				},
				});

			}
			});
	}

	get() {
		fetch(config.BASE_URL + '/api/zipcodes/get', {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			  }
			})
			.then(data => data.json())
			.then(data => {
				this.renderlist(data)
			})
			.catch(error => console.log(error));
	}

    componentDidMount() {
      this.get();
    }

	open() {
		this.setState({open:true});
	}

	close() {
		this.setState({open:false});
	}

	submit(data) {
		this.add(data);
		this.close();
	}

	add(data) {
		const authuser = this.state.AuthUser;
		fetch(config.BASE_URL + '/api/zipcodes/add', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
		})
		.then(data => data.json())
		.then(data => {
			this.setState({data: data.items});
		})
		.catch(error => console.log(error));
	}

	delete(ID){

		swal({
      title: `${strings.Areyousure}`,
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
				const authuser = this.state.AuthUser;
				const data = {
					ID: ID
				}
				fetch(config.BASE_URL + '/api/zipcodes/delete', {
				  method: "POST",
				  body: JSON.stringify(data),
				  headers: {
					  "Content-Type": "application/json",
					  'Accept': 'application/json',
					  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
					}
				  })
				  .then(data => data.json())
				  .then(data => {
						this.renderlist(data)
				  })
				  .catch(error => console.log(error));
      } else {
          return false;
      }
  });

	}

	save(data) {
		console.log('data', data)
		const authuser = this.state.AuthUser;
		fetch(config.BASE_URL + '/api/zipcodes/update', {
		  method: "POST",
		  body: JSON.stringify(data),
		  headers: {
			  "Content-Type": "application/json",
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
		  })
		  .then(data => data.json())
		  .then(data => {

		  })
		  .catch(error => console.log(error));
	}


	editFormatter(cell, row) {
		return  <button className="link-btn" onClick={() => this.delete(row.ID)}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>;
  }

	headerFormatter(cell, row){
		return  <button className="btn purchasebtn" onClick={() => this.open()}>
					Add
				</button>;
	}

	onSortChange(field, order) {
		console.log(field, order)
		localStorage.setItem("ZipSort", JSON.stringify({
		sortName: field,
		sortOrder: order
		}));
	}

	render() {
		var ZipSort = localStorage.getItem('ZipSort');

		if (ZipSort) {
			ZipSort = JSON.parse(ZipSort)
		}

		const defaultSort = {
			dataField: (ZipSort) ? ZipSort.sortName : null,
			order: (ZipSort) ? ZipSort.sortOrder : null,
		}


		const columns = [{
			dataField: 'zipcode',
			text: 'Post Code',
			sort: true,
			editable: true,
			headerStyle: {width: this.state.columnWidth[0] + '%', verticalAlign: 'middle'},
			style: {verticalAlign: 'middle'},
			  onSort: this.onSortChange
		}, {
			dataField: 'suburb',
			sort: true,
			text: 'Suburb',
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[1] + '%'},
			onSort: this.onSortChange
		}, {
			dataField: 'distance',
			sort: true,
			text: 'Distance',
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[1] + '%'},
			onSort: this.onSortChange
		}, {
			dataField: 'state',
			hidden: true,
			sort: true,
			text: 'State',
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[1] + '%'},
			onSort: this.onSortChange
		}, {
			dataField: 'ID',
			text: '',
			editable: false,
			sort: false,
			style: {whiteSpace: 'normal', padding: '8px', verticalAlign: 'middle'},
			formatter: this.editFormatter,
			headerFormatter: this.headerFormatter,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[2] + '%'},
			onSort: this.onSortChange
		}];



		  return (
			<div className="admin-wrapper">
				<HeaderAdmin />
				<div className="dashboard-wrapper partscontent">
				  <div className="dashboardhome">
					  <div className="container-fluid dashboard-content jobspagecnt">
						<div className="row">
						  <div className="col-md-12">
							<ol className="breadcrumb breadm">
								<li><a href="/admin">Admin</a></li>
								<li><i className="fa fa-angle-right midarrow" /><a href="/admin/zipcodes">Perth Metro Post Codes</a></li>
							</ol>
						  </div>
						</div>

						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<BootstrapTable
									keyField="ID"
									data={ this.state.data }
									columns={ columns }
									sort={defaultSort}
									cellEdit={ cellEditFactory({
										mode: 'click',
										blurToSave: true,
										afterSaveCell: (oldValue, newValue, row, column) => {
											this.save(row)
										}
									})}
								/>

								<ZipcodesModal open={this.state.open} close={this.close} submit={this.submit} />

							</div>
						</div>

						</div>
					</div>

				<FooterAdmin />
				</div>
			</div>
		  )
	}

}

class ZipcodesModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      zipcode  : '',
      state  : 'WA',
      zipcodeClass: 'form-control',
      errorClass: 'hidden'
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);



  }

  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value, zipcodeClass: 'form-control', errorClass: 'hidden'} )
  }

  submit() {
    const zipcode = this.state.zipcode;

    let valid = true;

    if (zipcode == '') {
      valid = false;
      this.setState({zipcodeClass: 'form-control border-danger', errorClass: 'text-red'})
    }

    if (valid) {
      this.props.submit(this.state);
    }
  }

  render() {

    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal"
        >

       <Modal.Header closeButton>
         Add Perth Metro Post Code
       </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label>Post Code</label>
            <input type="text" name="zipcode" className={this.state.zipcodeClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter zip code</span>
          </div>
					<div className="form-group">
            <label>Suburb</label>
            <input type="text" name="suburb" className={this.state.zipcodeClass} onChange={this.handleChange} />
          </div>
					<div className="form-group">
            <label>Distance</label>
            <input type="number" name="distance" className={this.state.zipcodeClass} onChange={this.handleChange} />
          </div>
					{/*
					<div className="form-group">
            <label>State</label>
            <select name="state" onChange={this.handleChange} className="form-control">
                <option value="WA" selected>Western Australia</option>
								<option value="NSW">New South Wales</option>
								<option value="VIC">Victoria</option>
								<option value="QLD">Queensland</option>
								<option value="SA">South Australia</option>
								<option value="TAS">Tasmania</option>
            </select>
          </div> */}
          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
        </Modal.Body>

      </Modal>

    )

  }

}

export default Zipcodes
