
import swal from "sweetalert";
import config from "../../config";
import { strings } from '../strings';

export let Customer = localStorage.getItem("customer") ;        

export function DoLogin() {

}

export function getCustomer() {

  const customer = localStorage.getItem("customer");

  try {

    JSON.parse(customer);

  } catch (e) {

    localStorage.removeItem("customer");
    window.location = "/";

  }

  return customer;

}

export function DoLogout() {

  swal({
    title: `${strings.Areyousure}`,      
    icon: "warning",
    buttons: true,
    dangerMode: true
    }).then(logout => {
    if (logout) {
  
      const customer = localStorage.getItem("customer");

      const jcustomer = JSON.parse(customer);

      localStorage.removeItem("customer");
      localStorage.removeItem("jobProduct")
      localStorage.removeItem("postData")
      localStorage.removeItem("project");

      const data = {customerId: jcustomer.CustomerId, customerName: jcustomer.Name, activity: 'logout'}

      fetch(config.BASE_URL + '/api/activity-log', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json"
          }
      })
      .then()
      .then(() => {
        swal({
          title: "",
          text: "You have been logout!!",
          type: "success",
          timer: 3000
          }).then( () => {
            window.location = '/'
          })   
      })
      .catch(error => console.log(error));  
      
    } 
  });

  
  
}