import React, { Component } from 'react';
import Modal from "react-bootstrap-modal";
import config from '../config';
import {Elements, StripeProvider} from 'react-stripe-elements';
import MyStoreCheckout from './CheckOut';
// import AssemblyCost from './AssemblyCost';
import OrderAddress from './OrderAddress';
import swal from "sweetalert";

import { strings } from './strings';

class OrderConfirm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      products: [],
      customer: [],
      project: [],
      assemblyCost: 0,
      calculatingACost: false,
      showAssemblyCost: false,
	    JobId: props.JobId ? props.JobId : 0,
		  GST: props.GST,
      min_order: props.min_order,
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.button = this.button.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.saveJob = this.saveJob.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.calculate = this.calculate.bind(this);
    this.completePayment = this.completePayment.bind(this);

  }

  completePayment() {
    this.setState( {
      open: false
    }, () => {
      this.props.completePayment();
    } )
  }

  calculate() {

    const products = this.props.products;
    const stockcodesdb = this.props.stockCodesDB;

    let assemblyCostwithGST = 0;
    
    if (products.length > 0) {
      products.map(function(product){
        
        if (product.jobProductData.ProductType == '') {
          const cpStockCode = stockcodesdb.filter(dbCode => {
            return dbCode.StockCode ===  product.jobProductData.productStockCode
          });
          
          var assemblyCost = cpStockCode && cpStockCode[0].AssemblyCost ? cpStockCode[0].AssemblyCost  : 0
          var Qty = parseInt(product.jobProductData.ProductQty)
          var PriceWidthGST = !isNaN(parseFloat(product.jobProductData.Price)) ? parseFloat(product.jobProductData.Price) : 0

          assemblyCostwithGST += (((assemblyCost / 100) * PriceWidthGST) * Qty);
        }
        
      });
      
      this.setState({
        showAssemblyCost: true,
        assemblyCost: parseFloat(assemblyCostwithGST).toFixed(2),
      });
    }

	}

  handleChangeCheck = async (ev) => {

		if (ev.target.checked){

      this.calculate();

		} else {
			this.setState({
				assemblyCost: 0,
				showAssemblyCost: false
			})
		}
  }

  doLogout() {
    this.closeModal();
    this.props.doLogout();
  }

  openModal(data) {

    let hasCustomized = 0;

    this.props.products.map( (p) => {

      const productData = p.jobProductData

      if (productData.isCustomized === 1) hasCustomized = 1;

      return p;

    });

    if (hasCustomized === 0) {

      if (this.props.JobId === 0) {

        this.saveJob(data);
        this.setState({ open:true });

      } else {

        this.setState({ open:true });
      }

    } else {

      swal( {

        icon: 'error',
        title: 'The team needs to confirm the pricing on one of the items, we will contact you shortly once we have confirmed the pricing'

      })

    }
  }

	saveJob() {

    const address1 = ""; //data.address1;
		const address2 = ""; //data.address2;
		const address3 = ""; //data.address3;
		const customer = this.props.customer;
		const project = this.state.project;
    const products = this.state.products;
    //console.log('save job', this.props.customer);

		var customer_obj = JSON.parse(this.props.customer);

    var Token = customer_obj.token;

    this.props.addJob(products, project, customer, address1, address2, address3, Token);

	}

  closeModal(data) {

    this.setState( { open: false } );

      /* if (this.state.JobId == '') {

          const address1 = ""; //data.address1;
          const address2 = ""; //data.address2;
          const address3 = ""; //data.address3;
          const customer = this.state.customer;
          const project = this.state.project;
          const products = this.state.products;

          var customer_obj = JSON.parse(this.state.customer);
          //console.log(customer_obj);
          var Token = customer_obj.token;

          fetch(config.BASE_URL + '/api/addJob', {
            method: "POST",
            body: JSON.stringify({products, project, customer, address1, address2, address3}),
            headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + Token
            }
          })
          .then(data => data.json())
          .then(data => {
            this.setState(
            {
              open: false
            }
            )
          })
          .catch(error => console.log(error));

	    } else {
		    this.setState(
			    {
			      open: false
			    }
		    )
	  }*/
  }

  componentDidMount() {

    this.setState({
      products: this.props.products,
      customer: this.props.customer,
      project: this.props.project
    }, () => {
      if (this.state.showAssemblyCost) {
        this.calculate()
      }
    });
  }

	onAssemblyCost=(assemblyCost)=>{
		this.setState({
			assemblyCost: assemblyCost
		}, () => {
			//console.log(this.state.assemblyCost);
		});

	}

  button() {

    const customer = this.props.customer;

      return(
        <>
          {/*<AssemblyCost db={this.props.stockCodesDB} stockCodes={stockCodes} onAssemblyCost={this.onAssemblyCost} GST={this.state.GST} />*/}

          <div className="form-check includeAssemblyCost">
				    <label className="form-check-input">

                <input onChange={this.handleChangeCheck} type="checkbox" name="includeAssemblyCost" value="1" />
                <span className="checkmark1"></span> {strings.IncludeAssemblyCost}

            </label>

              { (this.state.calculatingACost)? (
                <p>{strings.CalculatingAssemblyCost}</p>
              ) : (
                ''
              )}
              { (this.state.showAssemblyCost)? (
                <label style={{paddingLeft: "0px", fontSize: "18px", fontWeight: "900"}}>Assembly Cost $ {this.state.assemblyCost}</label>
              ) : (
                ''
              )}

			    </div>

          <button type="button"
                    id="btnOrderConfirm"
                    className="upsize cartbtn purchasebtn btn"
                    onClick={this.openModal}
                    >
              Purchase
          </button>

          <OrderConfirmModal
            completePayment={this.completePayment}
            redirect={this.props.redirect}
            doLogout={this.doLogout}
            open={this.state.open}
            closeModal={this.closeModal}
            products={this.props.products}
            project={this.props.project}
            default={this.props.default}
            JobId={this.props.JobId}
            customer={customer}
            assemblyCost={this.state.assemblyCost}
            min_order = {this.props.min_order}

          />
        </>
      )
  }


  render() {

    const isLogin = this.props.isLogin
    const button = isLogin ? this.button() : '';

    return(

      <>
        {button}
      </>

    )
  }
}

class OrderConfirmModal extends Component {

    constructor(props) {
      super(props);

      this.state = {
        name: '',
        isLogin: false,
        address1: '',
        address2: '',
        address3: '',
		    addressData: [],
        isPerth: true,
        shippingCost: 0,
        TotalWeight: 0,
        isPickUp: false,
        pickupOption: ''
      }

      this.handleAddress = this.handleAddress.bind(this);
      this.proceed = this.proceed.bind(this);
      this.checkPayByAccount = this.checkPayByAccount.bind(this);
      this.onChangeOption = this.onChangeOption.bind(this);
      this.checkZipcode = this.checkZipcode.bind(this);
      this.onCheckPickUp = this.onCheckPickUp.bind(this);
    }

    onCheckPickUp(data){
      this.setState({
  			isPickUp : data.isPickup,
        pickupOption: data.isPickup ? data.pickupOption : ''
  		}, () => {
  			//console.log('this.state pickuption', this.state);
  		})
    }

    checkPayByAccount(){
      //console.log('working function');
        if (JSON.parse(this.props.customer).pay_by_account === 0){
          //Hide pay by account payment
          return 'hide-pay-account';
        }
        return 'show-pay-account';
    }

    componentDidUpdate(prevProps) {

    }

    componentDidMount() {

    }

    proceed() {
      //let valid = true;

      //let address1 = this.state.address1;
      //let address2 = this.state.address2;
      //let address3 = this.state.address3;

      this.props.closeModal(this.state);
    }

    handleAddress(e) {
      const value = e.target.value;

      if (e.target.name === 'address1') {
        this.setState({ address1 : value});
      }

      if (e.target.name === 'address2') {
        this.setState( { address2: value} )
      }

      if (e.target.name === 'address3') {
        this.setState( {address3: value} );
      }
    }

  checkZipcode(zipcode){
    const {products} = this.props
    const data = {
      zipcode: zipcode
    }
		fetch(config.BASE_URL + '/api/zipcodes/search', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			}
		})
		.then(data => data.json())
		.then(data => {

      const {products} = this.props
      var TotalWeight = 0;
      products.map((p, key) => {
        const Qty = (p.jobProductData.ProductType === 'accessories') ? p.jobProductData.fixedQty : p.jobProductData.ProductQty;
        TotalWeight += p.jobProductData.Weight ? parseFloat(p.jobProductData.Weight) : 0;
        //console.log('TotalWeight-'+key, TotalWeight)
      })

      var shippingCost = 0;

      var fixed = 0;
      var perkl = 0;
      var customDelivery = false
      if (parseInt(data.found) > 0) {
        fixed = parseFloat(this.props.default.within_perth_fixed);
        perkl = parseFloat(this.props.default.winthin_perth_per);
        shippingCost = fixed + (parseFloat(perkl) * TotalWeight)
      }else{
        fixed = parseFloat(this.props.default.outside_perth_fixed);
        perkl = parseFloat(this.props.default.outside_perth_per);
        shippingCost = fixed + (parseFloat(perkl) * TotalWeight)

        if (this.props.default.outside_perth_auto == 0 ) {
          shippingCost = 0
          customDelivery = true
        }

      }

      //console.log('this.props.default', this.props.default)

      this.setState({
  			isPerth: parseInt(data.found) > 0 ? true : false,
        shippingCost: shippingCost,
        TotalWeight: TotalWeight,
        customDelivery: customDelivery
  		}, () => {
        console.log('this.state', this.state)
  		})
		})
		.catch(error => console.log(error));
  }

	onSelectAddress = (data, isShipping) => {
		this.setState({
			addressData: data
		}, () => {
			//console.log(this.state.addressData);
      if (isShipping) { //check for perth area
        if (data.shippingAddress.postcode !== '') {
          this.checkZipcode(data.shippingAddress.postcode)
        }
      }
		})
	}

  onChangeOption(data){
    this.setState({
			pickupOption: data
		}, () => {
			console.log(this.state);
		})
  }
    render() {

      let result = this.props.products && this.props.products.length > 0 ? this.props.products.reduce(function (tot, arr) {
        return tot + (arr.jobProductData.Price * arr.jobProductData.ProductQty);
      }, 0) : "";

      if (result < this.props.min_order) {
        //result = this.props.min_order
      }

      //console.log('props-props', this.props)

        const customer = JSON.parse(this.props.customer);

        return (

        <Modal
            show={this.props.open}
            onHide={this.props.closeModal}
            className="OrderConfirmationModal"
         >

            <Modal.Header closeButton>{strings.OrderConfirmation}</Modal.Header>

            <Modal.Body>
              <div>
                <h2>Hi {customer.Name}</h2>
                <button className="linkBtn mb-10" onClick={this.props.doLogout}>Not {customer.Name}, Logout</button>
              </div>

              <div className="row">

                <div className="col-md-12">
			            <OrderAddress onCheckPickUp={this.onCheckPickUp} default={this.props.default} customDelivery={this.state.customDelivery} customer={customer} onSelectAddress={this.onSelectAddress.bind(this)} onChangeOption={this.onChangeOption} />
                </div>

              </div>

              <div className={this.checkPayByAccount()}>
                <StripeProvider apiKey={config.STRIPE_KEY}>
                  <Elements>
                    <MyStoreCheckout
                      customDelivery={this.state.customDelivery}
                      completePayment={this.props.completePayment}
                      shippingCost={this.state.shippingCost}
                      TotalWeight = {this.state.TotalWeight}
                      subtotal = {(parseFloat(result) + parseFloat(this.props.assemblyCost)).toFixed(2)}
                      JobId = {this.props.JobId}
                      projectId = {0}
                      result={result}
                      min_order = {this.props.min_order}
                      assemblyCost={this.props.assemblyCost}
                      Customer = {customer}
                      project={this.props.project}
                      addressData = {this.state.addressData}
                      redirect={this.props.redirect}
                      productsData={this.props.products}
                      pickupOption = {this.state.pickupOption}
                      isPickUp = {this.state.isPickUp}
                      saveJob={this.props.saveJob}

                    />
                  </Elements>
                </StripeProvider>
                </div>




              <div className="row">

                <div className="col-md-6">

				            {/*<button className="btn cartbtn purchasebtn d-block upsize" onClick={this.proceed}>Proceed to Payment Gateway</button>*/}

                </div>

              </div>

            </Modal.Body>

        </Modal>

      )
    }
}

export default OrderConfirm;
